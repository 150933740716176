// The right-side panel that opens when you select an ingredient in the simulator.
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { StateWith, Action } from '../store';
import Button from '@material-ui/core/Button/Button';
import IngredientDistributionEditor from './IngredientDistributionEditor';
import { getCategoryColors, LayoutConstants, sortCategoriesByWhatIf } from '../utils';
import { addCommonDataToSqIng, computeWhatIfsFromSim } from '../addData';
import { ImpactVisualization } from './ImpactVisualization';
import { movementForDistribution, simulateOutcomeForMovement } from './simulation';
import CategoryMovementChart from '../charts/CategoryMovementChart';
import { SimulatorData, PerCategory } from '../sharedTypes';
import { SqIngWithCommonData } from '../clientTypes';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%'
    },
    movementChart: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-8px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '528px',
      height: '516px',
      backgroundColor: LayoutConstants.lightBlueBackgroundColor,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      boxShadow: 'rgba(100, 100, 111, 0.2) 2px 4px 4px 0px'
    },
    controller: {
      padding: theme.spacing(1),
      backgroundColor: LayoutConstants.lightBlueBackgroundColor,
      borderRadius: '8px',
      width: '600px',
      flex: '0 0 600px',
      minHeight: '516px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-8px',
      marginRight: theme.spacing(1),
      boxShadow: 'rgba(100, 100, 111, 0.2) 2px 4px 4px 0px'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

interface Props {
  ingredientForSimulation: string,
  showDetails: boolean,
  setShowDetails: (show: boolean) => void,
  onDrag: (newDistr: { [cat: string]: number }) => void
  close: () => void,
}

type LocalState = StateWith<SimulatorData>;
interface IngWithLocalData extends SqIngWithCommonData {
  whatIfValues: PerCategory<number>,
  whatIfMagnitudes: PerCategory<number>,
}

export default function SimulatorControl(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ingredientForSimulation, showDetails, setShowDetails, onDrag, close } = props;
  const { productConfig, simulationData, simulatorState } = useSelector((state: LocalState) => {
    const productConfig = state.cfg.products[state.product];
    const selectedOutcome = state.selectedOutcomesByProduct[state.product];
    const simulationData = state.data.simulation[selectedOutcome.id];
    const simulatorState = state.simulatorStateByProduct[state.product];
    return {ingredientForSimulation, productConfig, simulationData, simulatorState};
  });
  const ingredient = productConfig.ingredients.find( ing => ing.column === ingredientForSimulation)!;
  const ingredientWithData: IngWithLocalData = useSelector((state: LocalState) => {
    const ingWithCommonData = addCommonDataToSqIng(ingredient, state);
    const whatIfs = computeWhatIfsFromSim(ingredient, state);
    return { ...ingWithCommonData, ...whatIfs };
  });
  const categoryColors = getCategoryColors(sortCategoriesByWhatIf(ingredientWithData).map(c => c.value));
  const ingredientHumanName = ingredient.human_name;
  const originalDistribution = simulatorState.originalDistributions[ingredient.column];
  const movement = movementForDistribution(
    simulationData[ingredient.column],
    originalDistribution,
    simulatorState.simulatedDistributions[ingredient.column]
  );
  const simulated = ingredientWithData.magnitude(
    simulateOutcomeForMovement(
      simulationData[ingredient.column],
      originalDistribution,
      movement
    ));

  return (
    <div className={classes.root}>
      { showDetails &&
          <div className={classes.movementChart}>
            <h4>Transitions between categories for the selected simulation</h4>
            <CategoryMovementChart
              ingredient={ingredient}
              categoryColors={categoryColors}
              originalDistribution={originalDistribution}
              movement={movement}/>
            <div style={{flex: 1}}></div>
            <div className={classes.buttons}>
              <Button
                data-testid='sim-hide-details-button'
                style={{marginRight: '10px'}}
                color='secondary'
                variant='contained'
                onClick={() => setShowDetails(false)}>▶ Hide details
              </Button>
            </div>
          </div>
      }
      <div className={classes.controller}>
        <h3>{ingredientHumanName}</h3>
        <div style={{display: 'flex', marginBottom: '24px'}}>
          <ImpactVisualization
            width={96}
            height={224}
            role='simulator'
            actualMagnitude={ingredientWithData.actualBar}
            potentialMagnitude={ingredientWithData.potentialBar}
            simulatedMagnitude={simulated}
            maxPotentialMagnitude={ingredientWithData.potentialBar}
            magnitudeToOutcome={ingredientWithData.magnitudeToOutcome}
            sqIng={ingredient}
            selected={true}
            onSelect={() => {}}
            onDrag={onDrag}
            whatIfMagnitudes={ingredientWithData.whatIfMagnitudes}
            categoryColors={categoryColors}
          />
        </div>
        <IngredientDistributionEditor
          ingredient={ingredient}
          categoryColors={categoryColors}
        />
        <div style={{flex: 1}}></div>
        <div className={classes.buttons}>
          { showDetails === false &&
            <Button
              data-testid='sim-show-details-button'
              style={{marginRight: '10px'}}
              color='secondary'
              variant='contained'
              onClick={() => setShowDetails(true)}>◀ Show more details
            </Button>
          }
          <div style={{flex: 1}}></div>
          <Button
            style={{marginRight: '10px'}}
            color='secondary'
            variant='contained'
            onClick={() => dispatch({ type: Action.ResetSimulation})}>
            Reset simulation
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={close}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}
