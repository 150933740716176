import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { parseFilterKey } from '../sharedUtils';
import CategorySelector from './CategorySelector';
import OutcomeRangeSelector from './OutcomeRangeSelector';
import ImpactRangeSelector from './ImpactRangeSelector';
import ListFilterValueSelector from './ListFilterValueSelector';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    width: '300px',
    padding: '10px',
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start'
  },
}));

export default function FilterValueSelector(
  {
    closeEditor,
    filterKey,
    setFilterKey,
    goBackToKeySelection,
  }: {
    closeEditor: () => void,
    filterKey: string,
    setFilterKey: (key: string) => void,
    goBackToKeySelection: () => void,
  }) {
  const classes = useStyles();
  const parsedFilterKey = parseFilterKey(filterKey);
  return (
    <Paper className={classes.outerContainer}>
  { parsedFilterKey.type === 'category' &&
      <CategorySelector
               closeEditor={closeEditor}
               ingredientId={parsedFilterKey.ingredientId}
               goBackToKeySelection={goBackToKeySelection} /> }
  { parsedFilterKey.type === 'outcome' &&
      <OutcomeRangeSelector
               closeEditor={closeEditor}
               outcomeId={parsedFilterKey.outcomeId}
               goBackToKeySelection={goBackToKeySelection} /> }
  { parsedFilterKey.type === 'impact' &&
      <ImpactRangeSelector
              closeEditor={closeEditor}
              filterKey={filterKey}
              setFilterKey={setFilterKey}
              goBackToKeySelection={goBackToKeySelection} /> }
  { parsedFilterKey.type === 'list' &&
      <ListFilterValueSelector
              closeEditor={closeEditor}
              listname={parsedFilterKey.listname}
              goBackToKeySelection={goBackToKeySelection} /> }
    </Paper>
  );
}
