import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Action, State } from '../store';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MapLayerWithData } from '../clientTypes';
import { ChangeEvent } from '../store';
import { Colors } from '../sharedUtils';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MapChart from './MapChart';
import { WidgetPanelTitle } from '../WidgetPanel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1)
    },
    alignedSelect: {
      marginTop: '-2px',
    },
    decorated: {
      color: Colors.darkblue,
    },
    smallText: {
      fontSize: '14px'
    }
  }),
);

interface Props {
  layersWithData: MapLayerWithData[],
  title?: string,
}


export default function MultiLayerMap(props: Props) {
  const classes = useStyles();
  const { layersWithData, title = 'Location' } = props;
  const { layerTitle, colorBy } = useSelector((state: State) =>
    state.mapSettingsByProduct[state.product]);
  const dispatch = useDispatch();
  const layer = layersWithData.find(l => l.title === layerTitle)!;

  function onLayerChange(event: ChangeEvent) {
    const newLayerTitle = event.target.value as string;
    const newLayer = layersWithData.find(l => l.title === newLayerTitle)!;
    const newSetting = { layerTitle: newLayerTitle, colorBy: newLayer.colorByOptions[0] };
    dispatch({ type: Action.SetMapSetting, payload: newSetting });
  }

  function onColorByChange(event: ChangeEvent) {
    const newSetting = { layerTitle, colorBy: event.target.value as string };
    dispatch({ type: Action.SetMapSetting, payload: newSetting });
  }

  return (
    <>
      <div className={classes.title}>
        <WidgetPanelTitle title={title}/>
        { layer.colorByOptions.length > 0 &&
          <div>
            <TextField
              select
              value={colorBy}
              inputProps={{'data-testid': 'map-color-selector'}}
              onChange={onColorByChange}
              variant='outlined'
              margin='dense'
              label='Color'
              className={classes.alignedSelect}
              SelectProps={{
                IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
              }}
              InputProps={{
                classes: {
                  input: classes.smallText
                }
              }}
            >
              { layer.colorByOptions.map(c =>
                <MenuItem key={c} value={c}>{c} &nbsp;</MenuItem>
              ) }
            </TextField>
          </div>
        }
        { layersWithData.length > 1 &&
          <div>
            <TextField
              select
              value={layerTitle}
              inputProps={{'data-testid': 'map-layer-selector'}}
              onChange={onLayerChange}
              variant='outlined'
              margin='dense'
              label='Detail level'
              className={classes.alignedSelect}
              SelectProps={{
                IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
              }}
              InputProps={{
                classes: {
                  input: classes.smallText
                }
              }}
            >
              {layersWithData.map((l, i) => <MenuItem key={i} value={l.title}>{l.title} &nbsp;</MenuItem>)}
            </TextField>
          </div>
        }
      </div>
      <div>
        <MapChart layer={layer} colorBy={colorBy!}/>
      </div>
    </>
  );

}
