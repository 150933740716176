// The dialog that pops up when the "Find lookalikes" button is clicked.
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import dialogStyle from '../dialogStyle';
import SavedListsBrowser from '../lists/SavedListsBrowser';
import ListUploadButton from '../lists/ListUploadButton';
import { Action, setError, State } from '../store';
import { protectedJsonFetch } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import FancyLoading from '../FancyLoading';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  onClose: () => void
}

export default function LookalikeDialog(props: Props) {
  const isMounted = React.useRef(true); // Mutable value, it's true while the dialog is open.
  const dispatch = useDispatch();
  const classes = dialogStyle();
  const product = useSelector((state: State) => state.product);
  const selectedDate = useSelector((state: State) => state.selectedDatesByProduct[product][1]);
  const savedLists = useSelector((state: State) => state.savedListsByProduct[product]);
  const [inProgress, setInProgress] = useState(false);
  const [excludeList, setExclusion] = useState(false);

  async function getLookalike(list: string) {
    setInProgress(true);
    try {
      const params = { product, list, day: selectedDate.toISOString() };
      const response = await protectedJsonFetch(
        '/api/getLookalikeFilters?' + new URLSearchParams(params).toString(), {});
      const payload = Object.fromEntries(response.filters.map(
        (f: any) => ['category/' + f.ing, f.cats]));
      if (excludeList) {
        payload[`list/${list}`] = { listUsage: 'exclude'};
      }
      if (isMounted.current) { // We don't apply the filters, if the dialog is already closed.
        dispatch({ type: Action.SetFilters, payload });
        props.onClose();
      }
    } catch (error) {
      dispatch(setError(error));
    } finally {
      if (isMounted.current) {
        setInProgress(false);
      }
    }
  }

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Dialog onClose={props.onClose} open={true} classes={{ paper: classes.dialogContent}}>
      <DialogTitle className={classes.dialogTitle}>Find lookalikes</DialogTitle>
      <div className={classes.decoration}/>
      <DialogContent>
        <div>
          <Checkbox
            checked={excludeList}
            onChange={(event) => { setExclusion(event.target.checked); }}
            disabled={inProgress}
            value='excludeSource'
          />
          Exclude source list from audience
        </div>
        { inProgress
          ? <><FancyLoading/> Creating lookalike audience filters...</>
          : <SavedListsBrowser
              savedLists={savedLists}
              explanation='Choose the list to use as the basis of the lookalike audience.'
              mode='selector'
              onSelect={getLookalike}
            />
        }
      </DialogContent>
      <DialogActions>
        <ListUploadButton/>
        <div style={{flex: '1 0 0'}} />
        <Button onClick={props.onClose} color='secondary'> Close </Button>
      </DialogActions>
    </Dialog>
  );
}
