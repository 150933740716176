import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { State, Action, EditorState } from '../store';
import SingleFilterEditor from './SingleFilterEditor';
import ChipContainer from './ChipContainer';
import { Colors } from '../sharedUtils';
import { log } from '../utils';

const borderRadiusInPx = 5;

const useStyles = makeStyles(theme => ({
  horizontalDivider: {
    height: '1px',
    borderBottom: `solid ${Colors.gray} 1px`,
    marginTop: '5px',
    marginBottom: '5px',
    width: '100%'
  },
  filterEditor: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
    marginLeft: '80px',
    marginRight: '80px',
    border: `solid ${Colors.gray} 1px`,
    borderRadius: `${borderRadiusInPx}px`,
    width: `calc(100% - 2 * ${borderRadiusInPx}px)`
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  chip: {
      margin: theme.spacing(1),
  },
  mainIcon: {
    margin: theme.spacing(1),
  }
}));

function HorizontalDivider() {
  const classes = useStyles();
  return <div className={classes.horizontalDivider}></div>;
}

export default function FilterEditor() {
  const classes = useStyles();
  const editorState = useSelector((state: State) => state.editorState);
  const dispatch = useDispatch();

  function setEditorState(s: EditorState) {
    dispatch({ type: Action.SetEditorState, payload: s });
  }

  const startEditingNewFilter = () => {
    log('Started editing new filter');
    setEditorState('new_filter');
  };

  const closeEditor = () => {
    log('Closed filter editor');
    setEditorState('no_edit');
  };

  const setCurrentlyEditedFilterKey = (key: string) => {
    setEditorState({ editedKey: key });
  };

  return (
    <div className={classes.filterEditor}>
      <ChipContainer
        editorState={editorState}
        startEditingNewFilter={startEditingNewFilter}
        closeEditor={closeEditor}
        setCurrentlyEditedFilterKey={setCurrentlyEditedFilterKey}
      />
    { editorState !== 'no_edit' &&
      <div>
        <HorizontalDivider/>
        <SingleFilterEditor
          closeEditor={closeEditor}
          setCurrentlyEditedFilterKey={setCurrentlyEditedFilterKey}
          editorState={editorState}
          startEditingNewFilter={startEditingNewFilter}
        />
      </div>
    }
  </div>
  );
}
