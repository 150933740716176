// The chart with the timeseries lines rendered from the data in the Redux state.
import React from 'react';
import { useSelector } from 'react-redux';
import { State, useProductConfig, useDisplaySettings } from '../store';
import { TrendsData } from '../sharedTypes';
import { chartColor } from '../utils';
import { Plot } from '../charts/Plot';

interface Props {
  showCounts: boolean
  setShowCounts: (v: boolean) => void
}

export default function TrendsChart(props: Props) {
  const trends = useSelector((state: State) => state.data as TrendsData);
  const cfg = useProductConfig();
  const customers = useDisplaySettings().entities;
  const data = [];
  const yaxis = { title: '' };
  const yaxis2 = { title: '', overlaying: 'y', side: 'right' };
  // Currently there's only one outcome.
  for (const k in trends.outcomes) {
    const outcome = cfg.outcomes.find(o => o.id === k)!;
    data.push({
      type: 'scatter',
      line: { shape: 'spline', width: 5 },
      marker: { size: 10 },
      name: outcome.human_name,
      x: trends.days,
      y: trends.outcomes[k],
    });
    yaxis.title = outcome.human_name;
  }
  if (props.showCounts) {
    data.push({
      type: 'scatter',
      line: { dash: 'dash', shape: 'spline', width: 1 },
      yaxis: 'y2',
      marker: { size: 6, color: 'black' },
      name: 'segment size',
      x: trends.days,
      y: trends.counts,
    });
  }
  let i = 0;
  for (const k in trends.categories) {
    const [ing, cat] = k.split('/');
    const ingCfg = cfg.ingredients.find(i => i.column === ing)!;
    const catCfg = ingCfg.categories.find(c => c.value === cat)!;
    data.push({
      type: 'scatter',
      line: { shape: 'spline' },
      yaxis: 'y2',
      name: catCfg.display_name,
      x: trends.days,
      y: trends.categories[k],
      marker: { color: chartColor(i) },
    });
    if (yaxis2.title === '') {
      yaxis2.title = `${customers} with ${catCfg.display_name}`;
    } else { // More than one category displayed.
      yaxis2.title = `${customers} with selected categories`;
    }
    i += 1;
  }
  const layout = {
    margin: { t: 10 },
    width: 1200,
    height: 500,
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    yaxis, yaxis2,
    showlegend: false,
  };
  const config = {
    displayModeBar: false,
    displaylogo: false,
  };

  return (<div>
    <Plot data={data} layout={layout} config={config}/>
  </div>);
}
