import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Colors } from './sharedUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      padding: '0.5em',
      paddingLeft: theme.spacing(2),
      margin: '0.2em'
    },
    title: {
      fontWeight: 'bold',
      marginBottom: 0,
      color: Colors.black,
      textTransform: 'capitalize',
    },
    decoration: {
      width: '40px',
      height: '3px',
      backgroundColor: Colors.lightblue,
      marginBottom: theme.spacing(2)
    }
  }),
);

export function WidgetPanelTitle({ title, className }: { title: string, className?: string }) {
  const classes = useStyles();
  const fullClassName = `${classes.title} ${className || ''}`;
  return (
    <div style={{display: 'inline-block'}}>
      <Typography paragraph className={fullClassName}>
        {title}
      </Typography>
      <div className={classes.decoration}/>
    </div>
  );
}

interface Props {
  children: any,
  title?: string,
  className?: string,
  style?: Object
}

export default function WidgetPanel(props: Props) {
  const { children, title, className, style } = props;
  const classes = useStyles();
  const fullClassName = `${classes.root} ${className || ''}`;
  return (
    <Paper className={fullClassName} style={style}>
       {title &&
         <WidgetPanelTitle title={title} />
       }
       {children}
    </Paper>
  );
}
