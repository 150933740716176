import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import dialogStyle from '../dialogStyle';
import { IconButton } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Filters } from '../sharedTypes';
import ConfirmDialog from '../ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { Action, State } from '../store';
import { protectedJsonFetch } from '../utils';


interface Props {
  open: boolean
  savedFilterSets?: { [key: string]: {saveDate: Date, filters: Filters} }
  onSelect: (value: string | undefined) => void
}

export default function LoadSegmentDialog( props: Props) {
  const { open, savedFilterSets, onSelect } = props;
  const classes = dialogStyle();
  const filterSetNames = !!savedFilterSets ? Object.keys(savedFilterSets) : [];
  const [selectedFilterSet, setSelectedFilterSet] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const product = useSelector((state: State) => state.product);

  async function deleteFilterSet(name: string) {
    const msg = {
      name,
      product,
    };
    const updatedSavedFilterSets = await protectedJsonFetch('/api/deleteSegment', {
      method: 'POST',
      body: JSON.stringify(msg),
    });
    dispatch({ type: Action.SetSavedFilterSets, payload: updatedSavedFilterSets[product] });
  }

  function close() {
    setSelectedFilterSet('');
    setShowConfirmDialog(false);
  }

  return (
    <Dialog onClose={() => onSelect(undefined)} open={open} classes={{ paper: classes.dialogContent}}>
      <DialogTitle className={classes.dialogTitle}>Load saved filter set</DialogTitle>
      <div className={classes.decoration}/>
      <DialogContent>
        <List>
          {filterSetNames.map((filterSet, index) => (
            <div key={filterSet}>
              <ListItem button onClick={() => onSelect(filterSet)}>
                <ListItemText
                  primary={filterSet}
                  secondary={`Saved on ${savedFilterSets![filterSet].saveDate}`}
                  data-testid={`saved-filterSet_${filterSet}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={() => { setSelectedFilterSet(filterSet); setShowConfirmDialog(true); }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant='middle' light/>
            </div>
          ))}
        </List>
        <ConfirmDialog
          open={showConfirmDialog}
          message={`This will permanently delete the saved filter set "${selectedFilterSet}".`}
          close={close}
          action={() => { deleteFilterSet(selectedFilterSet); }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSelect(undefined)} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
