import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Colors } from './sharedUtils';
import LinearProgress from '@material-ui/core/LinearProgress';
import FancyLoading from './FancyLoading';

const useStyles = makeStyles(theme => createStyles({
  menuAnimation: {
    fontSize: '12px',
    color: Colors.lightblue,
    backgroundColor: `rgba(57, 188, 243, 0.15)`,
    'text-align': 'center',
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    width: '100%',
    minHeight: '48px',
  },
}));

export default function LoadingAnimation() {
  return (
    <div style={{ flexGrow: 1, padding: '1em', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={100} variant='indeterminate' thickness={2}/>
    </div>
  );
}

export function InitialLoadingAnimation() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <FancyLoading size={400}/>
      Loading configuration and initial data...
    </div>
  );
}

export function MenuItemLoadingAnimation() {
  const classes = useStyles();

  return (
    <div className={classes.menuAnimation}>
      <div>Loading menu...</div>
      <div style={{width: '90%', margin: 'auto', paddingTop: '8px'}}>
        <LinearProgress color='secondary'/>
      </div>
    </div>
  );
}
