const Plotly = require('plotly.js/lib/core');

// Load in only the trace types we use.
Plotly.register([
    require('plotly.js/lib/bar'),
    require('plotly.js/lib/pie'),
    require('plotly.js/lib/choropleth'),
    require('plotly.js/lib/scatter'),
    require('plotly.js/lib/scattergeo'),
    require('plotly.js/lib/scattermapbox'),
    require('plotly.js/lib/sankey'),
]);

module.exports = Plotly;
