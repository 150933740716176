import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import download from 'downloadjs';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { Colors, dateToString } from '../sharedUtils';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import TocIcon from '@material-ui/icons/Toc';
import SvgIcon from '@material-ui/core/SvgIcon';
import { SVGIcons } from '../icons';
import { useSelector, useDispatch } from 'react-redux';
import { log, protectedJsonFetch, displayFormat, entitiesLabel } from '../utils';
import { Action, setError, State, useDisplaySettings, useExportAllowed,
         useSelectedPage, useAudienceCount } from '../store';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(0.5)
    },
    summary: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      fontSize: theme.spacing(2.5),
      fontWeight: 'bold',
      textAlign: 'center',
      color: Colors.darkblue
    },
    explanation: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      fontStyle: 'italic',
      minHeight: theme.spacing(5),
    },
    tooltip: {
      maxWidth: 200
    },
  }),
);


type SaveMode = 'asFilters' | 'asList' | 'download';

interface Props {
  open: boolean
  onClose: () => void
  setSaveDialogOpen: (isOpen: boolean) => void
  existingFilterSetNames: Array<string>
  existingListNames: Array<string>
}


export default function SaveDialog(props: Props) {
  const {
    open, setSaveDialogOpen, onClose,
    existingFilterSetNames, existingListNames
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const display = useDisplaySettings();
  const exportAllowed = useExportAllowed();
  const [downloading, setDownloading] = useState(false);
  const [saveFiltersInProgress, setSaveFiltersInProgress] = useState(false);
  const [saveListInProgress, setSaveListInProgress] = useState(false);
  const page = useSelectedPage();
  const audienceCount = useAudienceCount();
  const { cfg, numFilters, filters, selectedDates, product } = useSelector((state: State) => {
    const product = state.product;
    const cfg = state.cfg.products[product];
    const filters = state.filtersByProduct[product];
    const numFilters = Object.keys(filters).length;
    const selectedDates = state.selectedDatesByProduct[product];
    return { cfg, numFilters, filters, selectedDates, product };
  });

  const segmentSize = displayFormat(audienceCount);
  const entityPart = audienceCount === 1 ? display.entity : display.entities;
  const filterPart = numFilters === 1 ? 'filter' : 'filters';
  const summary = `${entitiesLabel(display, audienceCount)} selected with ${numFilters} ${filterPart}`;

  async function downloadSegment(filename: string) {
    log('Exported segment');
    const controller = new AbortController();
    const signal = controller.signal;
    setDownloading(true);
    const fiveMinutes = 1000 * 60 * 5;
    setTimeout(() => controller.abort(), fiveMinutes);
    const dayStart = new Date(selectedDates[0]);
    const dayEnd = new Date(selectedDates[1]);
    if (page !== 'trends') {
      // Outside of the trends page we are looking at just the end date.
      dayStart.setDate(dayEnd.getDate());
    }
    // The end date is exclusive on the backend.
    dayEnd.setDate(dayEnd.getDate() + 1);
    try {
      const segment = await protectedJsonFetch('/api/exportSegment', {
        method: 'POST',
        body: JSON.stringify({
          product,
          dayStart: dayStart.toISOString(),
          dayEnd: dayEnd.toISOString(),
          filters,
        }),
        signal
      });
      download(segment.data, filename, 'text/csv');
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setDownloading(false);
      onClose();
    }
  }

  async function saveFilterSet(name: string) {
    const msg = {
      name,
      product,
      saveDate: dateToString(new Date()),
      filters: filters || {},
    };
    setSaveFiltersInProgress(true);
    try {
      const updatedSavedFilterSets = await protectedJsonFetch('/api/saveSegment', {
        method: 'POST',
        body: JSON.stringify(msg),
      });
      dispatch({ type: Action.SetSavedFilterSets, payload: updatedSavedFilterSets[product] });
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setSaveFiltersInProgress(false);
      setSaveDialogOpen(false);
    }
  }

  async function saveList(name: string) {
    const msg = {
      name,
      product,
      filters: filters || {},
      day: selectedDates[1].toISOString(),
    };
    setSaveListInProgress(true);
    try {
      const updatedSavedLists = await protectedJsonFetch('/api/saveList', {
        method: 'POST',
        body: JSON.stringify(msg),
      });
      dispatch({ type: Action.SetSavedLists, payload: updatedSavedLists[product] });
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setSaveListInProgress(false);
      setSaveDialogOpen(false);
    }
  }

  function explanation(saveMode: SaveMode) {
    switch (saveMode) {
      case 'asFilters':
        return `The ${numFilters} current ${filterPart} will be saved. You can apply the same filter set on other dates.`;
      case 'asList':
        return `The ${segmentSize} selected ${entityPart} will be saved. You can look up the same ${entityPart} on other dates.`;
      case 'download':
        if (page === 'trends') {
          return `Download a CSV file with the data for the selected ${display.entities} from
            ${dateStrs[0]} to ${dateStrs[1]}. (Up to ${segmentSize} ${entityPart} per day.)`;
        } else {
          return `The ${segmentSize} selected ${entityPart} will be saved in a CSV file.`;
        }
      default:
        return 'Invalid mode.';
    }
  }

  const downloadButtonTooltip = cfg.demo_cheats
    ? 'This is a demo version. In demo version only a sample of the segment is exported.' : '';


  function saveButtonText(saveMode: SaveMode) {
    switch (saveMode) {
      case 'asFilters':
        return 'Save filter set';
      case 'asList':
        return 'Save list';
      case 'download':
        return 'Download list';
      default:
        return 'Invalid mode.';
    }
  }

  function textFieldLabel(saveMode: SaveMode) {
    switch (saveMode) {
      case 'asFilters':
        return 'Name of filter set';
      case 'asList':
        return 'Name of list';
      case 'download':
        return 'Filename';
      default:
        return 'Invalid mode.';
    }
  }

  const dateStrs = selectedDates.map(dateToString);
  const dateStr = page === 'trends' ? `${dateStrs[0]} to ${dateStrs[1]}` : dateStrs[1];
  const defaultName = `${display.entities} ${dateStr}`;
  const [name, changeName] = useState(defaultName);
  const [saveMode, changeSaveMode] = useState<SaveMode>('asFilters');

  function getError(saveMode: SaveMode): string | undefined {
    if (name === '') {
      return 'Name cannot be empty!';
    } else if (saveMode === 'asFilters' && existingFilterSetNames.indexOf(name) !== -1) {
      return 'Filter set name already exists!';
    } else if (saveMode === 'asList' && existingListNames.indexOf(name) !== -1) {
      return 'List name already exists!';
    }
  }

  const error = getError(saveMode);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth='sm'
      fullWidth={true}
      disableBackdropClick={true}
    >
      <DialogContent>
        <div className={classes.summary}>
          {summary}
        </div>
        <div className={classes.radioGroup}>
        <Tabs
          value={saveMode}
          indicatorColor='primary'
          textColor='primary'
          onChange={(event, value) => changeSaveMode(value)}
          aria-label='Save mode'
          variant='fullWidth'
        >
          <Tab
            value='asFilters'
            label='Save as filter set'
            icon={
              <SvgIcon fontSize='small'>
                {SVGIcons.funnel(saveMode === 'asFilters' ? Colors.darkblue : Colors.lightblack)}
              </SvgIcon>
            }/>
          { page !== 'trends' &&
            <Tab value='asList' label='Save as list' icon={<TocIcon/>}/> }
          <Tab value='download' label='Download as list' disabled={!exportAllowed} icon={<SaveAltIcon/>}/>
        </Tabs>
        </div>
        <div className={classes.explanation}>
          {explanation(saveMode)}
        </div>
        <TextField
          error={!!error}
          helperText={error || ' '}
          value={name}
          variant='outlined'
          label={textFieldLabel(saveMode)}
          onChange={event => changeName(event.target.value)}
          data-testid='segment-name'
          fullWidth/>
      </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary'>
            Close
          </Button>
          { saveMode === 'asFilters' &&
            <Button onClick={() => saveFilterSet(name)} disabled={saveFiltersInProgress || !!error} color='secondary'>
              { saveFiltersInProgress
                ? 'Saving filters...'
                : saveButtonText(saveMode)
              }
            </Button>
          }
          { saveMode === 'asList' &&
            <Button onClick={() => saveList(name)} disabled={saveListInProgress || !!error} color='secondary'>
              { saveListInProgress
                ? 'Saving list...'
                : saveButtonText(saveMode)
              }
            </Button>
          }
          { saveMode === 'download' &&
            <Tooltip title={downloadButtonTooltip} placement='top' classes={{ tooltip: classes.tooltip }}>
            <span>
              <Button
                onClick={() => downloadSegment(name)} disabled={downloading || !!error}
                color='secondary'>
                { downloading
                  ? 'Downloading...'
                  : saveButtonText(saveMode)
                }
              </Button>
            </span>
            </Tooltip>
          }
        </DialogActions>
    </Dialog>
  );
}
