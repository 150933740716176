import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Plot } from './Plot';
import { useDisplaySettings } from '../store';
import { baseLayout, baseConfig, mergeWhileConcatenatingArrays, segmentSizeStrings,
         getCategoryColors, LayoutConstants } from '../utils';
import { Colors } from '../sharedUtils';
import { DisabledOrNumber, disabled } from '../clientTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: theme.spacing(1.5),
    },
    dot: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      display: 'inline-block',
      marginRight: theme.spacing(1),
      borderRadius: theme.spacing(0.75),
    },
  }),
);

interface Props {
  labels: string[],
  // If you don't want to display a value for a label but only show that the label also exists
  // (e.g. the ingredient category has been filtered out) then set the value to 'disabled'.
  values: DisabledOrNumber[],
  layout?: Object,
  // The text that will be displayed besides each segment. If not specified then labels will be used.
  text?: string[],
  segmentColor?: string[],
  hovertext?: string[],
  // You can pass extra data for each segment using `segmentData`. The `onSelectBar` will be called
  // with the segmentData element corresponding to the selected segment.
  segmentData?: any[],
  onSelectSegment?: (selectedSegmentData: any) => void,
}

export default function PieChart(props: Props) {
  const classes = useStyles();
  const customers = useDisplaySettings().entities;
  const { labels, values, layout, hovertext, segmentData, onSelectSegment } = props;
  const layoutWithDefaults = mergeWhileConcatenatingArrays([baseLayout(), layout]);
  const text = props.text || labels;
  // Create a array of [0, 1, .. , num of segments - 1], like range() in python.
  const defaultColors = getCategoryColors(labels);
  const segmentColor = props.segmentColor || labels.map(l => defaultColors[l]);
  // Filter out the disabled data.
  const enabledIds = values
    .map((v, id) => v === disabled ? disabled : id).filter(i => i !== disabled);

  function onlyEnabled(lst: any[]) {
    return enabledIds.map(id => lst[(id as number)]);
  }

  const segmentSize = onlyEnabled(values).reduce((soFar, nextCount) => soFar + nextCount, 0);
  const { digits, suffix } = segmentSizeStrings(segmentSize);

  function colorOnlyIfEnabled(label: string, color: string) {
    const labelId = labels.indexOf(label);
    const enabled = enabledIds.includes(labelId);
    return enabled ? color : Colors.gray;
  }

  const data = [
    {
      type: 'pie',
      values: onlyEnabled(values),
      labels: onlyEnabled(labels),
      sort: false,
      direction: 'clockwise',
      hole: 0.6,
      marker: { colors: onlyEnabled(segmentColor) },
      textinfo: 'text',
      textposition: text ? 'outside' : 'none',
      textfont: {
        color: onlyEnabled(segmentColor),
        family: LayoutConstants.roboto,
      },
      text: onlyEnabled(text),
      title: {
        text: `${digits} ${suffix}<br>${customers}`,
        position: 'middle center',
        font: {
          family: LayoutConstants.roboto,
          size: 16,
          color: Colors.black,
        },
      },
      hoverinfo: hovertext ? 'text' : 'skip',
      hovertext: hovertext && onlyEnabled(hovertext),
      customdata: segmentData,
    }
  ];

  function onClick(clickdata: any) {
    if (onSelectSegment) {
      const selectedSegmentData = clickdata.points[0].customdata;
      onSelectSegment(selectedSegmentData);
    }
  }

  const bottomMarginForPlots = 70;

  return (
    <div style={{display: 'flex', alignItems: 'flex-start'}}>
      <div className={classes.legend} >
        {
          labels.map((l, id) => (
            <div className={classes.legendItem} key={id}>
              <div
                className={classes.dot}
                style={{ backgroundColor: colorOnlyIfEnabled(l, segmentColor[id]) }} >
              </div>
              <div style={{ color: colorOnlyIfEnabled(l, Colors.black) }} >
                {l}
              </div>
            </div>))
        }
      </div>
      <Plot
        data={data}
        layout={layoutWithDefaults}
        config={baseConfig()}
        style={{width: '90%', height: `calc(100% - ${bottomMarginForPlots}px`}}
        useResizeHandler={true}
        onClick={onClick}/>
    </div>
  );
}
