import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppMenu from './AppMenu';
import SideMenuItem from './SideMenuItem';
import {
  newStore, Action, State, SimulatorState, setError, useError,
  useSelectedPage, setSelectedPageAction } from '../store';
import { useSelector, Provider } from 'react-redux';
import InsightsPage from '../insights/InsightsPage';
import TrendsPage from '../trends/TrendsPage';
import { InitialLoadingAnimation, MenuItemLoadingAnimation } from '../LoadingAnimation';
import { Config } from '../sharedTypes';
import { LayoutConstants, protectedJsonFetch, getDefaultMapSettings } from '../utils';
import { Colors } from '../sharedUtils';
import { SVGIcons } from '../icons';
import SimulatorPage from '../simulator/SimulatorPage';
import 'material-design-icons/iconfont/material-icons.css';
import HelpPage from '../help/HelpPage';

import * as firebase from 'firebase/app';
import 'firebase/firestore';



const sidebarWidthMultiplier = 12;
let firebaseConfig = {}; // Will be set after init is completed.
let demoID = ''; // Used in the contact popup to identify demo instance;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: LayoutConstants.roboto,
      backgroundColor: LayoutConstants.pageBackgroundColor,
      minHeight: '100vh',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: Colors.white,
      height: theme.spacing(5),
    },
    appBarContent: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: Colors.darkblue,
      fontWeight: 'bold',
      fontSize: '20px',
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(0.5),
    },
    sidebar: {
      width: theme.spacing(sidebarWidthMultiplier),
      flexShrink: 0,
    },
    sidebarPaper: {
      width: theme.spacing(sidebarWidthMultiplier),
      backgroundColor: Colors.darkblue,
    },
    sidebarContent: {
      display: 'flex',
      height: 'calc(100vh)',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(14),
      justifyContent: 'space-between'
    },
    lynxLogo: {
      bottom: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
      color: Colors.white,
    },
    formContainer: {
      margin: theme.spacing(2),
    }
  }),
);


const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.darkblue,
      light: '#2889BF',
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.mediumblue,
      light: '#77B8E7',
      contrastText: Colors.white,
    },
    error: {
      main: Colors.cyclamen,
      light: '#F38BB1',
      contrastText: Colors.white,
    }
  },
  typography: {
    button: {
      textTransform: 'none',
    }
  },
  overrides: {
    MuiButton: {
      root: {
        color: Colors.lightblue
      }
    }
  }
});


interface PopupConfig {
  nonblocking: boolean
  timeoutSeconds: number
  firebaseConfig: any
}

const allLabels: { [pageId: string]: string } = {
  insights: 'Insights & Segmentation',
  simulator: 'Simulator',
  trends: 'Trends',
  help: 'Help',
};

const allIcons: { [pageId: string]: (color: string) => JSX.Element } = {
  insights: SVGIcons.insightsPageIcon,
  simulator: SVGIcons.simulationPageIcon,
  trends: SVGIcons.trendsPageIcon,
  help: SVGIcons.info,
};

const store = newStore();
const App: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={mainTheme}>
        <Provider store={store}>
          <Main />
        </Provider>
      </ThemeProvider>
    </div>
  );
};

function Main() {
  const [pages, setPages] = useState<{ [pageId: string]: JSX.Element }>({});
  const [pageIds, setPageIds] = useState<string[]>([]);
  const selectedPageId = useSelectedPage();
  const [initCompleted, setInitCompleted] = useState(false);
  const error = useError();
  const [title, setTitle] = useState(''); // Will be updated when init is completed.
  const [contactPopupOpen, setContactPopupOpen] = useState(false);
  const [contactPopupConfig, setContactPopupConfig] = useState(undefined);
  const [popupNonBlocking, setPopupNonBlocking] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState<boolean>(false);
  const dataLoaded = useSelector((state: State) => state.dataUpToDate);
  if (dataLoaded && !initCompleted) {
    setInitCompleted(true);
  }
  const classes = useStyles();
  // Typescript wants us to use "undefined" but React needs a "null" here.
  // tslint:disable-next-line
  const trackerElement = useRef<HTMLDivElement>(null);

  function setupTracker(tracker: string) {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${tracker}`;
    (trackerElement as any).current.appendChild(scriptTag);

    function gtag(...args: any[]) {
      ((window as any).dataLayer = (window as any).dataLayer || []).push(arguments);
    }

    gtag('js', new Date());
    gtag('config', tracker);

    // For measuring the time spent on our SPA we need to fire additional events.
    // https://help.analyticsedge.com/article/measuring-time-on-bounce-page/

    function trackEvent(timerID: string, timerValue: string) {
      gtag(
        'event',
        timerID,
        {
          'event_category': 'TimeOnPage',
          'event_label': timerValue,
          'non_interaction': true
        }
      );
    }

    function timer11() { trackEvent('1', '11-30 seconds'); }
    function timer31() { trackEvent('2', '31-60 seconds'); }
    function timer61() { trackEvent('3', '61-180 seconds'); }
    function timer181() { trackEvent('4', '181-600 seconds'); }
    function timer601() { trackEvent('5', '601-1800 seconds'); }
    function timer1801() { trackEvent('6', '1801+ seconds'); }

    trackEvent('0', '0-10 seconds');
    setTimeout(timer11, 11000);
    setTimeout(timer31, 31000);
    setTimeout(timer61, 61000);
    setTimeout(timer181, 181000);
    setTimeout(timer601, 601000);
    setTimeout(timer1801, 1801000);
  }

  function startContactPopupCountDown( contactPopup: PopupConfig ) {
    setTimeout( () => {
      setContactPopupOpen(true);
    }, contactPopup.timeoutSeconds * 1000);
  }

  function ErrorDialog({ message }: { message?: string }) {
    return (
      <Dialog
        open={message !== undefined}
        disableBackdropClick={false}
        maxWidth='md'>
        <DialogContent>
          <DialogContentText
            dangerouslySetInnerHTML={{
              __html: message || ''
            }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload()} color='secondary'>
            Reload
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function ContactDialog({ open, onClose }:
    { open: boolean, onClose: () => void }) {

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [company, setCompany] = useState('');
    const [companyError, setCompanyError] = useState(false);
    const [comment, setComment] = useState('');


    function handleSend(demoID: string) {
      let someError = false;
      if (name === '') {
        setNameError(true);
        someError = true;
      }
      if (email === '') {
        setEmailError(true);
        someError = true;
      }
      if (company === '') {
        setCompanyError(true);
        someError = true;
      }
      if (someError) {
        return;
      }

      firebase.initializeApp(firebaseConfig);
      const db = firebase.firestore();
      db.collection('contacts').add({
        name,
        email,
        company,
        comment,
        demoID,
        timestamp: new Date()
      })
      .then(function(docRef: any) {
        // tslint:disable-next-line:no-console
        console.log('Document written with ID: ', docRef.id);
        setFeedbackVisible(true);
      })
      .catch(function(error: any) {
        // tslint:disable-next-line:no-console
        console.error('Error adding document: ', error);
      });
      onClose();
    }

    function changeName(e: React.ChangeEvent<HTMLInputElement>) {
      setName(e.target.value);
    }

    function changeEmail(e: React.ChangeEvent<HTMLInputElement>) {
      setEmail(e.target.value);
    }

    function changeCompany(e: React.ChangeEvent<HTMLInputElement>) {
      setCompany(e.target.value);
    }

    function changeComment(e: React.ChangeEvent<HTMLInputElement>) {
      setComment(e.target.value);
    }

    return (
      <Dialog
        open={open}
        disableBackdropClick={true}
        maxWidth='xs'>
        <DialogContent>
          <DialogContentText>
            If you want to learn more about this tool
            and how it could help you and your organization,
            leave your contact information and we will get back to you.
            Once you have filled this form, you can continue using the
            demo and come back to it anytime you want.
          </DialogContentText>
          <div className={classes.formContainer}>
            <TextField
              required
              variant='outlined'
              id='name'
              label='Name'
              type='text'
              error={nameError}
              helperText={nameError ? 'Name cannot be an empty string.' : ' '}
              fullWidth
              onChange={changeName}
              onBlur={() => { setNameError(name === ''); }}
            />
          </div>
          <div className={classes.formContainer}>
            <TextField
              required
              variant='outlined'
              id='email'
              label='Email'
              type='text'
              error={emailError}
              helperText={emailError ? 'Email cannot be an empty string.' : ' '}
              fullWidth
              onChange={changeEmail}
              onBlur={() => { setEmailError(email === ''); }}
            />
          </div>
          <div className={classes.formContainer}>
            <TextField
              required
              variant='outlined'
              id='company'
              label='Company'
              type='text'
              error={companyError}
              helperText={companyError ? 'Company cannot be an empty string.' : ' '}
              fullWidth
              onChange={changeCompany}
              onBlur={() => { setCompanyError(company === ''); }}
            />
          </div>
          <DialogContentText>
            Are you wishing for a specific feature?
            Let us know in the comment box below!
          </DialogContentText>
          <div className={classes.formContainer}>
            <TextField
              required
              variant='outlined'
              id='comment'
              type='text'
              multiline
              rows={3}
              fullWidth
              onChange={changeComment}
            />
          </div>
        </DialogContent>
        <DialogActions>
          { popupNonBlocking &&
              <Button onClick={() => { setContactPopupOpen(false); startContactPopupCountDown(contactPopupConfig!); }} color='secondary'>
                Cancel
              </Button>
          }
          <Button onClick={() => { handleSend(demoID); }} color='secondary'>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  useEffect(() => {
    const init = async () => {
      let initialState;
      try {
        initialState = await protectedJsonFetch('/api/init', {});
      } catch (err) {
        store.dispatch(setError(err.message));
        return;
      }
      const cfg = (initialState.config as Config);
      setTitle(cfg.dashboard_title);
      // Also set the title of the browser tab:
      document.title = cfg.dashboard_title;
      const help = cfg.help;

      const allPages: { [pageId: string]: JSX.Element } = {
        insights: <InsightsPage/>,
        simulator: <SimulatorPage/>,
        trends: <TrendsPage/>,
        help: <HelpPage help={help}/>,
      };

      const optionalPageIds = cfg.optionalPages || [];
      const pageIds = ['insights'].concat(optionalPageIds);
      setPageIds(pageIds);
      const _pages: { [pageId: string]: JSX.Element} = {};
      for (const pageId of pageIds) {
        _pages[pageId] = allPages[pageId];
      }
      setPages(_pages);
      const products = Object.keys(cfg.products);
      const filters = products.map(p => ({ [p]: [] }));
      const filtersByProduct = Object.assign({}, ...filters);
      const selectedDatesByProduct: { [productId: string]: [Date, Date] } = {};
      for (const [p, d] of Object.entries(initialState.selectedDatesByProduct)) {
        selectedDatesByProduct[p] = [new Date((d as string[])[0]), new Date((d as string[])[1])];
      }
      const mapSettingsByProduct = getDefaultMapSettings(cfg);
      const { product, userInfo, selectedOutcomesByProduct, savedFilterSetsByProduct,
              savedListsByProduct, visibleDatesByProduct, filterBoundsByProduct,
              tracker, contactPopup } = initialState;

      const simulatorStateByProduct: { [key: string]: SimulatorState } = {};
      for (const p of products) {
        simulatorStateByProduct[p] = {
          active: false,
          originalDistributions: {},
          simulatedDistributions: {}
        };
      }

      setContactPopupConfig(contactPopup);
      if (contactPopup !== undefined) {
        setPopupNonBlocking(contactPopup.nonblocking);
      }

      store.dispatch({
        type: Action.Init,
        payload: {
          selectedDatesByProduct, product, userInfo, selectedOutcomesByProduct, filtersByProduct,
          filterBoundsByProduct, savedFilterSetsByProduct, savedListsByProduct, mapSettingsByProduct,
          visibleDatesByProduct, simulatorStateByProduct, cfg
        }
      });
      // Demo site specific optional additions
      if (tracker) {
        setupTracker(tracker);
      }
      if (contactPopup !== undefined) {
        firebaseConfig = contactPopup.firebaseConfig;
        demoID = contactPopup.demoID;
        startContactPopupCountDown(contactPopup);
      }
    };
    init();
  }, []);

  return (
    <>
          <AppBar position='sticky' className={classes.appBar}>
            <div className={classes.appBarContent}>
              <Typography variant='h6' className={classes.title}>
                {title}
              </Typography>
              <AppMenu/>
              </div>
          </AppBar>
          <Drawer className={classes.sidebar} variant='permanent' classes={{paper: classes.sidebarPaper}}>
            <div className={classes.sidebarContent}>
              { initCompleted ?
              <div>
                {
                  pageIds.map( pid => {
                    return <SideMenuItem
                      label={allLabels[pid]}
                      active={selectedPageId === pid}
                      icon={allIcons[pid]}
                      handleActivate={() => { store.dispatch(setSelectedPageAction(pid)); }}
                      key = {pid}
                    />;
                  })
                }
              </div>
              :
              !error && <MenuItemLoadingAnimation />
              }
              <div className={classes.lynxLogo}>
                <img src='lynx-logo.svg' alt='Lynx logo'/>
              </div>
            </div>
          </Drawer>
          { initCompleted ? pages[selectedPageId] : !error && <InitialLoadingAnimation /> }
          <div ref={trackerElement}></div>
          <ContactDialog
            open={contactPopupOpen}
            onClose={() => setContactPopupOpen(false)}/>
          <ErrorDialog message={error} />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={feedbackVisible}
            autoHideDuration={6000}
            onClose={() => { setFeedbackVisible(false); }}
            message={`Fantastic! Have fun exploring ${title}.`}
            action={
              <React.Fragment>
                <IconButton
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={() => { setFeedbackVisible(false); }}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </React.Fragment>
            }/>
    </>
  );
}

export default App;
