/*
 * This file contains types that are used by both the client and the server. Sharing is solved
 * by a symbolic link from the server code to the client code. If you move this file, take into
 * account how it affects the symbolic link!
 */

export type Bounds = { lower: number, upper: number };

export interface HistogramData {
  bucketCounts: number[],
  bounds: Bounds,
  currentRange: Bounds,
  stepSize: number
}

interface FilterBoundsForOneOutcome {
  outcomeBounds: Bounds,
  impactBounds: { [ingredientColumn: string]: { relative: Bounds, absolute: Bounds } },
}

export interface FilterBoundsByOutcome {
  [outcomeId: string]: FilterBoundsForOneOutcome,
}

export interface FilterBoundsByProduct {
  [productId: string]: FilterBoundsByOutcome,
}

export type OutcomeCardType = 'gauge' | 'histogram' | 'nps';
export interface NPSCuts {
  detractorBelow: number,
  promoterAbove: number
}

export interface Outcome {
  human_name: string,
  id: string,
  positive: boolean,
  stepSize: number,
  display?: { suffix?: string, prefix?: string, description?: string, impact_unit?: string },
  filterBounds: { lower: 'auto' | number, upper: 'auto' | number },
  minValue?: number,
  maxValue?: number,
  cardType?: OutcomeCardType,
  npsCuts?: NPSCuts,
}

export interface IngredientCategory {
  value: string,
  display_name: string
}

export interface Ingredient {
  categories: Array<IngredientCategory>,
  column: string,
  context: boolean,
  human_name: string,
  meaning?: string,
  units?: string,
  kind?: 'multi-value' | 'location' | 'numerical',
  source_column?: string,
  cuts?: Array<number>
}

// How the ingredient groups are defined in the config.
export interface IngredientGroup {
  id: string,
  human_name: string,
  ingredients?: Array<string>,  // The ids of the ingredients directly under the group.
  subgroups?: Array<string>,  // The ids of the subgroups directly under the group.
}

export interface RegionMapLayer {
  src: string,
  topojson: string,
  center?: { lat: number, lon: number},
  projection_scale?: number,
  title?: string,  // If not provided then the `human_name` of the ingredient used for `src` is used.
  mode?: 'region',
  plotly_config?: any,
}

export interface PointMapLayer {
  src: string,
  topojson: string,
  mapbox_token?: string,
  center?: { lat: number, lon: number},
  projection_scale?: number,
  title?: string,
  mode: 'point',
  plotly_config?: any,
  pointHoverText?: {columnsToDisplay: string[], format: string}
}
export type MapLayer = RegionMapLayer | PointMapLayer;

export type SimpleChart = {
  type: 'vertical_bar' | 'combined_vertical_bar' | 'horizontal_bar' | 'pie',
  src: string,
  main_color?: string, colors?: { [category: string]: string }
};
export type MapChart = { type: 'map', title: string, layers: MapLayer[]};
export type Chart = SimpleChart | MapChart;

export enum ImpactType {
  Actual = 'actual',
  Potential = 'potential',
  Unrealized = 'unrealized',
}

export type CategoryFilterKey = { type: 'category', ingredientId: string };
export type ImpactFilterKey = {
  type: 'impact',
  relative: boolean,
  ingredientId: string,
  outcomeId: string,
  impactType: ImpactType,
};
export type OutcomeFilterKey = { type: 'outcome', outcomeId: string };
export type ListFilterKey = { type: 'list', listname: string };
export type FilterKey = CategoryFilterKey | ImpactFilterKey | OutcomeFilterKey | ListFilterKey;
export type FilterType = FilterKey['type'];

// When sent from the UI, the ids property is not set because we don't know them at that point.
// They are read from a file and added to the query on the backend.
export type ListFilterValue = { listUsage: 'include' | 'exclude', ids?: string[] };

export type FilterValue = string[] | Bounds | ListFilterValue;
export interface Filters { [key: string]: FilterValue }

export type DemoCheat = { type: 'multiply_counts', multiplier: number };

export type DataSource = {
  name: string,
  primary_key: string,
  retries?: number,
  retry_delay_ms?: number,
  concurrency?: number,
  log_queries?: boolean,
};

export interface DisplaySettings {
  entity: string,
  entities: string,
  entity_icon: string,
}

export interface ProductConnectionSettings {
  product: string,
  foreign_key: string,
  button_text: string,
}

export interface LookalikeAudienceSettings {
  enabled?: boolean,
}

export interface Product {
  product_name: string,
  display?: Partial<DisplaySettings>,
  product_connection?: ProductConnectionSettings,
  impact_log_base?: number,
  datasource: DataSource,
  columns_to_export?: Array<string>,
  demo_cheats?: Array<DemoCheat>,
  charts?: Array<Chart>,
  ingredient_groups?: Array<IngredientGroup>,
  ingredients: Array<Ingredient>,
  outcomes: Array<Outcome>,
  lookalike_audience?: LookalikeAudienceSettings,
}

export interface Config {
  help: string,
  dashboard_title: string,
  products: { [productId: string]: Product },
  optionalPages?: Array<string>
}

export type PerOutcome<T> = { [outcomeId: string]: T };
export type PerIngredient<T> = { [ingCol: string]: T };
export type PerCategory<T> = { [catValue: string]: T };

export type HistogramCounts = ({ [bin: string]: string|number, count: number })[];
export type CategoryCounts = ({ [ingCol: string]: string|number, count: number })[];
export type PointLevelValues = { [key: string]: string|number };
export type SegmentInfo = { segment: string, whatIfs: PerCategory<number> };

export interface InsightsData {
  audienceCount: number,
  baseCount: number,
  dayStart: string,
  dayEnd: string,
  queryTime: number
  byPoint: PerIngredient<PointLevelValues[]> | null,
  categoryCounts: PerIngredient<CategoryCounts>,
  categoryOutcomes: PerOutcome<PerIngredient<{ [outcomeOrIng: string]: number | string }[]>> | null,
  currentOutcomeValues: PerOutcome<number>,
  histograms: PerOutcome<{ buckets: HistogramCounts }>,
  min: PerOutcome<PerIngredient<number>>,
  max: PerOutcome<PerIngredient<number>>,
}

export interface SimulatorData {
  audienceCount: number,
  baseCount: number,
  dayStart: string,
  dayEnd: string,
  queryTime: number
  categoryCounts: PerIngredient<CategoryCounts>,
  currentOutcomeValues: PerOutcome<number>,
  histograms: PerOutcome<{ buckets: HistogramCounts }>,
  min: PerOutcome<PerIngredient<number>>,
  max: PerOutcome<PerIngredient<number>>,
  simulation: PerOutcome<PerIngredient<SegmentInfo[]>>,
}

export interface TrendsData {
  days: string[]
  // The number[] fields are then time series corresponding to these dates.
  outcomes: { [outcome: string]: number[] } // Outcome values.
  categories: { [category: string]: number[] } // Category populations.
  counts: number[] // Segment size per day
  queryTime?: number
}

export function isTrendsData(data: InsightsData | SimulatorData | TrendsData): data is TrendsData {
  return (data as TrendsData).days !== undefined;
}

export function isSimulatorData(
    data: InsightsData | SimulatorData | TrendsData): data is SimulatorData {
  return (data as SimulatorData).simulation !== undefined;
}

export interface DruidResponses {
  '/api/insightsQuery': InsightsData,
  '/api/simulatorQuery': SimulatorData,
  '/api/trendsQuery': TrendsData,
  '/api/onDemand/whatIfValues': PerOutcome<PerIngredient<PerCategory<number>>>,
}

export type DruidEndpoint = keyof DruidResponses;
