// Collections of utilities used by both server and client side
import { FilterKey, ImpactType } from './sharedTypes';

export function datetimeStr() {
  const d = new Date();
  const str =
    d.getFullYear() + '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
    ('0' + d.getDate()).slice(-2) + 'T' +
    ('0' + d.getHours()).slice(-2) +
    ('0' + d.getMinutes()).slice(-2) +
    ('0' + d.getSeconds()).slice(-2) +
    ('00' + d.getMilliseconds()).slice(-3);
  return str;
}

// Applies the function fn on each value of the object obj and returns the resulting object.
export function mapValues(obj: any, fn: any): any {
  const result: any = {};
  for (const [key, value] of Object.entries(obj)) {
    // Also pass the key as second argument to fn. In JS you can provide more args to a function than
    // what it expects so this won't be a problem if fn only expects the value but makes it possible
    // to also use functions that also use the key.
    result[key] = fn(value, key);
  }
  return result;
}

// Applies the function fn on each key of the object obj and returns the resulting object.
export function mapKeys(obj: any, fn: any): any {
  const result: any = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = fn(key);
    result[newKey] = value;
  }
  return result;
}

export const relativeImpactStepSize = 0.01; // 1%

function padding(v: number, requiredLength: number): string {
  const str = v.toString();
  const paddingZeroes = requiredLength > str.length ? requiredLength - str.length : 0;
  return '0'.repeat(paddingZeroes) + str;
}

export function dateToString(d: Date): string {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return padding(year, 4) + '-' + padding(month, 2) + '-' + padding(day, 2);
}

export function filterKeyToStr(filterKey: FilterKey): string {
  switch (filterKey.type) {
    case 'category':
      return `${filterKey.type}/${filterKey.ingredientId}`;
    case 'impact':
      return [
        filterKey.type,
        filterKey.ingredientId,
        filterKey.outcomeId,
        filterKey.relative ? 'relative' : 'absolute',
        filterKey.impactType,
      ].join('/');
    case 'outcome':
      return `${filterKey.type}/${filterKey.outcomeId}`;
    case 'list':
      return `${filterKey.type}/${filterKey.listname}`;
  }
}

export function parseFilterKey(key: string): FilterKey {
  const keyParts = key.split('/');
  const type = keyParts[0];
  switch (type) {
    case 'category':
      return { type, ingredientId: keyParts[1] };
    case 'impact':
      return {
        type,
        ingredientId: keyParts[1],
        outcomeId: keyParts[2],
        relative: keyParts[3] === 'relative',
        impactType: keyParts[4] as ImpactType,
      };
    case 'outcome':
      return { type, outcomeId: keyParts[1] };
    case 'list':
      return { type, listname: keyParts[1] };
    default:
      throw new Error('Unknown filter type!');
  }
}

export const Colors = {
  darkblue: '#004165',
  lightblue: '#39BCF3',
  mediumblue: '#5DA2D5',
  gray: '#C3C3C3',
  lightgray: '#EBEBEB',
  turquoise: '#16D4D4',
  cyclamen: '#F24C88',
  darkcyclamen: '#bb005b',
  black: '#000000',
  white: '#FFFFFF',
  charlotte: '#DFF2F6',  // very light blue kind of color...
  lightblack: '#7B7B7B',
  silver: '#CFCFCF',
  lightsilver: '#E2E2E2',
};
