import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { HistogramData } from '../sharedTypes';
import { Colors } from '../sharedUtils';


const useStyles = makeStyles((theme: Theme) =>
  ({
    wrapper: {
      width: '100%',
      height: '100%',
      marginRight: theme.spacing(0.5),
    },
  }),
);

interface Props {
  histogramData: HistogramData
  selected?: boolean
  avg?: number
}

export default function Histogram( props: Props ) {
  const classes = useStyles();
  const { bucketCounts, bounds, currentRange, stepSize } = props.histogramData;
  const numBuckets = bucketCounts.length;
  const bucketPixelPct = 1 / numBuckets * 100;
  const avgMarkerPct = props.avg ? (props.avg - bounds.lower) / (bounds.upper - bounds.lower) * 100 : 0;
  const maxCount = Math.max(...bucketCounts);
  const normalizedCounts = maxCount > 0 ? bucketCounts.map( c => c / maxCount * 100) : bucketCounts;
  const activeColor = Colors.mediumblue;
  const svgParams = normalizedCounts.map((normalizedCnt, i) => {
    const fillColor =
      (bounds.lower + (i + 1) * stepSize <= currentRange.lower) ||
      (bounds.lower + i * stepSize >= currentRange.upper) ? 'lightgray' : activeColor;
    // For long tail distributions we "emphasize" small values here
    // to make them distinguishable from zeros.
    const minColumnHeightPct = 2;
    const adjustedHeightPct = normalizedCnt > 0 ? Math.max(normalizedCnt, minColumnHeightPct) : 0;
    return {
      x: (i * bucketPixelPct),
      y: 100 - adjustedHeightPct,
      width: bucketPixelPct,
      height: adjustedHeightPct,
      fillColor: fillColor
    };
  });

  return (
    <div className={classes.wrapper}>
      <svg width='100%' height='100%'>
        {svgParams.map((bar, i) =>
          <rect
            x={`${bar.x}%`}
            y={`${bar.y}%`}
            width={`${bar.width}%`}
            height={`${bar.height}%`}
            fill={bar.fillColor}
            key={i}
            role='histogram-bar'
            />
        )}
        {props.avg !== undefined &&
          <>
            <rect
              x={`${avgMarkerPct}%`}
              y='0%'
              width={1}
              height='100%'
              fill={Colors.darkcyclamen}/>
          </>
        }
      </svg>
    </div>
  );
}
