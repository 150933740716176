/**
 * Functions that populates the XXXWithData types with their respective data.
 */

import { State, StateWith } from './store';
import { InsightsData, SimulatorData,
         Ingredient, PerOutcome, PerCategory, PointLevelValues } from './sharedTypes';
import { IngWithCommonData, SqIngWithCommonData, disabled, DisabledOrNumber } from './clientTypes';
import { filterKeyToStr, mapValues } from './sharedUtils';
import { scale } from './utils';


export function addCommonDataToIng(
    i: Ingredient, state: StateWith<InsightsData | SimulatorData>): IngWithCommonData {
  const selectedCategories = getSelectedCategoryValues(i, state);
  const countPerCategory = getCountPerCategory(i, state);
  const percentagePerCategory = getPercentagePerCategory(i, state);
  return { ...i, selectedCategories, countPerCategory, percentagePerCategory };
}

export function addCommonDataToSqIng(
    i: Ingredient, state: StateWith<InsightsData | SimulatorData>): SqIngWithCommonData {
  const outcome = state.selectedOutcomesByProduct[state.product];
  const min = getMin(i, state);
  const max = getMax(i, state);
  const curr = state.data.currentOutcomeValues[outcome.id];
  const impact = getImpact(i, state);
  const selectedCategories = getSelectedCategoryValues(i, state);
  const countPerCategory = getCountPerCategory(i, state);
  const percentagePerCategory = getPercentagePerCategory(i, state);
  return {
    ...i, ...impact, min, max, curr,
    selectedCategories, countPerCategory, percentagePerCategory,
  };
}

export function getMin(i: Ingredient, state: StateWith<InsightsData|SimulatorData>): number {
  const selectedOutcome = state.selectedOutcomesByProduct[state.product];
  return state.data.min[selectedOutcome.id][i.column];
}

export function getMax(i: Ingredient, state: StateWith<InsightsData|SimulatorData>): number {
  const outcome = state.selectedOutcomesByProduct[state.product];
  return state.data.max[outcome.id][i.column];
}

export function getWorst(i: Ingredient, state: StateWith<InsightsData|SimulatorData>): number {
  const outcome = state.selectedOutcomesByProduct[state.product];
  if (outcome.positive) {
    return getMin(i, state);
  } else {
    return getMax(i, state);
  }
}

export function getBest(i: Ingredient, state: StateWith<InsightsData|SimulatorData>): number {
  const outcome = state.selectedOutcomesByProduct[state.product];
  if (outcome.positive) {
    return getMax(i, state);
  } else {
    return getMin(i, state);
  }
}

export function getCurr(state: StateWith<InsightsData|SimulatorData>): number {
  const outcome = state.selectedOutcomesByProduct[state.product];
  return state.data.currentOutcomeValues[outcome.id];
}

export function getImpact(
    i: Ingredient,
    state: StateWith<InsightsData|SimulatorData>) {
  const outcome = state.selectedOutcomesByProduct[state.product];
  const curr = getCurr(state);
  const best = getBest(i, state);
  const worst = getWorst(i, state);
  const outcomeToRatio = (outcome: number) => outcome / worst;
  const outcomeToDelta = (outcome: number) => outcome - worst;
  const ratioToDelta = (ratio: number) => ratio * worst - worst;
  const deltaToRatio = (delta: number) => (delta + worst) / worst;
  const magnitude = (outcome: number) => scale(outcome, worst);
  const magnitudeToOutcome = (magnitude: number) => {
    const sign = outcome.positive ? 1 : -1;
    return Math.exp(sign * magnitude) * worst;
  };
  return {
    outcomeToRatio, outcomeToDelta, ratioToDelta, deltaToRatio,
    magnitude, magnitudeToOutcome,
    actualOutcomeRatio: outcomeToRatio(curr),
    potentialOutcomeRatio: outcomeToRatio(best),
    actualOutcomeDelta: outcomeToDelta(curr),
    potentialOutcomeDelta: outcomeToDelta(best),
    actualBar: magnitude(curr),
    potentialBar: magnitude(best),
  };
}

// List the categories that were not filtered out. If filter is not set in allFilters for an
// ingredient then none of the categories were filtered out.
export function getSelectedCategoryValues(i: Ingredient, state: State): string[] {
  const product = state.product;
  const allFilters = state.filtersByProduct[product];
  const filterKeyStr = filterKeyToStr({ type: 'category', ingredientId: i.column });
  const allCategoryValues = i.categories.map(c => c.value);
  const selectedCategoryValues = (allFilters[filterKeyStr] as string[]) || allCategoryValues;
  return selectedCategoryValues;
}

export function getCountPerCategory(
    i: Ingredient,
    state: StateWith<InsightsData|SimulatorData>): PerCategory<DisabledOrNumber> {
  if (i.categories.length === 0) {
    // Location ingredients don't have categories.
    return {};
  }
  const selectedCats = getSelectedCategoryValues(i, state);
  const countData = state.data.categoryCounts[i.column];
  const countPerCategory: PerCategory<DisabledOrNumber> = {};
  const categoryValues = i.categories.map(c => c.value);
  for (const catVal of categoryValues) {
    let count: DisabledOrNumber;
    if (selectedCats.includes(catVal)) {
      // The countData doesn't contain the categories whose count is 0.
      const countFromCountData = countData.find((cnt: any) => cnt[i.column] === catVal);
      count = countFromCountData ? countFromCountData.count : 0;
    } else {
      count = disabled;
    }
    countPerCategory[catVal] = count;
  }
  return countPerCategory;
}

export function getByPointValues(
    i: Ingredient, state: StateWith<InsightsData>): PointLevelValues[]  {
  return i.kind === 'location' ? state.data.byPoint![i.column] : [];
}

export function getPercentagePerCategory(
    i: Ingredient,
    state: StateWith<InsightsData|SimulatorData>): PerCategory<DisabledOrNumber> {
  const countPerCategory = getCountPerCategory(i, state);
  const audienceCount = state.data.audienceCount;
  return mapValues(
    countPerCategory,
    (cnt: DisabledOrNumber) => cnt === disabled ? cnt : 100 * cnt / audienceCount
  );
}

export function getOutcomePerCategory(
    i: Ingredient,
    state: StateWith<InsightsData>): PerOutcome<PerCategory<DisabledOrNumber>> {
  const selectedCats = getSelectedCategoryValues(i, state);
  const outcomes = state.cfg.products[state.product].outcomes;
  const outcomePerCategory: PerOutcome<PerCategory<DisabledOrNumber>> = {};
  for (const o of outcomes) {
    outcomePerCategory[o.id] = {};
    const allCategoryOutcomes = state.data.categoryOutcomes;
    if (allCategoryOutcomes === null || allCategoryOutcomes[o.id][i.column] === undefined) {
      // categoryOutcomes are only used on certain type of charts and thus We only query it for
      // ingredients that are shown on those charts.
      // If there is no such chart then the caregoryOutcomes is null.
      return {};
    }
    const categoryOutcomes = allCategoryOutcomes![o.id][i.column];
    const categoryValues = i.categories.map(c => c.value);
    for (const catVal of categoryValues) {
      let outcomeValue: DisabledOrNumber;
      if (selectedCats.includes(catVal)) {
        // The categoryOutcomes doesn't contain the categories whose outcome value is 0.
        const valueFromCategoryOutcomes = categoryOutcomes.find(
          (cnt: any) => cnt[i.column] === catVal);
        outcomeValue = (valueFromCategoryOutcomes ? valueFromCategoryOutcomes[o.id] : 0) as number;
      } else {
        outcomeValue = disabled;
      }
      outcomePerCategory[o.id][catVal] = outcomeValue;
    }
  }
  return outcomePerCategory;
}

type WhatIfs = { whatIfValues: PerCategory<number>, whatIfMagnitudes: PerCategory<number> };
export function computeWhatIfsFromSim(i: Ingredient, state: StateWith<SimulatorData>): WhatIfs {
  const outcome = state.selectedOutcomesByProduct[state.product];
  // Compute `whatIfValues` from `categoryCounts` and `simulation` so we don't need to include
  // `whatIfValues` in the druid query for the simulator page.
  const simulationData = state.data.simulation[outcome.id][i.column];
  const counts = getCountPerCategory(i, state);
  const whatIfValues: PerCategory<number> = {};
  const categoryValues = i.categories.map(i => i.value);
  for (const targetCat of categoryValues) {
    let whatIfSum = 0;
    for (const srcCat of categoryValues) {
      const cnt: number = counts[srcCat] === disabled ? 0 : (counts[srcCat] as number);
      const segmentInfo = simulationData.find(i => i.segment === srcCat);
      if (segmentInfo === undefined) {
        // simulationData only contains segments for the non-empty categories.
        continue;
      }
      const whatIfForSegment = segmentInfo.whatIfs[targetCat];
      whatIfSum += cnt * whatIfForSegment;
    }
    whatIfValues[targetCat] = whatIfSum / state.data.audienceCount;
  }
  const { magnitude } = getImpact(i, state);
  const whatIfMagnitudes = computeWhatIfMagnitudes(whatIfValues, magnitude);
  return { whatIfValues, whatIfMagnitudes };
}

export function computeWhatIfMagnitudes(
    whatIfValues: PerCategory<number>,
    magnitude: (outcome: number) => number): PerCategory<number> {
  return Object.fromEntries(Object.entries(whatIfValues).map(
      ([k, v]) => [k, magnitude(v)]
  ));
}
