import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Action, State, useSelectedPage } from '../store';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DatePickerProps,
} from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { log, DAY, closestDate } from '../utils';
import { dateToString } from '../sharedUtils';
import { SVGIcons } from '../icons';
import SvgIcon from '@material-ui/core/SvgIcon';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallText: {
      fontSize: '14px',
    }
  }),
);

function Picker(props: Omit<DatePickerProps, 'value'> & { date: Date }) {
  const classes = useStyles();
  const visibleDates = useSelector((state: State) => state.visibleDatesByProduct[state.product]);
  const minDate = visibleDates[0];
  const maxDate = visibleDates[visibleDates.length - 1];
  if (!visibleDates.includes(dateToString(props.date))) {
    props.onChange(closestDate(visibleDates, props.date));
    return null;
  }

  function shouldDisableDate(day: Date | null) {
    if (day === null) {
      return true;
    }
    const dateStr = dateToString(day);
    return !visibleDates.includes(dateStr);
  }
  return <DatePicker
      style={{width: 200}}
      value={props.date}
      inputVariant='outlined'
      margin='dense'
      label='Select Date'
      InputProps={{
        endAdornment:
            <InputAdornment position='end'>
                <SvgIcon>{SVGIcons.calendar('#707070')}</SvgIcon>
            </InputAdornment>,
        classes: {
          input: classes.smallText
        }
      }}
      shouldDisableDate={shouldDisableDate}
      minDate={minDate}
      maxDate={maxDate}
      format='dd / MM / yyyy'
      {...props}
      />;
}


export default function DateFilter() {
  const dispatch = useDispatch();
  const dates = useSelector((state: State) => state.selectedDatesByProduct[state.product]);
  const page = useSelectedPage();

  function updateDates(newDates: [Date | null, Date | null]) {
    if (newDates.includes(null)) {
      return; // Not sure when DatePicker gives us nulls, but we don't want them.
    }
    if (newDates[0]!.getTime() === dates[0].getTime()
      && newDates[1]!.getTime() === dates[1].getTime()) {
      return; // No actual change. Avoid triggering backend call.
    }
    log(`Changed date: ${dates} -> ${newDates}`);
    dispatch( { type: Action.SetDate, payload: newDates });
  }

  function updateDate(newDate: Date | null) {
    const earlier = newDate === null ? null : new Date(newDate.getTime() - 30 * DAY);
    updateDates([earlier, newDate]);
  }

  if (page === 'trends') {
    return (<>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Picker
          date={dates[0]}
          label='Start date'
          onChange={(d: Date | null) => updateDates([d, dates[1]])}
          />
      </MuiPickersUtilsProvider>
      &nbsp;
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Picker
          date={dates[1]}
          label='End date'
          onChange={(d: Date | null) => updateDates([dates[0], d])}
          />
      </MuiPickersUtilsProvider></>);
  } else {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Picker
          date={dates[1]}
          label='Select date'
          onChange={(d: Date | null) => updateDate(d)}
          />
      </MuiPickersUtilsProvider>);
  }
}
