import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { StateWith, useProductConfig, useDisplaySettings } from '../store';
import { Ingredient, Outcome, Product, Chart, SimpleChart, MapChart, DisplaySettings,
         InsightsData, PerOutcome, PerCategory, PointLevelValues } from '../sharedTypes';
import { Colors } from '../sharedUtils';
import { DisabledOrNumber, IngWithCommonData } from '../clientTypes';
import { LayoutConstants, segmentSizeStrings, SupportedColor, displayFormat, dynamicTemplate } from '../utils';
import { addCommonDataToIng, getByPointValues, getOutcomePerCategory } from '../addData';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';
import WidgetPanel from '../WidgetPanel';
import BarChart from '../charts/BarChart';
import PieChart from '../charts/PieChart';
import MultiLayerMap from '../charts/MultiLayerMap';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    distributionPanel: {
      marginTop: '20px',
      width: `calc(50% - ${LayoutConstants.pixelsBetweenDistributionCharts}px)`,
      height: '500px'
    },
  }),
);

interface Props {
  chart: Chart,
  ingredients: Ingredient[],
}

type LocalState = StateWith<InsightsData>;
interface IngWithLocalData extends IngWithCommonData {
  byPoint: PointLevelValues[],
  outcomePerCategory: PerOutcome<PerCategory<DisabledOrNumber>>,
}

export default function IngredientDistributionChart(props: Props) {
  const classes = useStyles();
  const { chart, ingredients } = props;
  const ingredientsWithData = useSelector((state: LocalState) =>
    ingredients.map(i => ({
      ...addCommonDataToIng(i, state),
      byPoint: getByPointValues(i, state),
      outcomePerCategory: getOutcomePerCategory(i, state),
    }))
  );
  const product = useSelector((state: LocalState) => state.product);
  const outcome = useSelector((state: LocalState) => state.selectedOutcomesByProduct[product]);
  const cfg = useProductConfig();
  const display = useDisplaySettings();

  const chartContent = chart.type === 'map' ?
    createMapChartContent(chart, ingredientsWithData, display, cfg)
    : createChartContentFromSingleIng(chart, ingredientsWithData[0], outcome);

  return (
    <WidgetPanel
      title={getTitle(chart, ingredients)}
      className={classes.distributionPanel}
    >
      {chartContent}
    </WidgetPanel>
  );
}

function getTitle(chart: Chart, ingredients: Ingredient[]) {
  if (chart.type === 'map') {
    return undefined;  // The Map chart has it's own title component with title included.
  } else {
    return ingredients[0].human_name;
  }
}

function createChartContentFromSingleIng(
  chart: SimpleChart, ingWithData: IngWithLocalData, outcome: Outcome) {

  const categories = ingWithData.categories;
  const labels = categories.map(cat => cat.display_name);
  const counts = categories.map(cat => ingWithData.countPerCategory[cat.value]);
  const colors = getColor(chart, ingWithData);
  const distributionTexts = getDistributionTexts(ingWithData);

  let plot;
  switch (chart.type) {
    case 'vertical_bar':
      plot = (
        <BarChart
          labels={labels}
          values={counts}
          barColor={colors}
          text={distributionTexts}
        />
      );
      break;
    case 'combined_vertical_bar':
      plot = (
        <BarChart
          labels={labels}
          values={counts}
          barColor={colors}
          text={distributionTexts}
          outcome={outcome}
          outcomeValues={categories.map(cat =>
            ingWithData.outcomePerCategory[outcome.id][cat.value])}
        />
      );
      break;
    case 'horizontal_bar':
      plot = (
        <BarChart
          labels={labels}
          values={counts}
          barColor={colors}
          text={distributionTexts}
          vertical={false}
        />
      );
      break;
    case 'pie':
      const pieText = categories.map(
        (cat, id) => `${cat.display_name} ${distributionTexts[id]}`);
      plot = (
        <PieChart
          labels={labels}
          values={counts}
          segmentColor={colors}
          text={pieText}
          layout={{margin: {t: 20}}}
        />
      );
      break;
    default:
      // TSLint insistst to have this
      plot = (
        <div>
        `Error while trying to plot chart type ${chart.type}. Please contact your admin.`
        </div>
      );
  }
  return (
    <OnlyIfDataUpToDate>
      {plot}
    </OnlyIfDataUpToDate>
  );
}

function createMapChartContent(
  chart: MapChart, ingsWithData: IngWithLocalData[], display: DisplaySettings, product: Product) {

  const layers = chart.layers;
  const layersWithData = layers.map((layer, id) => {
    const ing = ingsWithData.find(i => i.column === layer.src)!;
    let hoverText: string[], locations: string[], colorByOptions: string[];
    const stringValues: { [id: string]: string[] } = {};
    const numberValues: { [id: string]: DisabledOrNumber[] } = {};
    if (layer.mode === 'point') {
      locations = ing.byPoint.map(e => e[ing.column] as string);
      const hoverTextFormat = layer.pointHoverText?.format;
      hoverText = ing.byPoint!.map(e => {
        if (hoverTextFormat) {
          return dynamicTemplate(hoverTextFormat, e);
        } else {
          let hoverText = '';
          for (const key in e) {
            if (key !== ing.column) {
              // ingredient column contains the actual location, we dont want that on the hover text
              hoverText += `${key}: ${e[key]}<br>`;
            }
          }
          return hoverText;
        }
      });
      const extraColumns = layer.pointHoverText?.columnsToDisplay || [];
      for (const o of product.outcomes) {
        if (extraColumns.includes('total_' + o.id)) {
          numberValues[o.human_name] = ing.byPoint.map(e => e['total_' + o.id] as number);
        }
      }
      for (const i of product.ingredients) {
        if (extraColumns.includes(i.column)) {
          stringValues[i.human_name] = ing.byPoint.map(e => e[i.column] as string);
        }
      }
      colorByOptions = [...Object.keys(numberValues), ...Object.keys(stringValues)];
      colorByOptions.sort();
    } else {
      layer.mode = 'region'; // Make sure that mode is defined
      const distributionTexts = getDistributionTexts(ing, ' (');
      const knownLocations = ing.categories.filter(cat => cat.value.toLowerCase() !== 'unknown');

      function outcomeValue(outcome: Outcome, cat: string): DisabledOrNumber {
        return ing.outcomePerCategory[outcome.id][cat];
      }

      for (const o of product.outcomes) {
        numberValues[o.human_name] = knownLocations.map(cat => outcomeValue(o, cat.value));
      }
      hoverText = knownLocations.map((cat, id) => {
        if (ing.selectedCategories.includes(cat.value)) {
          const outcomeStr = product.outcomes.map(o =>
            o.human_name + ': ' + displayFormat(numberValues[o.human_name][id] as number, o));
          return (
            `<b>${cat.display_name}</b><br>${display.entities}: ${distributionTexts[id]})<br>`
            + outcomeStr.join('<br>'));
        } else {
          return '';
        }
      });
      locations = knownLocations.map(cat => cat.value);
      colorByOptions = [...Object.keys(numberValues), ...Object.keys(stringValues)];
      colorByOptions.sort();
      numberValues[`Number of ${display.entities}`] =
        knownLocations.map(cat => ing.countPerCategory[cat.value]);
      // Use the count as the default option.
      colorByOptions.unshift(`Number of ${display.entities}`);
    }
    return {
      ...layer,
      title: layer.title || ing.human_name,
      locations, stringValues, numberValues, hoverText, colorByOptions,
    };
  });
  return (
    <MultiLayerMap
       title={chart.title}
       layersWithData={layersWithData}
    />
  );
}

function getDistributionTexts(ingredient: IngWithCommonData, separator = '<br>'): string[] {
  return ingredient.categories.map(cat => {
    const catVal = cat.value;
    if (!ingredient.selectedCategories.includes(catVal)) {
      return '';
    }
    const count = ingredient.countPerCategory[catVal];
    const { digits, suffix } = segmentSizeStrings(count);
    const countStr = `${digits} ${suffix}`;
    const percentage = (ingredient.percentagePerCategory[catVal] as number);
    const percentageStr = `${percentage.toFixed(2)}%`;
      return `${countStr}${separator}${percentageStr}`;
    });
}

function getColor(chart: Chart, ingredient: Ingredient): string[] | undefined {
  if (chart.type === 'map') {
    return;  // The 'map type doesn't have properties 'colors' and 'main_color'.
  }
  const colors_specified = chart.colors || {};
  const colorNames = ingredient.categories.map(cat =>
    colors_specified[cat.value] || chart.main_color
  );
  const definedColorNames = colorNames.filter(c => c !== undefined) as SupportedColor[];
  if (definedColorNames.length === 0) {
    // No color was set, the charts will decide what default colors to use.
    return undefined;
  } else {
    return definedColorNames.map((name: SupportedColor) => Colors[name]);
  }
}

