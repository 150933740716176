import React, { useState, useEffect } from 'react';
import { createStore, applyMiddleware, Middleware } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import {
  Product, DisplaySettings, Config, Filters, FilterBoundsByProduct, Outcome, Ingredient,
  InsightsData, HistogramCounts, SimulatorData, TrendsData, isTrendsData, Bounds,
  DruidResponses, DruidEndpoint, HistogramData } from './sharedTypes';
import { PageId } from './clientTypes';
import { log, druidFetch, getDisplaySettings, orderedHistogramCounts } from './utils';
import { filterKeyToStr } from './sharedUtils';
import { initialDistributions } from './simulator/simulation';


export interface FiltersByProduct {
  [productId: string]: Filters
}

interface SelectedOutcomesByProduct {
  [productId: string]: Outcome
}

interface SelectedDatesByProduct {
  [productId: string]: [Date, Date] // Start and end. Only the end date is used most of the time.
}

export interface UserInfo {
  name: string,
  exportAllowed: { [productId: string]: boolean }
}

interface EditedKey {
  editedKey: string,
}

export type EditorState = 'no_edit' | 'new_filter' | EditedKey;

export interface SimulatorState {
  active: boolean
  originalDistributions: { [ingredientId: string]: { [categoryName: string]: number } }
  simulatedDistributions: { [ingredientId: string]: { [categoryName: string]: number } }
}

export interface SimulatorStateByProduct {
  [productId: string]: SimulatorState
}

export function isFilterKeyDefined(editorState: EditorState): editorState is EditedKey {
  return (editorState as EditedKey).editedKey !== undefined;
}

// Metadata for lists that contains the customers satisfying a filter set on a given date.
type SnapshotListSource = { type: 'snapshot', day: string };
// Metadata for lists that were created by uploading a CSV file.
type UploadedListSource = { type: 'upload', originalName: string };
export type ListMetadata = {
  saveDate: string,
  filename: string, // The internal ID and name of the file in the persistent dir.
  humanName: string, // The user-visible name of the list.
  numRows: number,
  source: SnapshotListSource | UploadedListSource,
};

// Options you can choose from the dropdowns on the MultilayerMap chart.
export type MapSetting = { layerTitle: string, colorBy: string | undefined };

export interface StateWith<DataType> {
  selectedPageId: PageId, // Which page are we on?
  selectedDatesByProduct: SelectedDatesByProduct,
  product: string,
  cfg: Config,
  userInfo: UserInfo,
  selectedOutcomesByProduct: SelectedOutcomesByProduct,
  filtersByProduct: FiltersByProduct,
  filterBoundsByProduct: FilterBoundsByProduct,
  mapSettingsByProduct: { [productId: string]: MapSetting },
  savedFilterSetsByProduct: { [productId: string]: { [name: string]: { saveDate: Date, filters: Filters} }},
  savedListsByProduct: { [productId: string]: { [filename: string]: ListMetadata }},
  visibleDatesByProduct: { [productId: string]: string[] },
  data: DataType,
  lastPendingFetch: Date | undefined,
  dataUpToDate: boolean,
  editorState: EditorState,
  simulatorStateByProduct: SimulatorStateByProduct,
  error?: string,
  categoriesForTrends: string[], // The categories selected for visualization on the trends page.
}

export type State = StateWith<InsightsData | SimulatorData | TrendsData>;

export type ChangeEvent = React.ChangeEvent<{ name?: string; value?: unknown, target?: HTMLElement }>;
export type MouseEvent = React.MouseEvent<HTMLButtonElement>;

const defaultState: State = {
  selectedPageId: 'insights',
  selectedDatesByProduct: {},
  product: 'test_product',
  cfg: {help: '', dashboard_title: '', products: {}},
  userInfo: {name: 'anonymous', exportAllowed: {}},
  selectedOutcomesByProduct: {},
  filtersByProduct: {},
  filterBoundsByProduct: {},
  mapSettingsByProduct: {},
  savedFilterSetsByProduct: {},
  savedListsByProduct: {},
  visibleDatesByProduct: {},
  data: {
    audienceCount: 0, baseCount: 0, dayStart: '', dayEnd: '', histograms: {}, queryTime: 0,
    min: {}, max: {}, currentOutcomeValues: {}, categoryCounts: {}, byPoint: null,
    categoryOutcomes: null,
  },
  lastPendingFetch: undefined,
  dataUpToDate: false,
  editorState: 'no_edit',
  simulatorStateByProduct: {},
  categoriesForTrends: [],
};

export enum Action {
  Init,
  SetDate,
  SetProduct,
  SetOutcome,
  AddOrUpdateFilter,
  RemoveFilter,
  SetFilters,
  ClearFilters,
  SetData,
  SetSavedFilterSets,
  SetSavedLists,
  SetMapSetting,
  SetVisibleDates,
  SetEditorState,
  UpdateSimulatedDistribution,
  ResetSimulation,
  SetError,
  SetSelectedPageId,
  SetCategoriesForTrends,
}

const actionsFollowedByDataUpdate = [
  Action.Init,
  Action.SetDate,
  Action.SetProduct,
  Action.AddOrUpdateFilter,
  Action.RemoveFilter,
  Action.ClearFilters,
  Action.SetFilters,
  Action.SetSelectedPageId,
  Action.SetCategoriesForTrends,
];

export const updateData: Middleware = store => next => async action => {
  if (action.type === Action.SetData) {
    // SetData actions are dispatched by this middleware and we don't want to apply the middleware
    // on them.
    next(action);
    return;
  }

  const timeStamp = new Date();
  if (action.meta) {
    action.meta.timeStamp = timeStamp;
  } else {
    action.meta = { timeStamp };
  }
  next(action);
  const state = store.getState();
  if (!state.dataUpToDate && state.lastPendingFetch === timeStamp) {
    const url = (`/api/${state.selectedPageId}Query` as unknown) as DruidEndpoint;
    try {
      const data = await druidFetch(url, state);
      store.dispatch({ type: Action.SetData, payload: data, meta: { timeStamp }});
    } catch (error) {
      store.dispatch(setError(error.message || error));
    }
  }
};

export type DispatchedAction = { type: Action, payload?: any, meta?: any };

function handleUIAction(state: State, action: DispatchedAction) {
  let filtersByProduct;
  let savedFilterSetsByProduct;
  let savedListsByProduct;
  let selectedOutcomesByProduct;
  let selectedDatesByProduct;
  let simulatorStateByProduct;
  switch (action.type) {
    case Action.Init:
      return {
        ...state,
        ...action.payload,
      };
    case Action.SetDate:
      selectedDatesByProduct = {
        ...state.selectedDatesByProduct,
        [state.product]: action.payload
      };
      return {
        ...state,
        selectedDatesByProduct,
      };
    case Action.SetProduct:
      return {
        ...state,
        product: action.payload,
        editorState: 'no_edit',
        categoriesForTrends: [],
      };
    case Action.SetOutcome:
      selectedOutcomesByProduct = {
        ...state.selectedOutcomesByProduct,
        [state.product]: action.payload
      };
      return {
        ...state,
        selectedOutcomesByProduct,
      };
    case Action.ClearFilters:
      filtersByProduct = {
        ...state.filtersByProduct,
        [state.product]: {}
      };
      return {
        ...state,
        filtersByProduct,
      };
    case Action.SetSavedFilterSets:
      savedFilterSetsByProduct = {
        ...state.savedFilterSetsByProduct,
        [state.product]: action.payload
      };
      return {
        ...state,
        savedFilterSetsByProduct
      };
    case Action.SetSavedLists:
      savedListsByProduct = {
        ...state.savedListsByProduct,
        [state.product]: action.payload
      };
      return {
        ...state,
        savedListsByProduct
      };
    case Action.SetMapSetting:
      const mapSettingsByProduct = {
        ...state.mapSettingsByProduct,
        [state.product]: action.payload,
      };
      return {
        ...state,
        mapSettingsByProduct,
      };
    case Action.SetVisibleDates:
      return {
        ...state,
        visibleDatesByProduct: action.payload
      };
    case Action.SetFilters:
      filtersByProduct = {
        ...state.filtersByProduct,
        [state.product]: action.payload
      };
      return {
        ...state,
        filtersByProduct,
      };
    case Action.AddOrUpdateFilter:
      const activeFilters = state.filtersByProduct[state.product];
      const { key, values } = action.payload;
      const updatedFilters = {
        ...activeFilters,
        [key]: values
      };
      filtersByProduct = {
        ...state.filtersByProduct,
        [state.product]: updatedFilters
      };
      return {
        ...state,
        filtersByProduct,
      };
    case Action.RemoveFilter:
      const newFiltersForProduct = {...state.filtersByProduct[state.product]};
      delete newFiltersForProduct[action.payload];
      filtersByProduct = {
        ...state.filtersByProduct,
        [state.product]: newFiltersForProduct
      };
      return {
        ...state,
        filtersByProduct,
      };
    case Action.SetEditorState:
      return {
        ...state,
        editorState: action.payload,
      };
    case Action.ResetSimulation:
      const ssbp = state.simulatorStateByProduct;
      simulatorStateByProduct = {
        ...ssbp,
        [state.product]: {
          ...ssbp[state.product],
          active: false,
          simulatedDistributions: cloneDeep(ssbp[state.product].originalDistributions),
        },
      };
      return {
        ...state,
        simulatorStateByProduct
      };
    case Action.UpdateSimulatedDistribution:
      const newSimulatedDistributions = {
        ...state.simulatorStateByProduct[state.product].simulatedDistributions,
        [action.payload.id]: action.payload.distribution
      };
      const editedSimulatorState = {
        active: true,
        originalDistributions: state.simulatorStateByProduct[state.product].originalDistributions,
        simulatedDistributions: newSimulatedDistributions
      };
      simulatorStateByProduct = {
        ...state.simulatorStateByProduct,
        [state.product]: editedSimulatorState
      };
      return {
        ...state,
        simulatorStateByProduct
      };
    case Action.SetError:
      log(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case Action.SetSelectedPageId:
      return {
        ...state,
        selectedPageId: action.payload,
      };
    case Action.SetCategoriesForTrends:
      return {
        ...state,
        categoriesForTrends: action.payload,
      };
    default:
      return state;
  }
}

// Modifies the state in place.
function resetSimulatorState(state: State) {
  const inactiveSimulatorState = {
    active: false,
    originalDistributions: {},
    simulatedDistributions: {}
  };
  inactiveSimulatorState.simulatedDistributions = initialDistributions(
    state.cfg.products[state.product].ingredients.filter( (i: Ingredient) => !i.context),
    (state.data as InsightsData | SimulatorData).categoryCounts // We need to compute the initial distributions from the new data.
  );
  inactiveSimulatorState.originalDistributions = cloneDeep(inactiveSimulatorState.simulatedDistributions);
  state.simulatorStateByProduct = {
    ...state.simulatorStateByProduct,
    [state.product]: inactiveSimulatorState
  };
}

export function update(state = defaultState, action: DispatchedAction): State {
  if (action.type === Action.SetData) {
    if (state.lastPendingFetch !== action.meta!.timeStamp) {
      // Ignore response from outdated request.
      return state;
    }
    const newState = {
      ...state,
      data: action.payload,
      dataUpToDate: true,
      lastPendingFetch: undefined,
    };
    if (state.selectedPageId === 'simulator') {
      resetSimulatorState(newState);
    }
    return newState;
  } else {
    const newState = handleUIAction(state, action);
    // checking action type first ensures that action.meta.timeStamp is populated
    // some internal actions are dispatched by redux that doesn't go through the middleware
    if ((actionsFollowedByDataUpdate.includes(action.type)
         || (state.selectedPageId === 'trends' && action.type === Action.SetOutcome))
        && (!state.lastPendingFetch || state.lastPendingFetch < action.meta.timeStamp)
        && !isEqual(state, newState)) {
      newState.lastPendingFetch = action.meta.timeStamp;
      newState.dataUpToDate = false;
    }
    return newState;
  }
}

export function useFetchEffect<T extends DruidEndpoint>(url: T): undefined | DruidResponses[T] {
  const [data, setData] = useState<undefined|DruidResponses[T]>(undefined);
  const state = useSelector((state: State) => state);
  const dispatch = useDispatch();
  const dispatchError = (msg: string) => dispatch(setError(msg));

  useEffect(() => {
    const fetchFromDruid = async () => {
      try {
        const resp = await druidFetch(url, state);
        setData(resp);
      } catch (error) {
        dispatchError(error.message || error);
      }
    };
    fetchFromDruid();
    // eslint-disable-next-line
    }, []);

  return data;
}

export function useProductConfig(): Product {
  return useSelector((state: State) => (state.cfg?.products[state.product]) || {});
}

export function useNumSqIngs(): number {
  return useSelector(
    (state: State) => (state.cfg.products[state.product].ingredients.filter( i => !i.context).length) || 0
  );
}

export function useAudienceCount(): number {
  return useSelector((state: State) => {
    if (isTrendsData(state.data)) {
      return Math.max(...state.data.counts);
    } else {
      return (state.data as SimulatorData).audienceCount;
    }
  });
}

export function useHistogram(outcomeId: string): HistogramCounts {
  return useSelector((state: State) => {
    if (isTrendsData(state.data) || state.data.histograms[outcomeId] === undefined) {
      return [];
    } else {
      return state.data.histograms[outcomeId].buckets;
    }
  });
}

export function useHistogramData(outcome: Outcome): HistogramData {
  const histogramCounts = useHistogram(outcome.id);
  const bounds: Bounds = useSelector((state: State) => {
    const { product, filterBoundsByProduct } = state;
    return filterBoundsByProduct[product][outcome.id].outcomeBounds;
  });
  const range = useOutcomeFilterRange(outcome);
  return {
    bucketCounts: orderedHistogramCounts(histogramCounts, outcome.stepSize, bounds, outcome),
    bounds,
    currentRange: range || bounds,
    stepSize: outcome.stepSize
  };
}

export function useSelectedOutcome(): Outcome {
  return useSelector((state: State) => state.selectedOutcomesByProduct[state.product]);
}

export function useError(): string | undefined {
  return useSelector((state: State) => state.error);
}
export function setError(error: string) {
  console.error(error); // tslint:disable-line:no-console
  return { type: Action.SetError, payload: error };
}

export function setSelectedPageAction(page: string) {
  return { type: Action.SetSelectedPageId, payload: page };
}
export function useSelectedPage(): string {
  return useSelector((state: State) => state.selectedPageId);
}

export function useDisplaySettings(): DisplaySettings {
  const cfg = useProductConfig();
  return getDisplaySettings(cfg);
}

export function useExportAllowed(): boolean {
  return useSelector((state: State) => state.userInfo.exportAllowed[state.product]);
}

export function newStore(initialState: Partial<State> = {}) {
  return createStore(update, { ...defaultState, ...initialState }, applyMiddleware(updateData));
}

export function useOutcomeFilterRange(outcome: Outcome): Bounds | undefined {
  return useSelector((state: State) => {
    const filters = state.filtersByProduct[state.product];
    const filterKeyStr = filterKeyToStr({ type: 'outcome', outcomeId: outcome.id });
    return filters[filterKeyStr] as Bounds | undefined;
  });
}
