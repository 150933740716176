import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { displayFormat } from '../utils';
import { Colors } from '../sharedUtils';
import { Outcome, Bounds, HistogramData } from '../sharedTypes';
import Histogram from './Histogram';


const useStyles = makeStyles(theme => ({
  sliderContainer: {
    borderColor: Colors.lightsilver,
    border: 'solid 1px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
  slider: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
  },
  bounds: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    caretColor: 'transparent'
  },
  histogramContainer: {
    height: '100px'
  }
}));

const StyledSlider = withStyles(theme => ({
  track: {
    height: theme.spacing(1),
  },
  rail: {
    height: theme.spacing(1),
    color: Colors.silver,
    borderRadius: theme.spacing(0.5),
  },
  thumb: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    '$disabled &': {
      backgroundColor: Colors.silver,
      marginTop: theme.spacing(-0.5),
      marginLeft: theme.spacing(-0.75),
      height: theme.spacing(2),
      width: theme.spacing(2),
    }
  },
  disabled: {
    color: `${Colors.silver}!important`
  }
}))(Slider);

interface Props {
  selectedRange: Bounds,
  setSelectedRange: (range: Bounds) => void,
  bounds: Bounds,
  stepSize: number,
  title?: string,
  outcome?: Outcome,
  prefix?: string,
  suffix?: string,
  histogramData?: HistogramData,
}

// Round to 2 decimals.
function round(x: number): number {
  return Math.round(x * 100) / 100;
}

export default function RangeSlider(props: Props) {
  const classes = useStyles();
  const { stepSize, title, histogramData } = props;
  // We want to display all-positive or all-negative ranges.
  // For all-negative ranges bounds.upper will be the higher absolute
  // value and we want to display it on the right. For the benefit of the
  // MUI Slider we use the absolute values and add back the signs as formatting.
  const sign = Math.sign(props.bounds.upper) || 1;
  // Avoid displaying fractions with many digits.
  const selectedRange = {
    lower: round(Math.abs(props.selectedRange.lower)),
    upper: round(Math.abs(props.selectedRange.upper)) };
  function setSelectedRange(range: { lower: number, upper: number }) {
    props.setSelectedRange({ lower: sign * range.lower, upper: sign * range.upper });
  }
  const bounds = {
    lower: round(Math.abs(props.bounds.lower)),
    upper: round(Math.abs(props.bounds.upper)) };

  function catchKeyEvent(event: React.KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  function handleInputChange(which: 'from' | 'to', newValue: number) {
    if (which === 'from') {
      setSelectedRange({ ...selectedRange, lower: newValue });
    } else {
      setSelectedRange({ ...selectedRange, upper: newValue });
    }
  }

  const adornments = {
    startAdornment:
      props.prefix && <InputAdornment position='start'>{props.prefix}</InputAdornment>,
    endAdornment:
      props.suffix && <InputAdornment position='end'>{props.suffix}</InputAdornment>,
  };

  return (
    <div className={classes.sliderContainer}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        { title &&
        <div>
          <b>{ title }</b>:
        </div>
        }
        <div style={{marginLeft: 'auto', height: '20px', paddingRight: '0px'}}>
          {selectedRange.lower === selectedRange.upper &&
            <Tooltip  title='Selected range is empty.' placement='right'>
              <WarningIcon fontSize='small' htmlColor={Colors.gray}></WarningIcon>
            </Tooltip>
          }
        </div>
      </div>

      <div style={{paddingTop: '8px', paddingBottom: '8px'}}>
        <Divider />
        <div className={classes.bounds}>
          <div>
            <TextField
              variant='outlined'
              label='from'
              type='number'
              margin='dense'
              inputProps={{ min: bounds.lower, max: selectedRange.upper, step: stepSize }}
              value={selectedRange.lower}
              onChange={(event) => { handleInputChange('from', parseFloat(event.target.value)); }}
              style={{ width: '136px'}}
              onKeyDown={(event) => { catchKeyEvent(event); }}
              data-testid={`${title || 'range'}-from`}
              InputProps={{
                className: classes.input,
                ...adornments,
              }}
            />
          </div>
          <div>
            <TextField
              variant='outlined'
              label='to'
              type='number'
              margin='dense'
              inputProps={{ min: selectedRange.lower, max: bounds.upper, step: stepSize }}
              value={selectedRange.upper}
              onChange={(event) => { handleInputChange('to', parseFloat(event.target.value)); }}
              style={{ width: '136px'}}
              onKeyDown={(event) => { catchKeyEvent(event); }}
              data-testid={`${title || 'range'}-to`}
              InputProps={{
                className: classes.input,
                ...adornments,
              }}
            />
          </div>
        </div>
      </div>
      { histogramData &&
        <div className={classes.histogramContainer}>
          <Histogram histogramData={histogramData}/>
        </div>
      }
      <div className={classes.slider}>
        <StyledSlider
            value={[selectedRange.lower, selectedRange.upper]}
            onChange={(event, r) => {
              const range = r as number[];
              setSelectedRange({ lower: range[0], upper: range[1] });
            }}
            valueLabelDisplay='off'
            aria-labelledby='range-slider'
            color='secondary'
            min={bounds.lower}
            max={bounds.upper}
            step={stepSize}
        />
      </div>
      <div className={classes.bounds}>
        <div>{props.prefix}{displayFormat(bounds.lower)}{props.suffix}</div>
        <div>{props.prefix}{displayFormat(bounds.upper)}{props.suffix}</div>
      </div>
    </div>
  );
}
