import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FilterValueSelector from './FilterValueSelector';
import FilterKeySelector from './FilterKeySelector';
import { Colors } from '../sharedUtils';
import { EditorState, isFilterKeyDefined } from '../store';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.darkblue,
    padding: '0.5em',
    borderRadius: '5px'
  },
}));

export default function SingleFilterEditor(
  {
    closeEditor,
    setCurrentlyEditedFilterKey,
    startEditingNewFilter,
    editorState,
  }: {
    closeEditor: () => void,
    setCurrentlyEditedFilterKey: (key: string) => void,
    startEditingNewFilter: () => void,
    editorState: EditorState,
  }) {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <div  className={classes.innerContainer}>
        <FilterKeySelector
          closeEditor={closeEditor}
          editorState={editorState}
          setCurrentlyEditedFilterKey={setCurrentlyEditedFilterKey}
        />
        { isFilterKeyDefined(editorState) &&
          <FilterValueSelector
            goBackToKeySelection={startEditingNewFilter}
            filterKey={editorState.editedKey}
            setFilterKey={setCurrentlyEditedFilterKey}
            closeEditor={closeEditor}
          />
        }
      </div>
    </div>
  );
}
