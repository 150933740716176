import { Ingredient, IngredientGroup, MapLayer, PerCategory } from './sharedTypes';

export const disabled = 'disabled';
export type DisabledOrNumber = 'disabled' | number;

export interface IngredientGroupWithData extends IngredientGroup {
  actualOutcomeRatio: number,  // Aggregates actual impact on the ingredients in this group.
  potentialOutcomeRatio: number,  // Aggregates potential impact on the ingredients in this group.
  actualOutcomeDelta: number,  // Same with absolute outcome values.
  potentialOutcomeDelta: number,
  actualBar: number,
  potentialBar: number,
}

export type ViewMode = 'absolute' | 'relative';

// Contains ingredient data that is available on both Insights and Simulator pages.
export interface IngWithCommonData extends Ingredient {
  selectedCategories: string[],  // The values of categories that are in the selected segment.
  countPerCategory: PerCategory<DisabledOrNumber>,
  percentagePerCategory: PerCategory<DisabledOrNumber>,
}

// Contains sq ingredient data that is available on both Insights and Simulator pages.
export interface SqIngWithCommonData extends IngWithCommonData {
  min: number,
  max: number,
  curr: number,
  actualOutcomeRatio: number,
  potentialOutcomeRatio: number,
  actualOutcomeDelta: number,
  potentialOutcomeDelta: number,
  actualBar: number,
  potentialBar: number,
  outcomeToRatio: (outcome: number) => number,
  outcomeToDelta: (outcome: number) => number,
  ratioToDelta: (ratio: number) => number,
  deltaToRatio: (delta: number) => number,
  magnitude: (outcome: number) => number,
  magnitudeToOutcome: (magnitude: number) => number,
}

export type MapLayerWithData = MapLayer & {
  locations: string[],
  // Values by which coloring is possible.
  stringValues: { [human_name: string]: string[] },
  numberValues: { [human_name: string]: DisabledOrNumber[] },
  colorByOptions: string[], // In the order to offer in the drop-down.
  hoverText: string[],
  title: string,
};

// Contains the data needed to display bars in the impact widget.
export interface ImpactBar {
  id: string,
  human_name: string,
  actualOutcomeRatio: number,
  potentialOutcomeRatio: number,
  actualOutcomeDelta: number,
  potentialOutcomeDelta: number,
  actualBar: number,
  potentialBar: number,
  worst?: number // Added for groups
}

export type PageId = 'insights' | 'simulator' | 'trends';
