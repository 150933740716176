import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useProductConfig, StateWith, useSelectedOutcome } from '../store';
import Button from '@material-ui/core/Button';
import { IngredientGroup, InsightsData } from '../sharedTypes';
import { ImpactBar, ViewMode } from '../clientTypes';
import { getServiceQualityIngs, LayoutConstants } from '../utils';
import { addCommonDataToSqIng } from '../addData';
import ImpactChart from './ImpactChart';
import IngredientsPanelTitle from './IngredientsPanelTitle';
import IngredientCategoriesWidget from './IngredientCategoriesWidget';
import WidgetPanel from '../WidgetPanel';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import GroupNavigator from './GroupNavigator';
import { getImpactBars, getCurrentGroup, collectIngGroupsWithData } from '../groups';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonAtTheBottom: {
      ...LayoutConstants.buttonAtTheBottomStyle(theme)
    },
    impactChartPanel: {
      padding: LayoutConstants.widgetPadding,
      overflowX: 'auto',
      flexGrow: 1,
    },
    impactChartContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    ingredientValuesPanel: {
      borderLeft: 'solid 5px rgb(250, 250, 250)'
    }
  }),
);

interface SortingOption {
  id: string,
  title: string,
  sortFn: (a: ImpactBar, b: ImpactBar) => number,
}

const sortingOptions: SortingOption[] = [
  {
    id: 'actualOutcomeRatio',
    title: 'Actual Impact',
    sortFn: (a: ImpactBar, b: ImpactBar) => b.actualBar - a.actualBar,
  },
  {
    id: 'potentialOutcomeRatio',
    title: 'Potential Impact',
    sortFn: (a: ImpactBar, b: ImpactBar) => b.potentialBar - a.potentialBar,
  },
  {
    id: 'unrealized',
    title: 'Unrealized Potential',
    sortFn: (a: ImpactBar, b: ImpactBar) =>
      (b.potentialBar - b.actualBar) - (a.potentialBar - a.actualBar),
  },
];

type LocalState = StateWith<InsightsData>;

export default function IngredientsPanel() {
  const classes = useStyles();

  const defaultSortBy = sortingOptions[0].id;
  const [viewMode, setViewMode] = useState('absolute' as ViewMode);
  const [sortBy, changeSortBy] = useState(defaultSortBy);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const dataUpToDate = useSelector((state: LocalState) => state.dataUpToDate);
  const cfg = useProductConfig();
  const [selectedIngId, changeSelectedIngId] = useState<undefined | string>(undefined);
  const outcome = useSelectedOutcome();

  const weAreInAGroup = currentPath.length > 0;
  const allIngredients = cfg.ingredients;
  const serviceQualityIngs = getServiceQualityIngs(allIngredients);
  const serviceQualityIngredientsWithData = useSelector((state: LocalState) =>
    serviceQualityIngs.map(i => addCommonDataToSqIng(i, state))
  );

  const ingredientGroups: IngredientGroup[] = cfg.ingredient_groups || [];
  const [showGroups, setShowGroups] = useState(ingredientGroups.length > 0);

  const ingredientGroupsWithData = collectIngGroupsWithData(
      ingredientGroups, serviceQualityIngredientsWithData, outcome
  );
  const currentGroup = getCurrentGroup(
    weAreInAGroup, currentPath, ingredientGroups, showGroups, serviceQualityIngs
  );
  const barsAreIngredients = currentGroup.subgroups === undefined;
  const impactBars: ImpactBar[] = getImpactBars(
    barsAreIngredients, currentGroup,
    serviceQualityIngredientsWithData, ingredientGroupsWithData);

  const selectedIngredientData = selectedIngId !== undefined
    ? serviceQualityIngredientsWithData.filter(i => i.human_name === selectedIngId)[0]
    : undefined;

  const sortOpt = sortingOptions.find(i => i.id === sortBy);
  const sortFn = sortOpt!.sortFn;

  function safelyChangePath(newPath: string[]) {
    changeSelectedIngId(undefined);
    setCurrentPath(newPath);
  }

  function diveIntoGroup(groupId: string) {
    const newPath = currentPath.concat(groupId);
    safelyChangePath(newPath);
  }

  function backToGroupLevel(groupId: string) {
    const idx = currentPath.indexOf(groupId);
    const newPath = currentPath.slice(0, idx + 1);
    safelyChangePath(newPath);
  }

  function backToTopLevel() {
    safelyChangePath([]);
  }

  function updateShowGroups() {
    if (showGroups) {
      backToTopLevel();
    }
    setShowGroups(!showGroups);
  }

  const widgetStyle = {
    width:  'calc(100% - 6px)',
    height: '600px',
    display: 'flex',
    flexWrap: 'nowrap',
    // Set the padding of the widget to 0 and set the panels padding instead so that the
    // panels' background nicely fills the widget.
    padding: '0em',
  };

  return (
      <WidgetPanel style={widgetStyle}>
        <div className={classes.impactChartPanel} >
          <IngredientsPanelTitle
            viewMode={viewMode}
            setViewMode={setViewMode}
            sortBy={sortBy}
            changeSortBy={changeSortBy}
            sortingOptionNames={sortingOptions.map(o => ({ title: o.title, id: o.id }))}
          />
          <div data-testid='ImpactChart' className={classes.impactChartContainer}>
            { !selectedIngId && ingredientGroups.length > 0 &&
                <GroupNavigator
                  currentPath={currentPath}
                  showGroups={showGroups}
                  updateShowGroups={updateShowGroups}
                  backToGroupLevel={backToGroupLevel}
                  backToTopLevel={backToTopLevel}
                  groups={cfg!.ingredient_groups!}
                />
            }
            <div style={{height: '440px'}}>
              <ImpactChart
                viewMode={viewMode}
                sortFn={sortFn}
                selectedIngredientData={selectedIngredientData}
                changeSelectedIngId={changeSelectedIngId}
                bars={impactBars}
                barsAreIngredients={barsAreIngredients}
                diveIntoGroup={diveIntoGroup}/>
            </div>
            <div className={classes.buttonAtTheBottom}>
            <span style={{flex: 1}}>
            </span>
            { selectedIngredientData !== undefined ?
                <Button
                  size='small'
                  onClick={() => changeSelectedIngId(undefined)} >
                    { weAreInAGroup ?
                      '< Show all ingredients in group' : '< Show all ingredients' }
                </Button>
              : <Chip
                  icon={<Icon>touch_app</Icon>}
                  label={`Click bar to ${barsAreIngredients ? 'view ingredient' : 'expand group'}`}
                  color='secondary'
                />
            }
            <span style={{flex: 1}}></span>
          </div>
          </div>
        </div>

        {selectedIngId && dataUpToDate &&
          <div className={classes.ingredientValuesPanel}>
            <IngredientCategoriesWidget selectedIngredientData={selectedIngredientData!} viewMode={viewMode}/>
          </div>
        }
      </WidgetPanel>
  );
}
