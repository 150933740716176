import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useProductConfig, State, Action } from '../store';
import { Bounds, ImpactFilterKey } from '../sharedTypes';
import { filterKeyToStr, parseFilterKey } from '../sharedUtils';
import RangeSlider from './RangeSlider';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
}));

export default function ImpactRangeSelector(
  {
    closeEditor,
    filterKey,
    setFilterKey,
    goBackToKeySelection,
  }: {
    closeEditor: () => void,
    filterKey: string,
    setFilterKey: (key: string) => void,
    goBackToKeySelection: () => void,
  }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productConfig = useProductConfig();
  const { outcomeId, ingredientId, relative, impactType } =
    parseFilterKey(filterKey) as ImpactFilterKey;
  const outcome = productConfig.outcomes.find(i => i.id === outcomeId)!;
  const ingredient = productConfig.ingredients.find(i => i.column === ingredientId)!;
  const filterBounds = useSelector((state: State) => {
    const { product, filterBoundsByProduct } = state;
    return filterBoundsByProduct[product][outcomeId].impactBounds[ingredientId];
  });
  const bounds = relative ? filterBounds.relative : filterBounds.absolute;

  const currentFilterRange: Bounds = useSelector((state: State) => {
    const { product, filtersByProduct } = state;
    return filtersByProduct[product][filterKey] as Bounds || bounds;
  }, shallowEqual);

  function setRelative(newRelative: boolean) {
    if (newRelative !== relative) {
      // Convert selections so the sliders stay in the same spot.
      const nb = newRelative ? filterBounds.relative : filterBounds.absolute;
      const convert = (x: number) =>
        nb.lower + (nb.upper - nb.lower) * (x - bounds.lower) / (bounds.upper - bounds.lower);
      const convertBounds = (b: Bounds) => ({ lower: convert(b.lower), upper: convert(b.upper) });
      setSelectedRange(convertBounds(selectedRange));
    }
    setFilterKey(filterKeyToStr({
      ...parseFilterKey(filterKey) as ImpactFilterKey,
      relative: newRelative }));
  }

  const [selectedRange, setSelectedRange] = useState(currentFilterRange);

  function updateFilters() {
    dispatch({
      type: Action.AddOrUpdateFilter,
      payload:  {
        key: filterKey,
        values: selectedRange,
      }
    });
    closeEditor();
  }

  function disabledReason() {
    if (selectedRange.lower === bounds.lower && selectedRange.upper === bounds.upper) {
      return 'No range is set.';
    } else if (selectedRange.upper === selectedRange.lower) {
      return 'Selected range is empty.';
    }
    return '';
  }

  function toDisplay(internal: Bounds): Bounds {
    return relative ? {
      lower: internal.lower * 100 - 100,
      upper: internal.upper * 100 - 100 } : internal;
  }
  function toInternal(display: Bounds): Bounds {
    return relative ? {
      lower: display.lower / 100 + 1,
      upper: display.upper / 100 + 1 } : display;
  }
  const stepSize = relative ? 1 : Math.min(1, (bounds.upper - bounds.lower) / 100);
  const prefix =
    (outcome.positive ? '+' : '−') // Unicode minus sign.
    + ((!relative && outcome.display && outcome.display.prefix) || '');
  let suffix = (outcome.display && outcome.display.suffix) || '';
  if (relative) {
    suffix = '%';
  } else if (suffix === '%') {
    suffix = ' pp'; // Absolute mode changes in % outcomes are percentage points.
  }

  return (
    <>
      <div>
        <Select
          value={relative ? 'relative' : 'absolute'}
          onChange={e => setRelative(e.target.value === 'relative')}>
          <MenuItem value='absolute'>Absolute</MenuItem>
          <MenuItem value='relative'>Relative</MenuItem>
        </Select>
        { impactType } impact
      </div>
      <div>of <b>{ingredient!.human_name}</b> on <b>{outcome!.human_name}</b></div>
      <RangeSlider
        prefix={prefix} suffix={suffix}
        selectedRange={toDisplay(selectedRange)}
        setSelectedRange={b => setSelectedRange(toInternal(b))}
        bounds={toDisplay(bounds)}
        stepSize={stepSize}
      />

      <div className={classes.buttons}>
        <Button onClick={goBackToKeySelection}>Cancel</Button>
        <Tooltip  title={disabledReason()} placement='right'>
          <span>
            <Button
              onClick={updateFilters}
              color='secondary'
              variant='contained'
              style={{marginLeft: '1em'}}
              disabled={disabledReason() !== ''}
              >Apply
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
}
