/**
 * Confirmation dialog to be used for "destructive" actions
 * such as filter set or list deletion.
 *
 * props:
 *   open: visibility of the dialog
 *   message: explanation of the action, to be confirmed
 *   action: the callback to be called in case the user confirms it
 *   close: callback for closing the dialog
 */

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import WarningIcon from '@material-ui/icons/Warning';


const useStyles = makeStyles((theme: Theme) =>
createStyles({
  button: {
    marginLeft: theme.spacing(2),
  },
  dialogTitle: {
    paddingBottom: '5px',
    textAlign: 'center',
    minWidth: '340px'
  },
  dialogContent: {
    maxHeight: '80vh'
  },
  question: {
    marginTop: theme.spacing(2),
  },
}),
);


interface Props {
  open: boolean
  message: string
  action: () => void
  close: () => void
}

export default function ConfirmDialog( props: Props) {
  const { open, message, action, close } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={close} open={open} classes={{ paper: classes.dialogContent}}>
      <DialogTitle className={classes.dialogTitle}><WarningIcon/></DialogTitle>
      <DialogContent>
        <div>{message}</div>
        <div className={classes.question}>Are you sure?</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (event) => { close(); event.stopPropagation(); }} color='secondary'>
          No
        </Button>
        <Button onClick={ (event) => { action(); close(); event.stopPropagation(); }} color='secondary'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
