import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(theme => createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

interface Props {
  children: any,
  title?: string,
}

export default function HelpDialog(props: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <span>
      <IconButton onClick={handleClickOpen} aria-label='help' size='small'>
        <HelpOutlineIcon></HelpOutlineIcon>
      </IconButton>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth='sm'>
        {props.title &&
          <DialogTitle >
          <Typography variant='h5'>{props.title}</Typography>
            <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        }
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

