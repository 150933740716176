// The dropdowns for configuring the trends chart.
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Action, State, ChangeEvent, useProductConfig, useSelectedOutcome, useDisplaySettings } from '../store';
import { chartColor, LayoutConstants } from '../utils';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';

function StyledSelect(props: SelectProps) {
  return <FormControl variant='outlined' style={{ minWidth: 200 }}>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <Select {...props}/>
    </FormControl>;
}

function box(color: string) {
  const style = {
    display: 'block',
    backgroundColor: color,
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    marginLeft: '8px',
  };
  return <div style={style}></div>;
}

interface Props {
  showCounts: boolean
  setShowCounts: (v: boolean) => void
}

export default function TrendsControls(props: Props) {
  const dispatch = useDispatch();
  const display = useDisplaySettings();
  const outcome = useSelectedOutcome();
  const product = useProductConfig();
  const categoriesForTrends = useSelector((state: State) => state.categoriesForTrends);

  const optionMap = Object.assign(
    {}, ...product.ingredients.flatMap(ing =>
      ing.categories.map(
        cat => {
          const id = `${ing.column}/${cat.value}`;  // As stored in the state.data
          const info = {
            chip: `${ing.human_name}: ${cat.display_name}`,  // As displayed on the chip.
            ing: ing.human_name,  // Used for grouping.
            cat: cat.display_name,  // As displayed on the dropdown list.
          };
          return { [id]: info };
        }
      )
    )
  );
  const options = Object.keys(optionMap);
  const selectedOptions = options.filter(o => categoriesForTrends.includes(o));

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...LayoutConstants.paperMargins }}>
      <StyledSelect
        label='Outcome'
        value={outcome.id}
        onChange={(e: ChangeEvent) => {
          const outcome = product.outcomes.find(o => o.id === e.target.value)!;
          dispatch({ type: Action.SetOutcome, payload: outcome });
        }}
        inputProps={{'data-testid': 'trends-outcome-selector'}}
        >
        { product.outcomes.map(o =>
          <MenuItem key={o.id} value={o.id}>{o.human_name}</MenuItem>
        ) }
      </StyledSelect>
      <span style={{flex: 1}}/>
      <span style={{marginRight: '32px'}}>
        <Checkbox
            checked={props.showCounts}
            onChange={(event) => { props.setShowCounts(event.target.checked); }}
            value='showCounts'
            data-testid='trends-show-counts'
        />
        Show number of {display.entities}
      </span>

      <Autocomplete
        style={{ minWidth: '200px', maxWidth: '470px' }}
        multiple
        filterSelectedOptions
        value={selectedOptions}
        onChange={(event, newValues) => {
          dispatch({ type: Action.SetCategoriesForTrends, payload: newValues });
        } }
        options={options}
        groupBy={(option) => optionMap[option].ing}
        getOptionLabel={(option) => optionMap[option].cat}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => {
            const selectionIndex = categoriesForTrends.indexOf(option);
            return <Chip
              {...getTagProps({ index })}
              label={optionMap[option].chip}
              icon={box(chartColor(selectionIndex))}
            />;
          })
        }
        data-testid='trends-category-selector'
        renderInput={(params) => {
          return <TextField {...params} label='Categories to display' variant='outlined' />;
        }}
      />
    </div>);
}
