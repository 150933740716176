import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { State, Action, ChangeEvent } from '../store';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TextField from '@material-ui/core/TextField';
import DateFilter from './DateFilter';
import MenuItem from '@material-ui/core/MenuItem';
import FilterEditor from './FilterEditor';
import FilterPanelActions from './FilterPanelActions';
import { log, LayoutConstants } from '../utils';


const flex = {
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
};

const row = {
  ...flex,
  width: '100%',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      ...flex,
      flexDirection: 'column' as 'column',
      padding: theme.spacing(2),
      paddingTop: '0px',
      minWidth: '1200px',
      ...LayoutConstants.paperMargins,
    },
    row: {
      ...row,
      padding: theme.spacing(1),
    },
    leftRightRow: {
      ...row,
      padding: theme.spacing(1),
      justifyContent: 'space-between',
    },
    leftJustified: {
      ...flex,
      justifyContent: 'flex-start',
    },
    rightJustified: {
      ...flex,
      justifyContent: 'flex-end',
    },
    smallText: {
      fontSize: '14px'
    }
  }),
);


export default function FilterPanel() {
  const { cfg, product } = useSelector((state: State) => {
    const { cfg, product } = state;
    return {
      cfg,
      product,
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const classes = useStyles();


  function onProductChange(event: ChangeEvent) {
    const newProductId = event.target.value;
    dispatch({ type: Action.SetProduct, payload: newProductId });
    log(`Changed product: ${product} -> ${newProductId}`);
  }


  return (
    <div className={classes.table}>
      <div className={classes.leftRightRow}>
        <div className={classes.leftJustified}>
            <TextField
              style={{minWidth: 200}}
              select
              value={product}
              onChange={onProductChange}
              label='Select product'
              variant='outlined'
              margin='dense'
              SelectProps={{
                IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
              }}
              InputProps={{
                classes: {
                  input: classes.smallText
                }
              }}
              inputProps={{'data-testid': 'product-selector'}}
              >
              {Object.keys(cfg.products).map(productId => (
                <MenuItem key={productId} value={productId}>
                  {cfg.products[productId].product_name} &nbsp;
                </MenuItem>)
              )}
            </TextField>
          <span style={{marginRight: '1em'}}></span>
          <DateFilter />
        </div>
        <div className={classes.rightJustified}>
          <FilterPanelActions/>
        </div>
      </div>
      <FilterEditor/>
    </div>
  );
}
