import React  from 'react';
import { useSelector } from 'react-redux';
import { State } from './store';
import LoadingAnimation from './LoadingAnimation';

export function WaitForIt({ toBeTrue, children }: { toBeTrue: boolean, children: any }) {
  const error = useSelector((state: State) => state.error);
  return (
    <div>
      { toBeTrue ? children : !error && <LoadingAnimation/>}
    </div>
  );
}

export default function OnlyIfDataUpToDate({ children }: { children: any }) {
  const dataUpToDate = useSelector((state: State) => state.dataUpToDate);
  return (
    <WaitForIt toBeTrue={dataUpToDate}>
      { children }
    </WaitForIt>
  );
}
