import { Theme } from '@material-ui/core/styles';
import mergeWith from 'lodash.mergewith';
import { Bounds, DisplaySettings, Config, Product, Ingredient, Outcome, PerCategory, MapChart,
  DruidResponses, DruidEndpoint, OutcomeCardType, NPSCuts, HistogramData } from './sharedTypes';
import { SqIngWithCommonData, DisabledOrNumber, disabled } from './clientTypes';
import { Colors, dateToString } from './sharedUtils';
import { State, MapSetting } from './store';
import chroma from 'chroma-js';

// Merge objects in such a way that the array properties are concatenated. E.g.
// o1 = { l: [1, 2] } and o2 = { l: [3, 4] } are merged into { l: [1, 2, 3, 4] }.
// Useful for merging layouts where e.g. the annotations are stored as list. Using
// simple merge would overwrite the first layout's annotations while using this one
// collects all the annotations.
// Uses 'lodash.mergewith', description of its usage can be found here:
// https://lodash.com/docs/4.17.15#mergeWith .
export function mergeWhileConcatenatingArrays(objects: any[]) {
  const [obj, ...sources] = objects;
  return mergeWith(obj, ...sources, concatArraycustomizer);
}

function concatArraycustomizer(objValue: any, srcValue: any) {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return objValue.concat(srcValue);
  }
}

export function getServiceQualityIngs(ingredients: Ingredient[]) {
  return ingredients.filter(i => !i.context);
}

export function getContextIngs(ingredients: Ingredient[]) {
  return ingredients.filter(i => i.context);
}

const numberFormats: { [key: string]: any } = {};

interface NumberFormatOption {
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
}

function numberFormat(
    num: number,
    opts: NumberFormatOption = { maximumFractionDigits: 2, minimumFractionDigits: 0 }) {
  const key = `${opts.minimumFractionDigits} to ${opts.maximumFractionDigits}`;
  if (numberFormats[key] === undefined) {
    numberFormats[key] = new Intl.NumberFormat('en-US', opts);
  }
  return numberFormats[key].format(num);
}

export function segmentSizeStrings(n: DisabledOrNumber) {
  let digits;
  let suffix;
  if (n === disabled) {
    return { digits, suffix };
  }
  if (n < 100000) {
    digits = numberFormat(n);
    suffix = '';
  } else if (n < 1000000) {
    digits = (Math.round(n / 1000)).toFixed(0);
    suffix = 'thousand';
  } else if (n < 100000000) {
    digits = (n / 1000000).toFixed(1);
    suffix = 'million';
  } else {
    digits = numberFormat(Math.round(n / 1000000));
    suffix = 'million';
  }
  return { digits, suffix };
}

export function displayFormat(value: number, outcome?: Outcome) {
  let prefix = '';
  let suffix = '';

  const minimumFractionDigits = 0;
  let maximumFractionDigits;
  if (value < 10) {
    maximumFractionDigits = 2;
  } else if (value < 100) {
    maximumFractionDigits = 1;
  } else {
    maximumFractionDigits = 0;
  }
  const opts = { minimumFractionDigits, maximumFractionDigits };
  const formattedNumber = numberFormat(value, opts);

  if (outcome?.display) {
    prefix = outcome.display.prefix || prefix;
    suffix = outcome.display.suffix || suffix;
  }

  return `${prefix}${formattedNumber}${suffix}`;
}

export function displayRange(values: Bounds, outcome?: Outcome) {
  const d = (x: number) => displayFormat(x, outcome);
  return d(values.lower) + ' — ' + d(values.upper); // utf8 'em dash'
}

export function displayImpactRange(values: Bounds, outcome: Outcome, relative: boolean) {
  return displayImpact(values.lower, outcome, relative)
    + ' — ' // utf8 'em dash'
    + displayImpact(values.upper, outcome, relative);
}

export function displayImpact(
    value: number, outcome: Outcome, relative: boolean, useUnit?: boolean) {
  useUnit = useUnit === undefined ? false : useUnit;
  const sign = outcome.positive ? '+' : '−'; // Unicode minus sign.
  let displayedValue;
  if (relative) {
    displayedValue = (value >= 1
      ? `${sign}${displayFormat(100 * value - 100)}%`
      : `${sign}${displayFormat(100 - 100 * value)}%`);
  } else {
    displayedValue = sign
      + displayFormat(Math.abs(value), outcome).replace('%', ' pp');
    // For % outcomes an absolute change is a percentage point change.
  }
  const unit = useUnit && outcome.display && outcome.display.impact_unit;
  return unit ? `${displayedValue} ${unit}` : displayedValue;
}

// Adds '<br>' line-breaks in the string to enforce a line length limit.
export function wrap(str: string, limit: number) {
  if (limit <= 1) { return str; }
  const possibleBreakPoints = [' ', '/'];
  let parts = [str];
  for (const breakPoint of possibleBreakPoints) {
    const furtherSplittedParts = [];
    for (const p of parts) {
      const splitted = p.split(breakPoint);
      for (const i of splitted.slice(0, -1)) {
        furtherSplittedParts.push(i + breakPoint);
      }
      furtherSplittedParts.push(splitted[splitted.length - 1]);
    }
    parts = furtherSplittedParts;
  }
  const words = parts;
  const lines = [];
  let letters = 0;
  let line = [];
  for (const w of words) {
    if (line.length > 0 && letters + w.length > limit) {
      lines.push(line.join(''));
      line = [];
      letters = 0;
    }
    line.push(w);
    letters += w.length;
  }
  lines.push(line.join(''));
  return lines.join('<br>');
}

interface SqIngWithWhatIfMagnitudes extends SqIngWithCommonData {
  whatIfMagnitudes: PerCategory<number>,
}
export function sortCategoriesByWhatIf(sqIngredient: SqIngWithWhatIfMagnitudes) {
  const magnitudes = sqIngredient.whatIfMagnitudes;
  return sqIngredient.categories
    .concat()  // Copy list to avoid mutating it.
    .sort((c1, c2) => magnitudes[c2.value] - magnitudes[c1.value]);
}

export type SupportedColor = keyof typeof Colors;

// Takes a colorCode (e.g. Colors.gray) and returns a lighter version of the color.
// The `value` parameter decides "how much" lighter.
export function lighter(colorCode: string, value: number = 1) {
  const chromaColor = chroma(colorCode);
  return chromaColor.brighten(value).hex();
}

// Based on our "official" product colors.
const lynxColors = [
  Colors.darkblue, Colors.turquoise, Colors.mediumblue, Colors.lightblue,
  Colors.gray, Colors.cyclamen];

export function getCategoryColors(categories: string[]): { [category: string]: string } {
  const paletteSize = categories.length;
  const sortedCategories = categories.concat().sort();
  const palette = chroma.scale(lynxColors).mode('lab').colors(paletteSize);
  const categoryColors: { [category: string]: string } = {};
  for (const [id, cat] of sortedCategories.entries()) {
    categoryColors[cat] = palette[id];
  }
  return categoryColors;
}

// Returns colors from the chart color palette.
export function chartColor(n: number) {
  return lynxColors[n % lynxColors.length];
}

function labelAxisSettings() {
  return {
    autorange: true,
    showgrid: false,
    zeroline: false,
    showline: false,
    type: 'category',
    categoryorder: 'trace',
    showticklabels: true,
    ticks: '',
    tickangle: 0,
    fixedrange: true,
    tickmode: 'auto',
  };
}

function dataAxisSettings() {
  return {
    autorange: true,
    showgrid: false,
    zeroline: false,
    showline: false,
    showticklabels: false,
    ticks: '',
    tickangle: 0,
    fixedrange: true,
    type: 'linear',
    tickmode: 'auto',
  };
}
export type Axis = 'xaxis' | 'yaxis';

// Parameter labelAxis is the name of the corresponding axis in the layout.
export function baseLayout(labelAxis: Axis = 'yaxis') {
  const dataAxis = labelAxis === 'yaxis' ? 'xaxis' : 'yaxis';
  return {
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    showlegend: false,
    dragmode: false,
    selectdirection: labelAxis === 'yaxis' ? 'h' : 'v',
    [labelAxis]: labelAxisSettings(),
    [dataAxis]: dataAxisSettings(),
    font: { size: 10, familiy: 'Roboto' },
    autosize: true,
  };
}

export function baseConfig() {
  return {
    displayModeBar: false,
    scrollZoom: false,
  };
}

// Collects the layout constants used by multiple components of the dashboard.
export const LayoutConstants = {
  roboto: 'Roboto',
  pageBackgroundColor: '#FAFAFA',
  lightBlueBackgroundColor: '#EAF7FF',
  pixelsBetweenDistributionCharts: 40,
  widgetPadding: '0.5em',
  paperMargins: {
    marginLeft: '0.2em',
    marginRight: '0.2em',
  },
  buttonAtTheBottomStyle: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
};

export async function protectedJsonFetch(url: string, payload: any) {
  payload.headers = payload.headers || {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  const response = await fetch(url, payload);
  if (response.status === 204) {  // No content.
    throw new Error(`
      The current user does not have proper permissions to access the dashboard.
      It's probably a Keycloak configuration issue.
      Please contact the administrator to set the required permissions.<br/>
      You can also try to log in with a different user.`);
  } if (response.status === 403) {
    window.location.href = '/login';
    throw new Error('Redirecting to login page...');
  } if (response.status >= 200 && response.status < 300) {
    return await response.json();
  } else {
    throw new Error(await response.text());
  }
}

export async function druidFetch<T extends DruidEndpoint>(
    url: T, state: State): Promise<DruidResponses[T]> {
  const product = state.product;
  const filters = (state.filtersByProduct || {})[product] || [];
  const [day1, day2] = state.selectedDatesByProduct[product];
  let params: { [key: string]: string } = {};
  switch (url) {
    case '/api/insightsQuery':
      params = {
        product,
        day: dateToString(day2),
        filters: JSON.stringify(filters),
      };
      break;
    case '/api/simulatorQuery':
      params = {
        product,
        day: dateToString(day2),
        filters: JSON.stringify(filters),
      };
      break;
    case '/api/trendsQuery':
      const days = sampleAvailableDates(day1, day2, state.visibleDatesByProduct[product], 10);
      params = {
        product,
        days: JSON.stringify(days.map(d => dateToString(d))),
        filters: JSON.stringify(filters),
        outcomes: JSON.stringify([state.selectedOutcomesByProduct[product].id]),
        categories: JSON.stringify(state.categoriesForTrends),
      };
      break;
    case '/api/onDemand/whatIfValues':
      params = {
        product,
        day: dateToString(day2),
        filters: JSON.stringify(filters),
      };
  }
  const paramString = new URLSearchParams(params).toString();
  let fullUrl = url as string;
  let payload = {};
  if (paramString.length < 1000) {
    // Use GET if we can so we can cache the fetch.
    fullUrl = url + '?' + paramString;
  } else {
    // Fall back to POST if the URL would be too long.
    payload = { method: 'POST', body: JSON.stringify(params) };
  }

  const data = await protectedJsonFetch(fullUrl, payload);
  return data;
}

// TSlint friendly function to concat arrays of the same type.
export function concat<T>(arrays: T[][]): T[] {
  const empty: T[] = [];
  return empty.concat(...arrays);
}

// Returns the distinct elements of an array.
export function distinct<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

// While it's an async function, it's best to call this without
// waiting for it. Let it log in the background.
export async function log(message: string) {
  const payload = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message }),
  };
  try {
    await fetch('/api/logs', payload);
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error('Logging error failed:', error);
  }
}

// We don't display diffs and simulated values if the diff is below a threshold.
export function isDiffSmall(value: number, simulatedValue: number) {
  const epsilon = 0.1;
  const diffPct = (simulatedValue - value) / value * 100;
  return Math.abs(diffPct) < epsilon;
}

/**
 * Use a dictionary to replace all occurrences of the keys in the given template
 * string with the corresponding values.
 *
 * The keys to replace should appears as {{key}} in the string template.
 *
 * @param template {[string]} Template string in which to replace values
 * @param replace_values {[{[key: string]: any}]} Dictionary with values to replace
 */
export function dynamicTemplate(
  template: string, replace_values: {[key: string]: any}): string {
  for (const key in replace_values) {
    template = template.replace('{{' + key + '}}', replace_values[key].toString());
  }
  return template;
}

export function entitiesLabel(display: DisplaySettings, count: number) {
  const segmentSize = displayFormat(count);
  const entityPart = count === 1 ? display.entity : display.entities;
  return `${segmentSize} ${entityPart}`;
}

export function uniqueName(newName: string, existingNames: string[]): string {
  if (existingNames.indexOf(newName) === -1) {
    return newName;
  } else {
    let i = 1;
    let modifiedName = `${newName} (${i})`;
    while (existingNames.indexOf(modifiedName) !== -1) {
      i += 1;
      modifiedName = `${newName} (${i})`;
    }
    return modifiedName;
  }
}

export const DAY = 24 * 3600 * 1000;

// Sets the hours, minutes etc to 0.
function removeTimePart(date: Date): Date {
  return new Date(dateToString(date));
}

export function closestDate(visibleDates: string[], date: Date): Date {
  let delta = 0;
  const time  = date.getTime();
  while (visibleDates.length) {
    const up = new Date(time + delta * DAY);
    const down = new Date(time - delta * DAY);
    // `visibleDates` and every other dates stored as a state have a 0 time part.
    // We make sure to also return such a Date.
    if (visibleDates.includes(dateToString(up))) {
      return removeTimePart(up);
    }
    if (visibleDates.includes(dateToString(down))) {
      return removeTimePart(down);
    }
    delta += 1;
  }
  return removeTimePart(date);
}

export function sampleAvailableDates(
  fromDate: Date, toDate: Date, visibleDates: string[], maxSampleSize: number): Date[] {
  const fromTime = fromDate.getTime();
  const toTime = toDate.getTime();
  const availableDates = visibleDates
    .map(d => new Date(d))
    .filter(d => {
      const t = d.getTime();
      return fromTime <= t && t <= toTime;
    });
  if (availableDates.length <= maxSampleSize) {
    return availableDates;
  } else {
    // Check how many ms are there in the interval [fromDate, toDate].
    const timeDiff = toTime - fromTime;
    // Both ends of the interval are included.
    const numberOfIntervals = maxSampleSize - 1;
    // How many ms should be between 2 neighboring sampled date on average.
    const sampleIntervalMs = timeDiff / numberOfIntervals;
    const sampleSet: Set<Date> = new Set();
    const day = new Date(toDate);
    for (let i = 0; i < maxSampleSize; i++) {
      const d = closestDate(visibleDates, day);
      sampleSet.add(d);
      // Using day.setDate(day.getDate - x) gave different result in Node.js than in Chrome.
      // https://github.com/biggraph/biggraph/pull/8107#issuecomment-454763837
      day.setTime(day.getTime() - sampleIntervalMs);
    }
    return [...sampleSet].sort((a: Date, b: Date) => a.getTime() - b.getTime());
  }
}

// Contains the formula of converting an outcome value
// and a worst value into an "impact score" which is on
// a logarithmic scale.
export function scale(current: number, worst: number): number {
  return Math.abs(Math.log(current / worst));
}

export function orderedHistogramCounts(
  histogramCounts: { [key: string]: string|number }[],
  stepSize: number, bounds: Bounds, outcome: Outcome) {
  const existingBuckets: { [key: string]: number } = {};
  const bin_prop = `${outcome.id}_bin`;
  for (const bucket of histogramCounts) {
    existingBuckets[bucket[bin_prop]] = bucket.count as number;
  }
  const lowerId = bounds.lower / stepSize;
  const upperId = bounds.upper / stepSize;
  const counts: number[] = [];
  for (let i = lowerId; i < upperId; i++) {
    const bin = `bin_${i}`;
    const bucket = histogramCounts.find(bucket => bucket[bin_prop] === bin) || { count: 0 };
    const cnt = bucket.count;
    counts.push(cnt as number);
  }
  return counts;
}

export function npsFromHistogramCounts(histogramData: HistogramData, npsCuts: NPSCuts) {
  const { bucketCounts, bounds, stepSize, currentRange } = histogramData;
  let detr = 0;
  let prom = 0;
  let all = 0;
  const lowerId = bounds.lower / stepSize;
  const upperId = bounds.upper / stepSize;
  for (let i = lowerId; i < upperId; i++) {
    const cnt = bucketCounts[i];
    const bucket_low_end = bounds.lower + i * stepSize;
    const bucket_high_end = bucket_low_end + stepSize;
    // Below we use the fact that a bucket's width is 1 stepSize
    // and currentRange is at least 1 stepSize.
    if (bucket_low_end >= currentRange.lower && bucket_high_end <= currentRange.upper) {
      all += cnt;
      if (bucket_high_end <= npsCuts.detractorBelow) {
        detr += cnt;
      }
      if (bucket_low_end >= npsCuts.promoterAbove) {
        prom += cnt;
      }
    }
  }
  return { detr, prom, all};
}


export function outcomeCardType(o: Outcome): OutcomeCardType {
  if (o.cardType !== undefined) {
    return o.cardType;
  }
  if (o.id === 'chi') {
    return 'gauge';
  }
  return 'histogram';
}

export function getDisplaySettings(product: Product): DisplaySettings {
  const ds: Partial<DisplaySettings> = {
    // Defaults.
    entity: 'customer',
    entity_icon: 'customer',
    ...product.display };
  if (!ds.entities) {
    ds.entities = ds.entity + 's'; // Set simple plural if not set.
  }
  return ds as DisplaySettings;
}

export function getDefaultMapSettings(cfg: Config): { [productId: string]: MapSetting } {
  const mapSettingsByProduct: { [productId: string]: MapSetting } = {};
  for (const [productId, productCfg] of Object.entries(cfg.products)) {
    const mapChart = (productCfg.charts || []).find(c => c.type === 'map') as MapChart;
    if (mapChart === undefined) {
      continue;
    }
    const layerTitle = getDefaultMapLayer(mapChart, productCfg);
    const colorBy = getDefaultMapColorBy(mapChart, productCfg);
    mapSettingsByProduct[productId] = { layerTitle, colorBy };
  }
  return mapSettingsByProduct;
}

function getDefaultMapLayer(mapChart: MapChart, productCfg: Product): string {
  const firstLayer = mapChart.layers[0];
  if (firstLayer.title) {
    return firstLayer.title;
  } else {
    const srcIng = productCfg.ingredients.find(i => i.column === firstLayer.src)!;
    return srcIng.human_name;
  }
}

function getDefaultMapColorBy(mapChart: MapChart, productCfg: Product): string {
  const firstLayer = mapChart.layers[0];
  if (firstLayer.mode === 'point') {
    const extraColumns = firstLayer.pointHoverText?.columnsToDisplay || [];
    const colorByOptions = [];
    for (const col of extraColumns) {
      if (col.startsWith('total_')) {
        const outcome = productCfg.outcomes.find(o => o.id === col.slice('total_'.length))!;
        colorByOptions.push(outcome.human_name);
      } else {
        const ing = productCfg.ingredients.find(i => i.column === col)!;
        colorByOptions.push(ing.human_name);
      }
    }
    return colorByOptions.sort()[0];
  } else {
    const entities = getDisplaySettings(productCfg).entities;
    return  `Number of ${entities}`;
  }
}
