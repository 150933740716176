import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useProductConfig, State, Action } from '../store';
import { Outcome } from '../sharedTypes';
import { makeStyles, Theme } from '@material-ui/core/styles';
import HIGauge from './HIGauge';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';
import { Colors } from '../sharedUtils';
import { lighter } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  ({
    gaugeContainer: {
      width: '112px',
      height: '96px',
      margin: '0 auto',
      padding: 0,
      paddingBottom: 0,
      overflow: 'hidden',
    },
    outerContainer: (selected: boolean) => ({
      display: 'inline-block',
      width: '140px',
      height: '120px',
      margin: '0px',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      padding: '2px',
      paddingBottom: '0px',
      '&:hover': {
        backgroundColor: selected ? lighter(Colors.darkblue) : Colors.lightsilver,
        cursor: 'pointer',
        color: selected ? Colors.white : Colors.black,
      },
      backgroundColor: selected ? Colors.darkblue : Colors.white,
      borderRadius: selected ? 4 : 0,
      color: selected ? Colors.white : 'inherit',
    }),
    hiScoreTitle: {
      fontSize: '12px',
      fontWeight: 400, // Same as on MUI card.
      marginLeft: theme.spacing(0.5),

    }
  })
);

export default function HiScore() {
  const selectedOutcome = useSelector((state: State) => (
    state.selectedOutcomesByProduct[state.product])
  );
  const CHIOutcome = useProductConfig().outcomes.find( (x: Outcome) => x.id === 'chi');
  const selected = selectedOutcome.id === 'chi';
  const classes = useStyles(selected);
  const dispatch = useDispatch();

  function updateOutcome() {
    dispatch({ type: Action.SetOutcome, payload: CHIOutcome });
  }

  return (
    <div className={classes.outerContainer} onClick={updateOutcome} data-testid='outcome-card'>
      <div className={classes.hiScoreTitle}>{CHIOutcome!.human_name}</div>
      <div className={`${classes.gaugeContainer}`}>
        <OnlyIfDataUpToDate>
          <HIGauge selected={selected}/>
        </OnlyIfDataUpToDate>
      </div>
    </div>
  );
}
