// The Sankey diagram showing movement between categories below the distribution editor
// on the simulator page.
import React from 'react';
import { useDisplaySettings, useAudienceCount } from '../store';
import { Plot } from './Plot';
import { Ingredient } from '../sharedTypes';
import { Distribution, MovementMatrix } from '../simulator/simulation';

export default function CategoryMovementChart(props: {
  ingredient: Ingredient,
  categoryColors: { [category: string]: string },
  originalDistribution: Distribution,
  movement: MovementMatrix,
}) {
  const entities = useDisplaySettings().entities;
  const audienceCount = useAudienceCount();
  const cats = Object.keys(props.movement);
  cats.sort();
  const catNames = cats.map((c: string) => props.ingredient.categories.find(x => x.value === c)!.display_name);
  const nodeColors = cats.map(c => props.categoryColors[c]);
  const data = [{
    type: 'sankey',
    orientation: 'h',
    valuesuffix: ' ' + entities,
    arrangement: 'fixed',
    node: {
      hoverinfo: 'skip',
      label: [...catNames, ...catNames],
      color: [...nodeColors, ...nodeColors],
    },
    link: {
      hoverinfo: 'skip',
      color: '#5da2d550',
      source: [] as number[],
      target: [] as number[],
      value: [] as number[],
    },
  }];
  for (let src = 0; src < cats.length; ++src) {
    const srcPop = audienceCount * props.originalDistribution[cats[src]];
    for (let dst = 0; dst < cats.length; ++dst) {
      const movePop = Math.round(srcPop * props.movement[cats[src]][cats[dst]]);
      if (movePop >= 1) {
        data[0].link.value.push(movePop);
        data[0].link.source.push(src);
        data[0].link.target.push(dst + cats.length); // Destination nodes are in the second half.
      }
    }
  }
  const layout = {
    margin: { l: 20, r: 20, t: 20, b: 20 },
    width: 528,
    height: 300,
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
  };
  const config = {
    displayModeBar: false,
    displaylogo: false,
  };

  return (
    <div>
      <Plot data={data} layout={layout} config={config}/>
    </div>
  );
}
