import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Colors } from '../sharedUtils';
import SvgIcon from '@material-ui/core/SvgIcon';


const useStyles = makeStyles(theme => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '12px',
    cursor: 'pointer',
    borderBottom: `solid ${Colors.darkblue} 1px`,
    borderTop: `solid ${Colors.darkblue} 1px`,
    '&:hover': {
      borderBottom: `solid ${Colors.lightblue} 1px`,
      borderTop: `solid ${Colors.lightblue} 1px`,
    }
  },
  strip: (active: boolean) => ({
    width: theme.spacing(1),
    backgroundColor: active ? Colors.lightblue : Colors.darkblue,
  }),
  item: (active: boolean) => ({
    width: '100%',
    backgroundColor: active ? `rgba(57, 188, 243, 0.15)` : '',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    'flex-direction': 'column',
  }),
  menuIcon: (active: boolean) => ({
    margin: theme.spacing(1),
    color: active ? Colors.lightblue : Colors.white,
    marginBottom: '0px',
  }),
  iconContainer: {
    'text-align': 'center',
    width: theme.spacing(8.5),
  },
  pageName: (active: boolean) => ({
    fontSize: '10px',
    color: active ? Colors.lightblue : Colors.white,
    'text-align': 'center',
    paddingBottom: theme.spacing(0.5),
    width: theme.spacing(8.5),
    minHeight: '24px',
  }),
}));


interface Props {
  label: string,
  icon: (rgb: string) => JSX.Element,
  active: boolean,
  handleActivate: () => void,
}


export default function SideMenuItem(props: Props) {
  const { label, icon, active, handleActivate } = props;
  const classes = useStyles(active);

  return (
    <div className={classes.root} onClick={handleActivate}>
      <div className={classes.strip}></div>
      <div className={classes.item}>
        <div className={classes.iconContainer}>
          <SvgIcon className={classes.menuIcon}>
            {icon(active ? Colors.lightblue : Colors.white)}
          </SvgIcon>
        </div>
        <div className={classes.pageName} data-testid={`side-menu-item-${label}`}> {label} </div>
      </div>
    </div>
  );
}
