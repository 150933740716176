import React from 'react';
import { Outcome } from '../sharedTypes';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Colors } from '../sharedUtils';
import { isDiffSmall } from '../utils';


export const arrowGreen = '#4ABDAC';
export const arrowRed = '#FC4A1A';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-around'
    },
    value: (selected: boolean) => ({
      fontSize: '10px',
      color: selected ? 'white' : 'black',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
    selectedOutcomeCard: {
      border: 'solid 1px', borderColor: Colors.darkblue, borderRadius: '4px'
    },
  }),
);

interface Props {
  outcome: Outcome
  selected: boolean
  value: number
  simulatedValue: number
}

export default function DiffArrow( props: Props) {
  const { outcome, selected, value, simulatedValue } = props;
  const classes = useStyles(selected);
  const diffPct = (simulatedValue - value) / value * 100;
  if (isDiffSmall(value, simulatedValue)) { // Diff is too small to display anything.
    return <></>;
  }
  const upColor = outcome.positive ? arrowGreen : arrowRed;
  const downColor = outcome.positive ? arrowRed : arrowGreen;

  return (
    <div className={ classes.main }>
      {Math.sign(diffPct) > 0 ?
        <>
          <svg viewBox='0 0 32 20' width='32' height='20'>
            <polygon
              data-testid='arrow'
              points='0,16 32,16 16,0'
              style={{
                fill: upColor,
                stroke: upColor,
                strokeWidth: '0.5',
                strokeLinejoin: 'round'
              }}/>
          </svg>
          <div className={classes.value}>{diffPct.toFixed(1)} %</div>
        </> :
        <>
          <div className={classes.value}>{Math.abs(diffPct).toFixed(1)} %</div>
          <svg viewBox='0 0 32 20' width='32' height='20'>
            <polygon
              data-testid='arrow'
              points='0,4 32,4 16,20'
              style={{
                fill: downColor,
                stroke: downColor,
                strokeWidth: '0.5',
                strokeLinejoin: 'round'
              }}/>
          </svg>
        </>
      }

    </div>
  );
}
