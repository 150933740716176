import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useProductConfig, State, Action, EditorState, isFilterKeyDefined,
         useDisplaySettings } from '../store';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import {
  FilterKey, Bounds, CategoryFilterKey, OutcomeFilterKey, ImpactFilterKey, ListFilterKey,
  ListFilterValue, FilterValue } from '../sharedTypes';
import { parseFilterKey, filterKeyToStr } from '../sharedUtils';
import { displayRange, displayImpactRange, log } from '../utils';
import { Colors } from '../sharedUtils';
import { SVGIcons } from '../icons';

const useStyles = makeStyles(theme => ({
  verticalDivider: {
    width: '1px',
    borderRight: `solid ${Colors.gray} 1px`,
    marginLeft: '5px',
    marginRight: '5px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  chip: {
    margin: theme.spacing(1),
  },
  chipList: {
    width: theme.spacing(148),
  },
  toggle: {
    marginTop: '10px',
  },
  mainIcon: {
    margin: theme.spacing(1),
    paddingTop: '0.2em'
  }
}));

function VerticalDivider() {
  const classes = useStyles();
  return <div className={classes.verticalDivider}></div>;
}

interface Props {
  editorState: EditorState,
  startEditingNewFilter: () => void,
  closeEditor: () => void,
  setCurrentlyEditedFilterKey: (filterKey: string) => void
}

export default function ChipContainer(props: Props) {
  const { editorState, startEditingNewFilter, closeEditor,
          setCurrentlyEditedFilterKey } = props;
  const classes = useStyles();
  const display = useDisplaySettings();
  const product = useSelector((state: State) => state.product);
  const activeFilters = useSelector((state: State) => state.filtersByProduct[product]);
  const savedLists = useSelector((state: State) => state.savedListsByProduct[product]);
  const dispatch = useDispatch();
  const productConfig = useProductConfig();
  const ingredients = productConfig.ingredients;
  const outcomes = productConfig.outcomes;
  const editorActive = editorState !== 'no_edit';

  function removeFilter(key: string) {
    log(`Remove filter: ${key}`);
    dispatch({ type: Action.RemoveFilter, payload: key });
  }

  function filterChip(key: FilterKey, label: string,
    {clickable = true}: {clickable?: boolean} = {}) {
    const keyStr = filterKeyToStr(key);
    const currentlyEdited = isFilterKeyDefined(editorState) && editorState.editedKey === keyStr;
    return (
      <Chip
        key={keyStr}
        onDelete={() => removeFilter(keyStr)}
        label={label}
        className={classes.chip}
        color={currentlyEdited || editorActive ? 'secondary' : 'default'}
        onClick={() => clickable && setCurrentlyEditedFilterKey(keyStr)}
        disabled={editorActive}
        variant={currentlyEdited ? 'default' : 'outlined'}
      />
    );
  }

  function categoryFilterToChip(key: CategoryFilterKey, value: string[]) {
    const name = ingredients.find(ing => ing.column === key.ingredientId)!.human_name;
    const ing = productConfig.ingredients.find(x => x.column === key.ingredientId)!;
    let valueNames;
    if (ing.kind === 'location') {
      // Nobody cares to see 10 decimals of GPS coordinates.
      valueNames = [value.length === 1 ? '1 location' : value.length + ' locations'];
    } else if (value.length > 5) {
      // Avoid listing too many entries.
      valueNames = ['list of ' + value.length + ' values'];
    } else {
      valueNames = value.map(v => {
        const c = ing.categories.find(c => c.value === v);
        return c ? c.display_name : v;
      });
    }
    const label = `${name}: ${valueNames.join(' | ')}`;
    return filterChip(key, label, {clickable: ing.categories.length > 0});
  }

  function outcomeRangeFilterToChip(key: OutcomeFilterKey, range: Bounds) {
    const outcome = outcomes.find(o => o.id === key.outcomeId);
    const name = outcome!.human_name;
    const label = `${name}: ${displayRange(range, outcome)}`;
    return filterChip(key, label);
  }

  function impactRangeFilterToChip(key: ImpactFilterKey, range: Bounds) {
    const outcome = outcomes.find(o => o.id === key.outcomeId)!;
    const ingredient = ingredients.find(i => i.column === key.ingredientId)!;
    const outcomeName = outcome.human_name;
    const ingredientName = ingredient.human_name;
    const rangeDisplay = displayImpactRange(range, outcome, key.relative);
    const label =
      `${key.relative ? 'Relative' : 'Absolute'} ${key.impactType} impact` +
      ` of ${ingredientName} on ${outcomeName}: ${rangeDisplay}`;
    return filterChip(key, label);
  }

  function listFilterToChip(key: ListFilterKey, value: ListFilterValue) {
    const list = savedLists[key.listname];
    const label =
      `${value.listUsage} ${display.entities} from list: "${list.humanName}"`;
    return filterChip(key, label);
  }

  function filterToChip([key, value]: [string, FilterValue]) {
    const filterKey = parseFilterKey(key);
    switch (filterKey.type) {
      case 'category':
        return categoryFilterToChip(filterKey as CategoryFilterKey, value as string[]);
      case 'outcome':
        return outcomeRangeFilterToChip(filterKey as OutcomeFilterKey, value as Bounds);
      case 'impact':
        return impactRangeFilterToChip(filterKey as ImpactFilterKey, value as Bounds);
      case 'list':
        return listFilterToChip(filterKey as ListFilterKey, value as ListFilterValue);
    }
  }

  function clearFilters() {
    dispatch({ type: Action.ClearFilters });
  }

  return (
    <div className={classes.row}>
      <SvgIcon className={classes.mainIcon}>
        {SVGIcons.funnel(Colors.darkblue)}
      </SvgIcon>
      <VerticalDivider/>
      <div className={classes.chipList}>
        { Object.entries(activeFilters).map(filterToChip) }
        <Chip
          key='add_filters'
          label='Add filters'
          color={isFilterKeyDefined(editorState) ? 'default' : 'secondary'}
          icon={<AddIcon/>}
          onClick={startEditingNewFilter}
          className={classes.chip}
          disabled={editorActive}
          variant={isFilterKeyDefined(editorState) ? 'outlined' : 'default'}
        />
      </div>
      <div style={{ padding: '10px', marginLeft: 'auto'}}>
        {Object.keys(activeFilters).length > 0 &&
        <Button
          size='small'
          onClick={clearFilters}
          disabled={editorActive}>Clear all filters</Button>
        }
      </div>
      <div className={classes.toggle}>
          {!editorActive ?
            <IconButton onClick={startEditingNewFilter} size='small'>
              <Icon color='secondary'>keyboard_arrow_down</Icon>
            </IconButton>
            :
            <IconButton onClick={closeEditor} size='small'>
              <Icon color='secondary'>keyboard_arrow_up</Icon>
            </IconButton>
          }
      </div>
    </div>
  );
}
