import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useProductConfig, useSelectedOutcome, State, Action, StateWith, useHistogramData } from '../store';
import { HistogramData, InsightsData, Outcome } from '../sharedTypes';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import WidgetPanel from '../WidgetPanel';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';
import HiScore from './HiScore';
import { Colors } from '../sharedUtils';
import { displayFormat, log, npsFromHistogramCounts, outcomeCardType } from '../utils';
import Tooltip from '@material-ui/core/Tooltip';
import Histogram from '../filters/Histogram';


const outcomeGroupBlue = '#DFf2F6';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      position: 'absolute',
      bottom: '10px',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -5%)',
      fontSize: '32px',
      fontWeight: 'bold',
    },
    explanation: {
      position: 'absolute',
      top: '40%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '12px',
    },
    actionArea: {
      width: '100%',
      height: '100%',
    },
    card: (opts: any) => ({
      width: opts.numOutcomes <= 5 ? 136 : 750 / opts.numOutcomes,
      height: 112,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    }),
    cardContent: {
      width: '100%',
      height: '100%',
    },
    cardsContainer: {
      height: 128,
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      position: 'absolute',
      top: '5px',
      fontSize: '12px',
    },
    selected: {
      backgroundColor: Colors.darkblue,
      color: Colors.white,
    },
    notSelected: {
      color: Colors.darkblue,
    },
    outcomeContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: outcomeGroupBlue,
      borderRadius: '4px',
      marginTop: theme.spacing(-1),
      border: `solid 1px ${outcomeGroupBlue}`
    },
    spacer: {
      width: theme.spacing(1),
    },
    tooltip: {
      backgroundColor: Colors.lightblack
    },
    tooltipArrow: {
      color: Colors.lightblack
    },
    absoluteCenter: {
      position: 'absolute',
      bottom: '4px',
      left: '50%',
      transform: 'translate(-50%, 0px)',
      fontSize: '8px'

    }
  }),
);

type LocalState = StateWith<InsightsData>;

export function OutcomeCardValue({ outcome }: { outcome: Outcome }) {
  const classes = useStyles();
  const currentOutcomeValues = useSelector((state: LocalState) => state.data.currentOutcomeValues);

  function template(value: number, outcome: Outcome) {
    return (
      <>
        {outcome.display && outcome.display.description &&
          <Typography align='center' className={classes.explanation}>
            {outcome.display.description}
          </Typography>
        }
        <Typography align='center' className={classes.value}>
          {displayFormat(value, outcome)}
        </Typography>
      </>
    );
  }

  function displayValue(outcome: Outcome) {
    const value = currentOutcomeValues[outcome.id];
    return template(value, outcome);
  }

  return displayValue(outcome);
}

interface OutcomeHistogramProps {
  outcome: Outcome
  selected: boolean
  histogramData: HistogramData
}

export function OutcomeHistogram( props: OutcomeHistogramProps ) {
  const currentOutcomeValue = useSelector((state: LocalState) => state.data.currentOutcomeValues[props.outcome.id]);

  return (
    <div style={{width: '120px', height: '72px', padding: '4px'}}>
      <Histogram
        histogramData={props.histogramData}
        avg={currentOutcomeValue}
        selected={props.selected}
      />
    </div>);
}

interface NPSBarProps {
  promoters: number
  detractors: number
  all: number
}

export function NPSBar( props: NPSBarProps ) {
  const dpct = props.detractors / props.all * 100;
  const ppct = props.promoters / props.all * 100;

  return (
    <div style={{width: '120px', height: '8px', padding: '4px'}}>
      <svg width='100%' height='100%'>
        <rect x={0} y={'0%'} width={`${dpct}%`} height={'100%'} style={{fill: Colors.cyclamen}}/>
        <rect x={`${dpct}%`} y={'0%'} width={`${100 - dpct - ppct}%`} height={'100%'} style={{fill: Colors.gray}}/>
        <rect x={`${100 - ppct}%`} y={'0%'} width={`${ppct}%`} height={'100%'} style={{fill: Colors.mediumblue}}/>
      </svg>
    </div>
  );
}

function histogramDescription(outcome: Outcome, histogramData: HistogramData) {
  const first = `
    The ${outcome.human_name} histogram shows buckets
    of size ${displayFormat(outcome.stepSize, outcome)} from
    ${displayFormat(histogramData.bounds.lower, outcome)}
    to
    ${displayFormat(histogramData.bounds.upper, outcome)}.`;
  let low = -1;
  let high = -1;
  for (let i = 0; i < histogramData.bucketCounts.length; i++) {
    const cut = histogramData.bounds.lower + i * outcome.stepSize;
    if (low === -1 && histogramData.bucketCounts[i] > 0 && cut >= histogramData.currentRange.lower) {
      low = cut;
    }
  }
  for (let i = histogramData.bucketCounts.length - 1; i >= 0; i--) {
    const cut = histogramData.bounds.lower + i * outcome.stepSize;
    if (high === -1 && histogramData.bucketCounts[i] > 0 && cut <= histogramData.currentRange.upper) {
      high = Math.min(cut + outcome.stepSize, histogramData.currentRange.upper); // adjust last bucket
    }
  }
  const second = `The current values are between ${displayFormat(low, outcome)} and ${displayFormat(high, outcome)}.`;

  return <>
      <p>{first}</p>
      <p>{second}</p>
    </> ;
}

function npsDescription(detr: number, prom: number, all: number) {
  const promoters = `Promoters: ${displayFormat(prom)} (${displayFormat(prom / all * 100)}%)`;
  const neutrals = `Neutrals: ${displayFormat(all - prom - detr)} (${displayFormat((all - prom - detr) / all * 100)}%)`;
  const detractors = `Detractors: ${displayFormat(detr)} (${displayFormat(detr / all * 100)}%)`;

  return (
    <>
      <p>{promoters}</p>
      <p>{neutrals}</p>
      <p>{detractors}</p>
    </>
  );
}


export default function OutcomeSelector() {
  const product = useSelector((state: State) => state.product);
  const selectedOutcome = useSelectedOutcome();
  const productOutcomes = useProductConfig().outcomes;
  const outcomeIds = productOutcomes.map((o: Outcome) => o.id);
  const widgetTitle = outcomeIds.length > 1 ? 'Estimated Outcomes' : 'Estimated Outcome';
  const classes = useStyles({ selectedOutcome, numOutcomes: outcomeIds.length });
  const dispatch = useDispatch();


  function isSelected(outcome: Outcome) {
    return outcome.id === selectedOutcome.id;
  }

  function cardClass(outcome: Outcome) {
    return `${classes.card} ${isSelected(outcome) ? classes.selected : classes.notSelected}`;
  }

  function updateOutcome(outcome: Outcome) {
    log(
      `Changed outcome for product ${product}: ` +
      `${selectedOutcome.human_name} -> ${outcome.human_name}`
    );
    dispatch({ type: Action.SetOutcome, payload: outcome });
  }

  function OutcomeCard({outcome, tooltip, children}: {outcome: Outcome, tooltip: JSX.Element, children?: any} ) {
    const additionalTitleStyle = isSelected(outcome) ? {} : { color: Colors.black };

    return (
      <Card className={cardClass(outcome)} data-testid='outcome-card'>
        <CardActionArea className={classes.actionArea} onClick={() => updateOutcome(outcome)}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title} style={additionalTitleStyle}>
              {outcome.human_name}
            </Typography>
            <Tooltip
              title={tooltip}
              arrow
              classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
            >
              <div style={{height: '80px'}}> {/* To position tooltip below card */}
                {
                  children
                }
              </div>
            </Tooltip>
          </CardContent>
        </CardActionArea>
      </Card>);
  }

  function HistogramCard({outcome}: {outcome: Outcome}) {
    const histogramData = useHistogramData(outcome);

    return (
      <OutcomeCard outcome={outcome} tooltip={histogramDescription(outcome, histogramData)}>
        <OnlyIfDataUpToDate>
          <div className={classes.absoluteCenter}>
            <OutcomeHistogram
              outcome={outcome}
              selected={isSelected(outcome)}
              histogramData={histogramData}
            />
          </div>
          <OutcomeCardValue outcome={outcome}/>
        </OnlyIfDataUpToDate>
    </OutcomeCard>);
  }

  function NPSCard({outcome}: {outcome: Outcome}) {
    const histogramData = useHistogramData(outcome);
    const {detr, prom, all} = npsFromHistogramCounts(
      histogramData,
      outcome.npsCuts!
    );
    const npsScore = 100 * (prom - detr) / all;

    return (
      <OutcomeCard outcome={outcome} tooltip={npsDescription(detr, prom, all)}>
        <OnlyIfDataUpToDate>
          <div className={classes.absoluteCenter}>
            <NPSBar detractors={detr} promoters={prom} all={all}/>
          </div>
          <Typography align='center' className={classes.value}>
            {displayFormat(npsScore)}
          </Typography>
        </OnlyIfDataUpToDate>
      </OutcomeCard>
    );
  }

  function OCard({outcome}: {outcome: Outcome}) {
    switch (outcomeCardType(outcome)) {
      case 'gauge': return <HiScore/>;
      case 'nps': return <NPSCard outcome={outcome}/>;
      case 'histogram': return <HistogramCard outcome={outcome}/>;
    }
  }

  return (
    <WidgetPanel style={{flexGrow: 1, height: '168px'}} title={widgetTitle}>
      <div className={classes.outcomeContainer}>
        <div className={classes.cardsContainer}>
          {
            productOutcomes.map(
              (o: Outcome) => <OCard outcome={o} key={o.id}/>
            )
          }
        </div>
      </div>
    </WidgetPanel>
  );
}
