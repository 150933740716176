import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useProductConfig } from '../store';
import IngredientDistributionChart from './IngredientDistributionChart';

const useStyles = makeStyles((theme: Theme) =>
  ({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: theme.spacing(4)
    },
  }),
);

export default function DistributionsPanel() {
  const classes = useStyles();
  const { charts = [], ingredients } = useProductConfig();

  function findIngredient(column: string) {
    return ingredients.find(i => i.column === column)!;
  }

  const chartsWithIngs = charts.map(ch => {
    const ings = [];
    switch (ch.type) {
      case 'map':
        for (const layer of ch.layers) {
          ings.push(findIngredient(layer.src));
        }
        break;
      default:
        ings.push(findIngredient(ch.src));
    }
    return { chart: ch, ings };
  });

  return (
    <div className={classes.wrapper}>
       {
         chartsWithIngs.map(({ chart, ings }, i) =>
           <IngredientDistributionChart key={i} chart={chart} ingredients={ings}/>
         )
       }
    </div>
  );
}
