import React from 'react';
import { Outcome, PerOutcome, PerIngredient, PerCategory } from '../sharedTypes';
import { ViewMode, SqIngWithCommonData, ImpactBar } from '../clientTypes';
import { getCategoryColors, sortCategoriesByWhatIf } from '../utils';
import { useSelectedOutcome, useFetchEffect } from '../store';
import { Colors } from '../sharedUtils';
import { displayImpact } from '../utils';
import { getBargap } from '../charts/BarChart';
import BarChartWithOuterDashedRectangles,
      { getOuterBarHalfWidth } from '../charts/BarChartWithOuterDashedRectangles';
import { WaitForIt } from '../OnlyIfDataUpToDate';
import { computeWhatIfMagnitudes } from '../addData';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface SortFn {
  (a: ImpactBar, b: ImpactBar): number,
}

interface Props {
  viewMode: ViewMode,
  sortFn: SortFn,
  selectedIngredientData?: SqIngWithCommonData,
  changeSelectedIngId: (ingId: string|undefined) => void,
  bars: ImpactBar[],
  barsAreIngredients: boolean,
  diveIntoGroup: Function,
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    scrollContainer: {
      overflowX: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '450px',
    },
    scrollContent: (props: Props) => ({
      height: '100%',
      width: props.selectedIngredientData !== undefined ? '560px' : props.bars.length * 144
    }),
  }),
);


export default function ImpactChart(props: Props) {
  const { sortFn, selectedIngredientData, changeSelectedIngId,
          bars, barsAreIngredients, diveIntoGroup } = props;

  const ingredientWasSelected = selectedIngredientData !== undefined;
  const classes = useStyles(props);
  return (
    <div className={classes.scrollContainer}>
      <div className={classes.scrollContent}>
        { ingredientWasSelected
          ? <SelectedIngredientWithWhatIfLines viewMode={props.viewMode} selectedIng={selectedIngredientData!} />
          : <IngredientOrGoupImpacts
              viewMode={props.viewMode}
              sortFn={sortFn}
              selectIngredient={(ingId: string) => changeSelectedIngId(ingId)}
              bars={bars}
              barsAreIngredients={barsAreIngredients}
              diveIntoGroup={diveIntoGroup} />
        }
      </div>
    </div>);
}

type WithImpact = ImpactBar | SqIngWithCommonData;

function displayActual(i: WithImpact, relative: boolean, outcome: Outcome, useUnit: boolean) {
  return displayImpact(
    relative ? i.actualOutcomeRatio : i.actualOutcomeDelta, outcome, relative, useUnit);
}

function displayPotential(i: WithImpact, relative: boolean, outcome: Outcome, useUnit: boolean) {
  return displayImpact(
    relative ? i.potentialOutcomeRatio : i.potentialOutcomeDelta, outcome, relative, useUnit);
}

function impactText(i: WithImpact, relative: boolean, outcome: Outcome) {
  const actual = displayActual(i, relative, outcome, true);
  const potential = displayPotential(i, relative, outcome, false);
  const impactUnit = outcome.display && outcome.display.impact_unit;
  const whitespace = impactUnit === undefined ? ' ' : '<br>';
  return `${actual}${whitespace}<i>out of ${potential}</i>`;
}


function IngredientOrGoupImpacts(props: {
    sortFn: SortFn,
    selectIngredient: (ingId: string) => void,
    bars: ImpactBar[],
    barsAreIngredients: boolean,
    diveIntoGroup: Function,
    viewMode: ViewMode,
  }) {

  const { sortFn, selectIngredient, bars, barsAreIngredients, diveIntoGroup } = props;
  const outcome = useSelectedOutcome();
  const relative = props.viewMode === 'relative';
  bars.sort(sortFn);

  const text = bars.map(bar => impactText(bar, relative, outcome));
  const barColor = bars.map(i => barsAreIngredients ? Colors.mediumblue : Colors.darkblue);
  const hovertext = bars.map(i => {
      const l1 = `${barsAreIngredients ? 'Ingredient' : 'Group'}: ${i.human_name}<br>`;
      const l2 = `Actual Impact: ${displayActual(i, relative, outcome, true)}<br>`;
      const l3 = `Potential Impact: ${displayPotential(i, relative, outcome, true)}`;
      return l1 + l2 + l3;
  });

  const layout = {
    bargap: getBargap(bars.length),
  };

  function onSelectBar(selectedBarData: { id: string }) {
    const selectedBar = bars.find(b => b.id === selectedBarData.id)!;
    if (barsAreIngredients) {
      onSelectBarIfBarsAreIngs(selectedBar);
    } else {
      onSelectBarIfBarsAreGroups(selectedBar);
    }
  }

  function onSelectBarIfBarsAreIngs(selectedBar: ImpactBar) {
    selectIngredient(selectedBar.human_name);
  }

  function onSelectBarIfBarsAreGroups(selectedBar: ImpactBar) {
    diveIntoGroup(selectedBar.id);
  }


  return (
    <BarChartWithOuterDashedRectangles
      labels={bars.map(b => b.human_name)}
      innerValues={bars.map(b => b.actualBar)}
      outerValues={bars.map(b => b.potentialBar)}
      layout={layout}
      text={text}
      innerBarColor={barColor}
      hovertext={hovertext}
      barData={bars.map(b => ({ id: b.id }))}
      onSelectBar={onSelectBar}
      wrapAt={16} />
  );
}

function SelectedIngredientWithWhatIfLines(props: { selectedIng: SqIngWithCommonData, viewMode: ViewMode }) {
  const whatIfValues = useFetchEffect('/api/onDemand/whatIfValues');
  return (
    <WaitForIt toBeTrue={whatIfValues !== undefined}>
      <SelectedIngWithWhatIfLinesContent
        selectedIng={props.selectedIng}
        viewMode={props.viewMode}
        whatIfValues={whatIfValues!}
      />
    </WaitForIt>
  );
}

interface ContentProp {
  selectedIng: SqIngWithCommonData,
  viewMode: ViewMode,
  whatIfValues: PerOutcome<PerIngredient<PerCategory<number>>>,
}

function SelectedIngWithWhatIfLinesContent(props: ContentProp) {
  const { selectedIng, viewMode } = props;
  const outcome = useSelectedOutcome();
  const whatIfValues = props.whatIfValues[outcome.id][selectedIng.column];
  const ingWithData = {
    ...selectedIng,
    whatIfValues: whatIfValues,
    whatIfMagnitudes: computeWhatIfMagnitudes(whatIfValues, selectedIng.magnitude),
  };
  const { human_name } = ingWithData;
  const categories = sortCategoriesByWhatIf(ingWithData);
  const relative = viewMode === 'relative';
  const text = impactText(ingWithData, relative, outcome);
  const bargap = getBargap(1);
  const halfWidth = getOuterBarHalfWidth(bargap);

  // The dashed lines represents the impact that we would get if all customers were in the selected
  // category. Added as a shape. The dashed lines should overflow the bar by the lenght of the bar.
  const dashedLineOverflow = 1.5 * (1 - bargap);
  const dashedLinesShapes = categories.map(cat => ({
      type: 'line',
      line: { color: Colors.black, width: 2, dash: 'dot' },
      opacity: 0.7,
      x0: -halfWidth,
      x1: dashedLineOverflow,
      y0: ingWithData.magnitude(ingWithData.whatIfValues[cat.value]),
      y1: ingWithData.magnitude(ingWithData.whatIfValues[cat.value]),
  }));

  // Colored dots at the end of the dashed lines represents which category the dashed line
  // corresponds to.
  // Added as annotation by using a dot headed arrow and making the arrow 0 long without any
  // text - so that only the arrowhead is shown.
  // Using 'ellipse' shape doesn't really work here because the vertical and horizontal dimensions
  // then would be relative to the y and x axes respectively so the horizontal dimension depends
  // on the number of bars while the vertical on the max bar height. This makes it very hard to
  // create spheres using shapes that are always the same size.
  const categoryColors = getCategoryColors(categories.map(c => c.value));
  const dotAnnotations = categories.map(cat => ({
      text: '<br>',
      arrowhead: 6,  // Dot as an arrowhead.
      arrowcolor: categoryColors[cat.value],
      x: dashedLineOverflow,
      y: ingWithData.magnitude(ingWithData.whatIfValues[cat.value]),
      arrowwidth: 4,
      ax: 0,  // The horizontal difference between the 2 end points of the arrow.
      ay: 0,  // The vertical difference between the 2 end points of the arrow.
  }));

  const layout = {
    bargap: bargap,
    shapes: dashedLinesShapes,
    annotations: dotAnnotations,
  };

  return (
    <div style={{height: '100%'}}>
      <BarChartWithOuterDashedRectangles
        labels={[human_name]}
        innerValues={[ingWithData.actualBar]}
        outerValues={[ingWithData.potentialBar]}
        layout={layout}
        text={[text]}
        innerBarColor={Colors.mediumblue}
        wrapAt={16} />
    </div>
  );

}
