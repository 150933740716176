import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useProductConfig, State, Action, ChangeEvent } from '../store';
import { Outcome } from '../sharedTypes';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HelpDialog from '../help/HelpDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: theme.spacing(6),
    },
    titleText: {
      display: 'inline',
      fontWeight: 'bold',
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    sortByText: {
      display: 'inline',
      marginRight: theme.spacing(2),
    },
    alignedSelect: {
      marginTop: '-8px',
    },
    smallText: {
      fontSize: '14px',
    }
  }),
);

interface Props {
  viewMode: string,
  setViewMode: Function,
  sortBy: string,
  changeSortBy: Function,
  sortingOptionNames: { title: string, id: string }[]
}

export default function IngredientsPanelTitle(props: Props) {
  const { sortBy, changeSortBy, sortingOptionNames } = props;
  const classes = useStyles();
  const product = useSelector((state: State) => state.product);
  const selectedOutcome = useSelector((state: State) => (
    state.selectedOutcomesByProduct[product])
  );
  const productOutcomes = useProductConfig().outcomes;
  const dispatch = useDispatch();

  function onOutcomeChange(event: ChangeEvent) {
    const newOutcome = productOutcomes.find((i: Outcome) => i.id === event.target.value);
    dispatch({ type: Action.SetOutcome, payload: newOutcome });
  }

  function onSortByChange(event: ChangeEvent) {
    changeSortBy((event.target.value as string));
  }

  return (
    <>
    <div className={classes.root}>
      <span>
        <TextField
          select
          value={selectedOutcome.id} onChange={onOutcomeChange}
          variant='outlined'
          margin='dense'
          label='Impact on'
          className={classes.alignedSelect}
          SelectProps={{
            IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
          }}
          InputProps={{
            classes: {
              input: classes.smallText
            }
          }}
        >
         {productOutcomes.map((o: Outcome) => (
           <MenuItem key={o.id} value={o.id}>{o.human_name} &nbsp;</MenuItem>)
          )
         }
        </TextField>
      </span>
      <span>
        <TextField
          select
          inputProps={{'data-testid': 'viewmode-dropdown'}}
          value={props.viewMode}
          onChange={e => props.setViewMode(e.target.value)}
          variant='outlined'
          margin='dense'
          label='View'
          className={classes.alignedSelect}
          SelectProps={{
            IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
          }}
          InputProps={{
            classes: {
              input: classes.smallText
            }
          }}
         >
         <MenuItem value='absolute'>Absolute &nbsp;</MenuItem>
         <MenuItem value='relative'>Relative &nbsp;</MenuItem>
        </TextField>
        <HelpDialog>
          <Typography gutterBottom>
            <ul>
              <li>Using the <b>Absolute</b> view mode, the ingredient impact is expressed in the unit of the selected outcome.
              For Churn, it's percentage points (<i>pp</i>). For ARPU, it's the currency of the country. For HI score it's a
              unitless number.</li>
              <li>In <b>Relative</b> view mode the impact is expressed as a percentage. The current and the potential best value
              is compared to the baseline, and the possible improvement is displayed. In case of Churn the improvement is a
              negative value: how much can we decrease the worst churn ratio.</li>
            </ul>
          </Typography>
          <Typography gutterBottom>
            <Typography variant='h6'>Examples</Typography>
            <div>
              Let's say that for ingredient <i>Network latency</i> the worst predicted ARPU is 60 USD, the current value
              is 75 USD and the possible best is 90 USD.
            </div>
            <ul>
              <li>The <b>Absolute</b> view shows <code>"+15 USD ARPU out of +30"</code>, because the current value is 15 USD higher than
              the possible worst, and the best prediction is 30 USD higher.</li>
              <li>The <b>Relative</b> view shows <code>"+25% USD ARPU out of +50%"</code>, since if we increase 60 by 25% we get 75 and
              if we increase it by 50% then we get 90.</li>
            </ul>
          </Typography>
          <Typography gutterBottom>
            <div>
              The visualized bars are always based on the relative impact.
            </div>
          </Typography>
        </HelpDialog>
      </span>
      <span>
        <TextField
          select
          value={sortBy}
          onChange={onSortByChange}
          variant='outlined'
          margin='dense'
          label='Sort by'
          className={classes.alignedSelect}
          SelectProps={{
            IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
          }}
          InputProps={{
            classes: {
              input: classes.smallText
            }
          }}
         >
         {sortingOptionNames.map((o: any) => <MenuItem key={o.id} value={o.id}>{o.title} &nbsp;</MenuItem>)}
        </TextField>
      </span>
    </div>
    </>
  );
}
