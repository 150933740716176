import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { LayoutConstants, lighter, log, segmentSizeStrings, chartColor } from '../utils';
import { Colors, filterKeyToStr } from '../sharedUtils';
import { disabled, MapLayerWithData } from '../clientTypes';
import { Plot } from './Plot';
import { Action } from '../store';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    legendContainer: {
      width: theme.spacing(8),
      fontSize: '10px'
    },
    legendTitle: {
      marginBottom: theme.spacing(2),
    },
    legendDetails: {
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100% - 100px)'
    },
    colorScale: {
      width: theme.spacing(1),
      alignSelf: 'stretch',
      borderRadius: theme.spacing(0.5),
      background: `linear-gradient(${Colors.darkblue},${lighter(Colors.lightblue)})`
    },
    marks: {
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    plotContainer: {
      width: '100%',
      height: '100%',
    },
  }),
);

interface LegendProps {
  minVal: number,
  maxVal: number,
  title: string
}

function MapLegend(props: LegendProps) {
  const classes = useStyles();
  const { minVal, maxVal, title } = props;
  const min = segmentSizeStrings(minVal);
  const max = segmentSizeStrings(maxVal);
  const minMark = `${min.digits} ${min.suffix}`;
  const maxMark = `${max.digits} ${max.suffix}`;

  return (
    <div className={classes.legendContainer}>
      <div data-testid='legendTitle' className={classes.legendTitle}>{title}</div>
      <div className={classes.legendDetails}>
        <div className={classes.colorScale}></div>
        <div className={classes.marks}>
          <div data-testid='legendMax'>{maxMark}</div>
          <div data-testid='legendMin'>{minMark}</div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  layer: MapLayerWithData,
  colorBy: string
}

export default function MapChart(props: Props) {
  const classes = useStyles();
  const { topojson, locations, stringValues, numberValues, hoverText, mode, src: ingredientId } = props.layer;
  const colorBy = props.colorBy;
  const mapbox_token = 'mapbox_token' in props.layer && props.layer.mapbox_token;
  const usedValues = numberValues[colorBy] || stringValues[colorBy] || [];
  const doWeFilter = usedValues.filter(val => val === disabled).length > 0;
  const displayedVals = usedValues.filter(v => v !== disabled) as number[];
  const minVal = Math.min(...displayedVals);
  const maxVal = Math.max(...displayedVals);

  // Hack alert: we want to color the filtered out locations to gray. To achieve this, we set the
  // z value of the filtered out locations to minVal - gapForGray, where minVal is the min z value of
  // the non-filtered out locations and gapForGray is a positive number.
  // Then set the colorscale to start with gray if there are filtered out locations (so that the
  // filtered out locations are colored to gray).
  const gapForGray = 100;
  function getColorScale() {

    function realColorScale({ startFrom }: { startFrom: number }) {
      return [[startFrom, lighter(Colors.lightblue)], [1, Colors.darkblue]];
    }

    if (doWeFilter) {
      // The colorscale is an array containing arrays mapping a normalized value to colors.
      // It is in the form of [[0, lowestValuesColor], ... , [1, highestValuesColor]].
      const rangeOfExtendedClrScale = maxVal - minVal + gapForGray;
      const startRealColorscale = gapForGray / rangeOfExtendedClrScale;
      return [[0, Colors.silver], ...realColorScale({ startFrom: startRealColorscale })];
    } else {
      return realColorScale({ startFrom: 0 });
    }
  }

  const data: any = [{
      cliponaxis: false,
      colorscale: getColorScale(),
      showscale: false,
      locationmode: 'ISO-3',
      z: usedValues.map(val => val === disabled ? minVal - gapForGray : val),
      hoverinfo: 'text',
      text: hoverText,
      textfont: { color: Colors.white, weight: 'bold' },
      textposition: 'middle center',
      }];
  if (mode === 'point') {
    data[0].type = mapbox_token ? 'scattermapbox' : 'scattergeo';
    data[0].marker = { color: Colors.cyclamen };
    if (colorBy in stringValues) {
      const colorMap: { [value: string]: string } = {};
      for (const v of stringValues[colorBy]) {
        if (!(v in colorMap)) {
          colorMap[v] = chartColor(Object.keys(colorMap).length);
        }
      }
      data[0].marker = { color: stringValues[colorBy].map(v => colorMap[v]) };
    }
    if (colorBy in numberValues) {
      data[0].marker = { color: data[0].z };
    }
    data[0].marker.colorscale = data[0].colorscale;
    const points = locations.map(p => JSON.parse(p));
    data[0].lat = points.map(p => p[0]);
    data[0].lon = points.map(p => p[1]);
  } else {
    data[0].type = 'choropleth';
    data[0].marker = { line: { color: 'rgb(255,255,255)', width: 2 } };
    data[0].locations = locations;
  }

  function getPlotlyConfig() {
    if (props.layer.plotly_config !== undefined) {
      return props.layer.plotly_config;
    // Defaults for the built-in maps.
    } else if (topojson.includes('hong-kong')) {
      return (mapbox_token
        ? { center: { lat: 22.3, lon: 114.1 }, zoom: 9 }
        : { lataxis: { range: [22.1, 22.6] }, lonaxis: { range: [113.8, 114.4] } });
    } else if (topojson.includes('china')) {
      return (mapbox_token
        ? { center: { lat: 33, lon: 102 }, zoom: 3 }
        : { lataxis: { range: [10, 56] }, lonaxis: { range: [70, 135] } });
    } else if (topojson.includes('indonesia')) {
      return (mapbox_token
        ? { center: { lat: 2.466, lon: 118 }, zoom: 4 }
        : { lataxis: { range: [-11, 6] }, lonaxis: { range: [95, 141] } });
    } else {
      return {};
    }
  }

  const layout = {
    dragmode: 'pan',
    scrollmode: 'zoom',
    clickmode: 'event',
    font : { size: 10, family: LayoutConstants.roboto, color: Colors.white },
    margin: { l: 0, r: 0, t: 0, b: 0 },
    width: 450 + (mode === 'point' ? 100 : 0),
    height: 450,
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    autosize: true,
    geo: undefined,
    mapbox: undefined,
  };
  if (mapbox_token) {
    layout.mapbox = {
      style: 'light',
      ...getPlotlyConfig(),
    };
  } else {
    layout.geo = {
      scope: 'world',
      bgcolor: 'transparent',
      framewidth: 0,
      showcountries: mode === 'point',
      countrycolor: Colors.mediumblue,
      ...getPlotlyConfig(),
    };
  }

  const config = {
    displayModeBar: mode === 'point',
    displaylogo: false,
    modeBarButtonsToRemove: ['hoverClosestGeo', 'toggleHover'],
    scroolZoom: true,
    topojsonURL: `/topojsons/${topojson}/`,
    mapboxAccessToken: mapbox_token,
  };

  // Plotly caches the topojson in the global `PlotlyGeoAssets` object so we need to reset this
  // cache or otherwise Plotly will try to use the topojson fetched first for all layers.
  // For this to work, the `react-plotly`'s `Plot` component also needs to be recalculated when
  // the layer is changed thus added `key={topojson}` prop to it.
  const PlotlyGeoAssets = (window as any).PlotlyGeoAssets;
  if (typeof PlotlyGeoAssets !== 'undefined') {
    PlotlyGeoAssets.topojson = {};
  }

  const dispatch = useDispatch();
  const filterKey = filterKeyToStr({ type: 'category', ingredientId });
  function onSelectedPoint(e: any) {
    if (mode !== 'point' || e.points.length === 0) {
      return;
    }
    const categories = e.points.map((p: any) => locations[p.pointIndex]);
    log(`Added or updated filter: ${filterKey}: ${categories}`);
    dispatch({
      type: Action.AddOrUpdateFilter,
      payload:  {
        key: filterKey,
        values: categories,
      }
    });
  }

  return (
    <div className={classes.mainContainer}>
      { mode === 'point' || <MapLegend minVal={minVal} maxVal={maxVal} title={colorBy}/> }
      <div className={classes.plotContainer}>
        <Plot key={topojson} data={data} layout={layout} config={config}
          onClick={onSelectedPoint} onSelected={onSelectedPoint}/>
      </div>
    </div>
  );
}
