import React, { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import * as d3 from 'd3';

interface SliderConfig {
  key: string,
  name: string,
  value: number,
  min: number,
  max: number,
  step: number,
  description: string,
  setter: Dispatch<SetStateAction<number>>,
}

function ConfigSlider(props: SliderConfig) {
  return (
    <div style={{ display: 'flex' }}>
      <span style={{ flex: 1 }}>{props.description}</span>
      <Slider style={{ flex: 1 }}
        valueLabelDisplay='on'
        key={props.name}
        value={props.value}
        onChange={(_, v) => props.setter(v as number)}
        min={props.min} max={props.max} step={props.step}
      />
    </div>);
}

function getAudioContext(): AudioContext | undefined {
  const w = window as any;
  const ac = w.AudioContext || w.webkitAudioContext || w.audioContext;
  if (ac) {
    return new ac();
  }
}

export default function FancyLoading(props: {size?: number}) {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const W = props.size || 400;
  const COLOR1 = '#5da2d5'; // Bondi Blue (Medium)
  const COLOR2 = '#ec4e87'; // French Rose
  const [GRAPH, setGraph] = useState(false);
  const sliders: {[name: string]: SliderConfig} = {};
  function withSlider(
    name: string,
    state: [number, Dispatch<SetStateAction<number>>],
    { min, max, step, description }: {
      min: number, max: number, step: number, description: string }): number {
    sliders[name] = {
      value: state[0],
      setter: state[1],
      key: name,
      name, min, max, step, description };
    return state[0];
  }
  const PARTICLE_COUNT = withSlider('PARTICLE_COUNT', useState(100), {
    min: 1, max: 1000, step: 1, description: 'Particle count' });
  const PARTICLE_SIZE = withSlider('PARTICLE_SIZE', useState(15), {
    min: 1, max: 100, step: 1, description: 'Particle size' });
  const GAPS = withSlider('GAPS', useState(3), {
    min: 0, max: 50, step: 0.1, description: 'Gaps' });
  const PATH_LENGTH = withSlider('PATH_LENGTH', useState(3), {
    min: 1, max: 10, step: 1, description: 'Path length' });
  const SPEED_CONTRACTION = withSlider('SPEED_CONTRACTION', useState(0.5), {
    min: 0, max: 2, step: 0.01, description: 'Speed contraction' });
  const ROTATION_SPEED = withSlider('ROTATION_SPEED', useState(1), {
    min: 0, max: 100, step: 1, description: 'Rotation speed' });
  const SEPARATION = withSlider('SEPARATION', useState(0.3), {
    min: 0, max: 1, step: 0.01, description: 'Goal separation' });
  const ATTRACTION = withSlider('ATTRACTION', useState(1), {
    min: 0, max: 10, step: 0.1, description: 'Goal attraction' });
  const COMPRESSION = withSlider('COMPRESSION', useState(1), {
    min: 0, max: 10, step: 0.1, description: 'Axial compression' });
  const VELOCITY_DECAY = withSlider('VELOCITY_DECAY', useState(0.5), {
    min: 0, max: 1, step: 0.01, description: 'Drag' });
  const MOUSE_REPULSION = withSlider('MOUSE_REPULSION', useState(500), {
    min: -1000, max: 1000, step: 1, description: 'Mouse repulsion' });
  const MOUSE_BOUNCE = withSlider('MOUSE_BOUNCE', useState(200), {
    min: 0, max: 500, step: 1, description: 'Bounce on hit' });
  const SCORE_PER_HIT = withSlider('SCORE_PER_HIT', useState(100), {
    min: 0, max: 1000, step: 1, description: 'Score per hit' });
  const SOUND_RAMP = withSlider('SOUND_RAMP', useState(0.01), {
    min: 0, max: 0.5, step: 0.01, description: 'Sound attack' });
  const SOUND_DURATION = withSlider('SOUND_DURATION', useState(0.05), {
    min: 0, max: 2, step: 0.01, description: 'Sound sustain' });
  const SOUND_BASE_FREQ = withSlider('SOUND_BASE_FREQ', useState(400), {
    min: 100, max: 2000, step: 1, description: 'Sound base frequency' });
  const SOUND_FREQ1 = withSlider('SOUND_FREQ1', useState(800), {
    min: 100, max: 2000, step: 1, description: 'Sound frequency 1' });
  const SOUND_FREQ2 = withSlider('SOUND_FREQ2', useState(600), {
    min: 100, max: 2000, step: 1, description: 'Sound base frequency' });

  useEffect(() => {
    const canvas = ref.current as any;
    if (!canvas) {
      return;
    }
    const audio: { ctx?: AudioContext, gain?: GainNode, oscillator?: OscillatorNode } = {
      ctx: getAudioContext(),
    };
    if (audio.ctx) {
      audio.gain = audio.ctx.createGain();
      audio.oscillator = audio.ctx.createOscillator();
      audio.oscillator.connect(audio.gain);
      audio.gain.gain.setValueAtTime(0, 0);
      audio.gain.connect(audio.ctx.destination);
      audio.oscillator.start();
    }
    function beep(freq: number) {
      if (!audio.ctx) {
        return;
      }
      audio.ctx.resume(); // Can only be resumed after first user interaction.
      const r = SOUND_RAMP;
      const d = SOUND_DURATION;
      const s = audio.ctx.currentTime;
      const g = audio.gain!.gain;
      const f = audio.oscillator!.frequency;
      // Sudden changes in the waveform can be heard as clicks.
      // To avoid that, we add a brief ramping for all changes.
      g.cancelScheduledValues(s);
      g.setValueAtTime(g.value, s);
      g.linearRampToValueAtTime(1, s + r);
      g.setValueAtTime(1, s + r + d + r + d);
      g.linearRampToValueAtTime(0, s + r + d + r + d + r);
      f.cancelScheduledValues(s);
      f.setValueAtTime(f.value, s);
      f.linearRampToValueAtTime(SOUND_BASE_FREQ, s + r);
      f.setValueAtTime(SOUND_BASE_FREQ, s + r + d);
      f.linearRampToValueAtTime(freq, s + r + d + r);
    }

    // Initial setup with 50% in each team.
    const nodes: Array<any> = Array.from({length: PARTICLE_COUNT}, (_, i) => ({
      i, r: PARTICLE_SIZE * 0.001 * W + 0.005 * W * Math.sin(i), temp: 0, team: i % 2 ? 1 : -1, past: []}));
    const color = d3.piecewise([COLOR1, 'transparent', COLOR2]);

    let ts = 0;
    const score = [0, 0];
    function ticked() {
      ts += ROTATION_SPEED;
      const tx = Math.sin(0.001 * ts);
      const ty = Math.cos(0.001 * ts);
      const ctx = canvas.getContext('2d');
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.lineWidth = 2;
      ctx.clearRect(0, 0, W, W);
      ctx.save();
      ctx.translate(W / 2, W / 2);
      if (GRAPH) {
        // Connect each team with a graph.
        for (const {color, sign} of [{color: COLOR1, sign: -1}, {color: COLOR2, sign: 1}]) {
          ctx.strokeStyle = color;
          ctx.beginPath();
          const d = d3.Delaunay.from(nodes.filter(p => sign * p.temp > 20), p => p.x, p => p.y);
          for (let i = 0, n = d.halfedges.length; i < n; ++i) {
            const j = d.halfedges[i];
            if (j < i) continue;
            const ti = d.triangles[i];
            const tj = d.triangles[j];
            const [x1, y1] = [d.points[ti * 2], d.points[ti * 2 + 1]];
            const [x2, y2] = [d.points[tj * 2], d.points[tj * 2 + 1]];
            if (Math.hypot(x1 - x2, y1 - y2) < W / 20) {
              ctx.moveTo(x1, y1);
              ctx.lineTo(x2, y2);
            }
          }
          ctx.stroke();
        }
      }
      for (const d of nodes) {
        // Temperature for gradual color change.
        d.temp += d.team;
        d.temp *= 0.99;
        const dot = d.x * tx + d.y * ty;
        // When they reach the target zone they change teams.
        if (d.team === 1 && dot > SEPARATION * W) {
          d.team = -1;
        } else if (d.team === -1 && dot < -SEPARATION * W) {
          d.team = 1;
        }
        // We compute the path length to keep the filled area roughly constant.
        let length = 0;
        let {x, y} = d;
        for (const p of d.past) {
          length += SPEED_CONTRACTION * Math.hypot(p.x - x, p.y - y);
          ({x, y} = p);
        }
        // Draw a "circle". (Elongated when moving fast.)
        ctx.strokeStyle = color(0.5 + Math.atan(0.1 * d.temp) / Math.PI);
        ctx.lineWidth = d.r * 2 / (length / d.r + 1);
        ctx.beginPath();
        ctx.moveTo(d.x, d.y);
        for (const p of d.past) {
          ctx.lineTo(p.x, p.y);
        }
        ctx.stroke();
        // Update path.
        d.past.unshift({x: d.x, y: d.y});
        if (d.past.length > PATH_LENGTH) {
          d.past.pop();
        }
      }
      ctx.restore();
      ctx.font = 'bold 24px sans-serif';
      if (score[0] > 0 || score[1] > 0) {
        ctx.fillStyle = COLOR1;
        ctx.fillText(score[0].toString(), 20, 20);
        ctx.fillStyle = COLOR2;
        ctx.fillText(score[1].toString(), W - 20 - ctx.measureText(score[1].toString()).width, 20);
      }
    }

    function forces() {
      const tx = Math.sin(0.001 * ts);
      const ty = Math.cos(0.001 * ts);
      for (const d of nodes) {
        // Compression to the centerline to give hourglass shape.
        const dot = d.x * ty - d.y * tx;
        const shape = 0.01 * COMPRESSION / (Math.abs(Math.atan(Math.hypot(d.x, d.y) / W)) + 0.1);
        d.vx -= shape * dot * ty;
        d.vy += shape * dot * tx;
        // Avoid the mouse.
        const [mx, my] = [mouseX - d.x - W / 2, mouseY - d.y - W / 2];
        const md = Math.hypot(mx, my);
        const md2 = md * md + W * W / 100;
        d.vx -= MOUSE_REPULSION * mx / md2;
        d.vy -= MOUSE_REPULSION * my / md2;
        // Attraction to the poles to separate the teams.
        const A = 0.01 * ATTRACTION * W;
        if (d.team > 0 && d.temp > 0) {
          d.vx += A * tx;
          d.vy += A * ty;
          if (md < d.r) { // Caught it!
            d.team *= -1;
            d.vx -= MOUSE_BOUNCE * mx / md; d.vy -= MOUSE_BOUNCE * my / md;
            score[1] += SCORE_PER_HIT;
            beep(SOUND_FREQ1);
          }
        } else if (d.team < 0 && d.temp < 0) {
          d.vx -= A * tx;
          d.vy -= A * ty;
          if (md < d.r) { // Caught it!
            d.team *= -1;
            d.vx -= MOUSE_BOUNCE * mx / md; d.vy -= MOUSE_BOUNCE * my / md;
            score[0] += SCORE_PER_HIT;
            beep(SOUND_FREQ2);
          }
        }
      }
    }

    const collide = d3.forceCollide().iterations(3).radius(d => (d as any).r + 0.001 * GAPS * W);
    const simulation = d3.forceSimulation(nodes)
        .alphaTarget(0.3)   // Keep the simulation running instead of stabilizing it.
        .velocityDecay(VELOCITY_DECAY) // Let them drift.
        .force('forces', forces)
        .force('collide', collide)
        .on('tick', ticked);
    let [mouseX, mouseY] = [999999, 999999];
    function pointed(event: any) {
      [mouseX, mouseY] = d3.pointer(event);
    }
    d3.select(canvas)
      .on('touchmove', event => event.preventDefault())
      .on('pointermove', pointed);
    // Cleanup code when the component is destroyed.
    return () => {
      simulation.stop();
      if (audio.ctx) {
        audio.ctx.close();
      }
    };
  }, [
    ref, W, COLOR1, COLOR2, GRAPH, PARTICLE_COUNT, PARTICLE_SIZE, PATH_LENGTH,
    ROTATION_SPEED, SEPARATION, COMPRESSION, ATTRACTION, SPEED_CONTRACTION,
    GAPS, VELOCITY_DECAY, MOUSE_REPULSION, MOUSE_BOUNCE, SCORE_PER_HIT,
    SOUND_RAMP, SOUND_DURATION, SOUND_BASE_FREQ, SOUND_FREQ1, SOUND_FREQ2]);

  return (
    <div style={{ width: W }}>
      <canvas ref={ref} width={W} height={W} />
      { showOptions ? <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }}>Show graph</span>
          <Checkbox style={{ flex: 1 }}
            checked={GRAPH}
            onChange={(_, v) => setGraph(v)}
          />
        </div>
        <ConfigSlider {...sliders.PARTICLE_COUNT} />
        <ConfigSlider {...sliders.PARTICLE_SIZE} />
        <ConfigSlider {...sliders.GAPS} />
        <ConfigSlider {...sliders.PATH_LENGTH} />
        <ConfigSlider {...sliders.SPEED_CONTRACTION} />
        <ConfigSlider {...sliders.ROTATION_SPEED} />
        <ConfigSlider {...sliders.SEPARATION} />
        <ConfigSlider {...sliders.ATTRACTION} />
        <ConfigSlider {...sliders.COMPRESSION} />
        <ConfigSlider {...sliders.VELOCITY_DECAY} />
        <ConfigSlider {...sliders.MOUSE_REPULSION} />
        <ConfigSlider {...sliders.MOUSE_BOUNCE} />
        <ConfigSlider {...sliders.SCORE_PER_HIT} />
        <ConfigSlider {...sliders.SOUND_DURATION} />
        <ConfigSlider {...sliders.SOUND_RAMP} />
        <ConfigSlider {...sliders.SOUND_BASE_FREQ} />
        <ConfigSlider {...sliders.SOUND_FREQ1} />
        <ConfigSlider {...sliders.SOUND_FREQ2} />
        <p>
          Results loading slowly? Report issues at <a
            href='mailto:support@lynxanalytics.com'>support@lynxanalytics.com</a>.
        </p>
      </> : <Button onClick={() => setShowOptions(true)}>...</Button> }
    </div>
  );
}
