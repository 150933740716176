import React, { useState }  from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { StateWith } from '../store';
import { Ingredient, PerCategory, SimulatorData } from '../sharedTypes';
import { isDiffSmall, LayoutConstants } from '../utils';
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../sharedUtils';
import { backwardSimulation } from './simulation';
import { SimulatedBar } from './SimulatedBar';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: (props: any) => ({
      cursor: props.role === 'simulator' ? 'default' : 'pointer',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: LayoutConstants.lightBlueBackgroundColor
      }
    }),
    barContainer: (props: any) => ({
      borderRadius: '5px',
      marginRight: '4px',
      marginLeft: '4px',
      backgroundColor: props.selected ? LayoutConstants.lightBlueBackgroundColor : 'white'
    }),
    barWrapper: (props: any) => ({
      display: 'flex',
      justifyContent: 'center',
      height: `${props.height + 10}px`,
      paddingTop: '8px'
    }),
    ingNameBox: (props: any) => ({
      textAlign: 'center',
      paddingLeft: '2px',
      paddingRight: '2px',
      paddingTop: '16px',
      paddingBottom: '4px',
      width: `${props.width + 16}px`,
      flex: `0 0 ${props.width + 16}px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '8px',
      whiteSpace: 'nowrap'
    }),
    legendItemBox: (props: any) => ({
      border: props.impactType === 'Potential' ? 'dashed 1px' : 'solid 1px invisible',
      backgroundColor: props.impactType === 'Potential'
        ? 'white'
        : props.impactType === 'Actual' ? Colors.mediumblue : Colors.darkcyclamen,
      width: '25px',
      height: '25px',
      marginRight: '8px'
    })
  }),
);


// To leave some space above the bar.
// Also used for label adjustment, so it needs to be around the height of a character.
const dY = 10;
const handleColor = '#585858';


interface ImpactVisualizationProps {
  width: number
  height: number
  role: 'selector' | 'simulator'
  sqIng: Ingredient
  selected?: boolean,
  onSelect: () => void
  onDrag: (newDistr: { [cat: string]: number }) => void
  actualMagnitude: number
  potentialMagnitude: number
  simulatedMagnitude: number
  maxPotentialMagnitude: number
  magnitudeToOutcome: (magnitude: number) => number
  whatIfMagnitudes: PerCategory<number>
  categoryColors?: { [category: string]: string }
}

type LocalState = StateWith<SimulatorData>;

export function ImpactVisualization( props: ImpactVisualizationProps) {
  const {
    width, height, role, sqIng, onSelect, onDrag,
    actualMagnitude, potentialMagnitude, simulatedMagnitude,
    maxPotentialMagnitude, whatIfMagnitudes, magnitudeToOutcome,
    categoryColors
  } = props;
  const selected = props.selected || false;
  const { originalDistributions, simulationData } = useSelector(
    (state: LocalState) => {
      const selectedOutcome = state.selectedOutcomesByProduct[state.product];
      const simulationData = state.data.simulation[selectedOutcome.id];
      const simulatorState = state.simulatorStateByProduct[state.product];
      return {
        originalDistributions: simulatorState.originalDistributions,
        simulationData,
      };
    }
  );

  const classes = useStyles({selected, width, height, role});

  function scaleMagnitude(val: number) {
    return val / maxPotentialMagnitude;
  }

  const diffIsVisible = !isDiffSmall(actualMagnitude, simulatedMagnitude);

  const scaledActual = scaleMagnitude(actualMagnitude);
  const scaledPotential = scaleMagnitude(potentialMagnitude);
  const scaledSimulated = scaleMagnitude(simulatedMagnitude);

  function toSVGCoord(val: number) {
    return height * (1 - val);
  }

  function fromSVGCoordToOutcome(svgY: number) {
    const scaled = 1 - svgY / height;
    const magnitude = scaled * maxPotentialMagnitude;
    return magnitudeToOutcome(magnitude);
  }

  const svgYActual = toSVGCoord(scaledActual);
  let svgYActualText = svgYActual + dY / 2;
  const svgYPotential = toSVGCoord(scaledPotential);
  const svgYPotentialText = svgYPotential + dY / 2;
  const svgYSimulated = toSVGCoord(scaledSimulated);
  const [ handle, setHandle ] = useState({
    dragActive: false,
    offsetY: 0
  });
  let svgYSimulatedText = svgYSimulated + dY / 2;

  const sideWidth = (600 - width) / 2;

  // Adjust overlapping labels
  if (svgYActualText < svgYSimulatedText) {
    let d = svgYActualText - svgYPotentialText;
    d = Math.max(d, 2 * dY);
    svgYActualText = svgYPotentialText + d;
    svgYSimulatedText = Math.max(svgYSimulatedText, svgYActualText + 2 * dY);
    const extra = Math.max(0, svgYSimulatedText - height - dY);
    svgYSimulatedText -= extra;
    svgYActualText = Math.min(svgYActualText, svgYSimulatedText - 2 * dY);
  } else {
    let d = svgYSimulatedText - svgYPotentialText;
    d = Math.max(d, 2 * dY);
    svgYSimulatedText = svgYPotentialText + d;
    svgYActualText = Math.max(svgYActualText, svgYSimulatedText + 2 * dY);
    const extra = Math.max(0, svgYActualText - height - dY);
    svgYActualText -= extra;
    svgYSimulatedText = Math.min(svgYSimulatedText, svgYActualText - 2 * dY);
  }

  function handlePointerDown(e: any) {
    const el = e.target;
    const bbox = e.target.getBoundingClientRect();
    const y = e.clientY - bbox.top;
    el.setPointerCapture(e.pointerId);
    setHandle({
      ...handle,
      dragActive: true,
      offsetY: y
    });
  }

  function handlePointerUp(e: any) {
    setHandle({
      ...handle,
      dragActive: false
    });
  }

  function updateDistribution(newSVGY: number) {
    const simulatedValue = fromSVGCoordToOutcome(newSVGY);
    const newDistribution = backwardSimulation(
      simulationData[sqIng.column], originalDistributions[sqIng.column], simulatedValue);
    onDrag(newDistribution);
  }

  function handlePointerMove(e: any) {
    if (handle.dragActive) {
      const bbox = e.target.getBoundingClientRect();
      const y = e.clientY - bbox.top;
      const newY = svgYSimulated - (handle.offsetY - y);
      const newSVGY = Math.min(Math.max(newY, svgYPotential), height);
      updateDistribution(newSVGY);
    }
  }

  return (
    <div className={classes.barContainer}>
      <div className={classes.actionArea} onClick={onSelect}>
        <div
          className={classes.barWrapper}
          data-testid={role === 'selector' ? `sim-select-${sqIng.column}` : `selected-${sqIng.column}`}>
          {role === 'simulator' && scaledPotential > 0 &&
            <svg width={sideWidth} height={height + 2 * dY}>
              {/* Horizontal lines */}
              <line x1={120} y1={svgYPotential + dY} x2={250} y2={svgYPotential + dY} style={{stroke: Colors.lightblack}}/>
              <line x1={120} y1={svgYSimulated + dY} x2={250} y2={svgYSimulated + dY} style={{stroke: Colors.darkcyclamen}}/>
              <line x1={120} y1={svgYActual + dY} x2={250} y2={svgYActual + dY} style={{stroke: Colors.mediumblue}}/>
              {/* Labels */}
              <text x={5} y={svgYPotentialText + dY} fill='black'>Potential impact</text>
              <text x={5} y={svgYSimulatedText + dY} fill={Colors.darkcyclamen}>Simulated impact</text>
              <text x={5} y={svgYActualText + dY} fill={Colors.mediumblue}>Actual impact</text>
              {/* Connectors */}
              <line x1={110} y1={svgYPotentialText + dY / 2} x2={120} y2={svgYPotentialText + dY / 2} style={{stroke: Colors.lightblack}}/>
              <line x1={120} y1={svgYPotentialText + dY / 2} x2={120} y2={svgYPotential + dY} style={{stroke: Colors.lightblack}}/>

              <line x1={110} y1={svgYActualText + dY / 2} x2={120} y2={svgYActualText + dY / 2} style={{stroke: Colors.mediumblue}}/>
              <line x1={120} y1={svgYActualText + dY / 2} x2={120} y2={svgYActual + dY} style={{stroke: Colors.mediumblue}}/>

              <line x1={110} y1={svgYSimulatedText + dY / 2} x2={120} y2={svgYSimulatedText + dY / 2} style={{stroke: Colors.darkcyclamen}}/>
              <line x1={120} y1={svgYSimulatedText + dY / 2} x2={120} y2={svgYSimulated + dY} style={{stroke: Colors.darkcyclamen}}/>
            </svg>
          }
          <svg width={width} height={height + 2 * dY}>
            <SimulatedBar
              width={width}
              height={height}
              dY={dY}
              scaledPotential={scaledPotential}
              scaledSimulated={scaledSimulated}
              scaledActual={scaledActual}
              svgYPotential={svgYPotential}
              svgYSimulated={svgYSimulated}
              svgYActual={svgYActual}
              diffIsVisible={diffIsVisible}
              fillColor={Colors.mediumblue}
            />
            {/* Handle to drag */}
            { potentialMagnitude > 0 &&
              <rect
                x={5} y={svgYSimulated - dY / 2 + dY}
                width={width - 10} height={dY} rx={dY / 2}
                style={{fill: handleColor, cursor: 'grab'}}
                onPointerDown={handlePointerDown}
                onPointerUp={handlePointerUp}
                onPointerMove={handlePointerMove}
              />
            }
          </svg>
          {role === 'simulator' && scaledPotential > 0 &&
            <svg width={sideWidth} height={height + 2 * dY}>
              {Object.keys(whatIfMagnitudes).map( (key: string) => (
                <g key={key}>
                  <line
                    x1={0}
                    y1={toSVGCoord(scaleMagnitude(whatIfMagnitudes[key])) + dY}
                    x2={150}
                    y2={toSVGCoord(scaleMagnitude(whatIfMagnitudes[key])) + dY}
                    style={{stroke: Colors.lightblack, strokeDasharray: '8 4'}}
                  />
                  <circle
                    cx={150}
                    cy={toSVGCoord(scaleMagnitude(whatIfMagnitudes[key])) + dY}
                    r={dY}
                    fill={categoryColors![key]}/>
                </g>
              ))}
            </svg>
          }
        </div>
        { role === 'selector' &&
          <Tooltip title={sqIng.human_name} placement='bottom'>
            <div className={classes.ingNameBox}>
              {sqIng.human_name}
            </div>
          </Tooltip>
        }
      </div>
    </div>
  );
}
