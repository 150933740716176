import React, { useState, useEffect } from 'react';
import { Plot } from './Plot';
import { useDisplaySettings } from '../store';
import { baseLayout, baseConfig, wrap, Axis,
         mergeWhileConcatenatingArrays, LayoutConstants, displayFormat } from '../utils';
import { Colors } from '../sharedUtils';
import { Outcome } from '../sharedTypes';
import { DisabledOrNumber, disabled } from '../clientTypes';

// The value of the bargap is the fraction of the chart width,
// which is not occupied by bars. If there are just a few bar then
// the same bargap that looks good for more bars can look awkward.
export function getBargap(numBars: number) {
  switch (numBars) {
    case 1:
      return 0.8;
    case 2:
      return 0.6;
    default:
      return 0.5;
  }
}

interface Props {
  labels: string[],
  // If you don't want to display a value for a label but only show that the label also exists
  // (e.g. the ingredient category has been filtered out) then set the value to 'disabled'.
  values: DisabledOrNumber[],
  layout?: any,
  // The value displayed above the bars if the bar chart is vertical or next to them if horizontal.
  // If not set then nothing will be displayed.
  text?: string[],
  vertical?: boolean,  // True by default.
  // If the following 2 properties are both set then the chart also displays the average outcome per
  // category as a line.
  outcome?: Outcome,
  outcomeValues?: DisabledOrNumber[],
  barColor?: string | string[],
  hovertext?: string | string[],
  // You can pass extra data for each bar using `barData`. The `onSelectBar` will be called with
  // barData element corresponding to the selected bar.
  barData?: any[],
  onSelectBar?: (selectedBarData: any) => void,
  wrapAt?: number,  // If set then the bar names should be wrapped at this number of characters.
  baseBottomMargin?: number,  // Extra space under the vertical bars for wrapped labels.
}

export default function BarChart(props: Props) {
  // In the react-plotly version we currently use, <Plot /> has a bug that it doesn't update
  // onClick by default (see https://github.com/biggraph/biggraph/pull/8625#issuecomment-525390615)
  // so we need to use a different key each time props.onSelectBar is changed to trigger the call to
  // its constructor again.
  // The fix has been merged: https://github.com/plotly/react-plotly.js/pull/151.
  // TODO: update react-plotly when the fix has been released and delete this key hack.
  const [ key, setKey ] = useState(new Date().getTime());
  useEffect(() => setKey(new Date().getTime()), [props.onSelectBar]);

  const { labels, values, text, layout, barData, onSelectBar, wrapAt,
    outcome, outcomeValues, baseBottomMargin } = props;
  const vertical = props.vertical === undefined ? true : props.vertical;

  // The various color attributes (labelcolor, textColor, barcolor) are set to gray for the
  // disabled labels.
  function colorDisabledsToGray(color: string | string[]) {
    const colorList = typeof(color) === 'string' ? labels.map(l => color) : color;
    return colorList.map((col, id) => values[id] === disabled ? Colors.gray : col);
  }

  const labelColor = colorDisabledsToGray(Colors.black);
  const textColor = colorDisabledsToGray(Colors.black);
  const barColor = colorDisabledsToGray(props.barColor || Colors.darkblue);
  const customers = useDisplaySettings().entities;

  const hovertext = props.hovertext || '';

  const displayedLabels = labels.map(l => wrapAt ? wrap(l, wrapAt) : l);
  const [labelAxisShort, dataAxisShort] = vertical ? ['x', 'y'] : ['y', 'x'];
  const labelAxis = (`${labelAxisShort}axis` as Axis);

  // Adjust the distance between the plot and the labels.
  const plotLabelDistance = 5;

  // Using the "default" plotly way to display the labels only allows to set the colors of
  // the labels together and not one by one.
  // To allow setting the label colors separately, we display the labels as annotations instead.
  const labelAnnotations = labels.map((label, id) => (
    {
      showarrow: false,
      [labelAxisShort]: id,
      [dataAxisShort]: 0,
      [`${dataAxisShort}ref`]: 'paper',
      [`${dataAxisShort}anchor`]: dataAxisShort === 'x' ? 'right' : 'top',
      [`${dataAxisShort}shift`]: -plotLabelDistance,
      text: wrapAt ? wrap(label, wrapAt) : label,
      font: { color: labelColor[id], family: LayoutConstants.roboto},
    })
  );
  const baseBarChartLayout = {
    bargap: getBargap(labels.length),
    margin: {
      l: vertical ? 40 : 100,
      r: vertical ? 40 : 40,
      t: vertical ? 50 : 10,
      b: vertical ? 50 : 10,
      pad: 5,
    },
    [labelAxis]: {
      // Don't show the labels because we do this with labelAnnotations.
      visible: false,
      // For horizontal bar charts Plotly by default displays the first bar at the bottom and the
      // last one at the top (so the order of the bars is from down to top). The other direction
      // is more natural for us, so we change it by setting autorange to 'reversed' in this case.
       autorange: vertical ? true : 'reversed',
    },
    annotations: labelAnnotations,
  };

  const hideOutcomesPerCategories = outcome === undefined || outcomeValues === undefined;
  let layoutWithDefaults;
  if (hideOutcomesPerCategories) {
    layoutWithDefaults = mergeWhileConcatenatingArrays(
      [baseLayout(labelAxis), baseBarChartLayout, layout]
    );
  } else {
    const combinedVerticalBarChartLayout =  {
      showlegend: true,
      legend: {
        x: 0.5,
        y: 1.1,
        orientation: 'h'
      },
      yaxis: {
        title: {
          text: 'number of ' + customers,
          font: {
            family: 'roboto',
          },
        },
        showticklabels: true,
        autorange: true,
        fixedrange: true,
        showgrid: false,
      },
      yaxis2: {
        title: {
          text: `average ${outcome!.human_name}`,
          font: {
            family: 'roboto',
            color: Colors.cyclamen
          },
        },
        overlaying: 'y',
        side: 'right',
        showticklabels: true,
        tickfont: { color: Colors.cyclamen },
        autorange: true,
        fixedrange: true,
        showgrid: false,
        zeroline: false,
      }
    };

    layoutWithDefaults = mergeWhileConcatenatingArrays(
      [baseLayout(labelAxis), baseBarChartLayout, layout, combinedVerticalBarChartLayout]
    );
  }

  const config = baseConfig();
  const countData = {
    [labelAxisShort]: displayedLabels,
      [dataAxisShort]: values,
      text: text,
      customdata: barData,
      textposition: text ? 'outside' : 'none',
      textfont: { color: textColor },
      orientation: vertical ? 'v' : 'h',
      marker: {
        color: barColor,
      },
      type: 'bar',
      hoverinfo: hovertext !== ''  ? 'text' : 'none',
      hovertext: hovertext,
      cliponaxis: false,
      name: 'number of ' + customers,
  };
  const outcomeData = hideOutcomesPerCategories ? undefined : {
    x: displayedLabels,
    y: outcomeValues,
    yaxis: 'y2',
    type: 'scatter',
    showgrid: false,
    marker: {
      color: Colors.cyclamen
    },
    hoverinfo: 'text',
    hovertext: outcomeValues!.map(
      (v: DisabledOrNumber) => v === 'disabled' ? 'disabled' : displayFormat(v, outcome)),
    name: `average ${outcome!.human_name}`
  };
  const data = outcomeData === undefined ? [ countData ] : [ countData, outcomeData ];

  function onClick(clickData: any) {
    if (onSelectBar) {
      const selectedBarData = clickData.points[0].customdata;
      onSelectBar(selectedBarData);
    }
  }

  const bottomMargin = baseBottomMargin || 70;
  const verticalShiftForLabels = vertical ? plotLabelDistance : 0;
  const bottomMarginForPlots = bottomMargin - verticalShiftForLabels;

  return (
    <Plot
      key={key}
      data={data}
      layout={layoutWithDefaults}
      config={config}
      style={{width: '100%', height: `calc(100% - ${bottomMarginForPlots}px`}}
      useResizeHandler={true}
      onClick={onClick}/>
  );
}
