import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useDisplaySettings, useAudienceCount, useNumSqIngs } from '../store';
import FilterPanel from '../filters/FilterPanel';
import GoToFilterPanelButton from '../GoToFilterPanelButton';
import SimulatorOutcomeSelector from './SimulatorOutcomeSelector';
import WidgetPanel from '../WidgetPanel';
import SimulatorIngredientsPanel from './SimulatorIngredientsPanel';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2.5),
      width: '1240px',
      marginLeft: theme.spacing(18),
    },
    pageTitle: {
      paddingLeft: theme.spacing(3),
      fontSize: '24px',
    },
    simulatorContainer: {
      paddingLeft: theme.spacing(2),
    },
    anchor: {
      height: 0,
    }
  }),
);

export default function SimulatorPage() {
  const classes = useStyles();
  const customers = useDisplaySettings().entities;
  const audienceCount = useAudienceCount();
  const numSqIngs = useNumSqIngs();

  return (
    <div className={classes.root}>
      <h1 className={classes.pageTitle}>Simulator</h1>
      <div id='filter-panel' className={classes.anchor}></div>
      <FilterPanel/>
      <OnlyIfDataUpToDate>
        {audienceCount > 0 ?
          <div className={classes.simulatorContainer}>
            <SimulatorOutcomeSelector/>
            { numSqIngs > 0
              ? <SimulatorIngredientsPanel />
              : <WidgetPanel style={{ marginLeft: '-4px', width: '1192px', height: '48px' }}>
                <div style={{padding: 16}}>
                  There are no ingredients to simulate on.
                </div>
                </WidgetPanel>
            }
          </div>
        :
          <WidgetPanel style={{ marginLeft: '16px', marginBottom: '32px', width: '1184px', height: '200px'}} title='Empty segment!'>
          <div>
            There are no {customers} in the segment you have created.
            Try to modify or remove some filters.
            You can also use the "Clear all filters" button to start again.
          </div>
        </WidgetPanel>
      }
      </OnlyIfDataUpToDate>
      <GoToFilterPanelButton/>
    </div>
  );
}
