// Common style used in dialogs.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from './sharedUtils';

export default makeStyles((theme: Theme) =>
createStyles({
  decoration: {
    backgroundColor: Colors.lightblue,
    width: '80px',
    margin: '0 auto',
    marginBottom: '10px',
    height: '3px',
    padding: '0px',
  },
  dialogTitle: {
    paddingBottom: '5px',
    textAlign: 'center',
    minWidth: '340px'
  },
  dialogContent: {
    maxHeight: '80vh'
  },
}),
);
