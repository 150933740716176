import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import { Action, State, useDisplaySettings } from '../store';
import { filterKeyToStr } from '../sharedUtils';
import { log, displayFormat } from '../utils';


const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: theme.spacing(3),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
}));

export default function ListFilterValueSelector(
  {
    closeEditor,
    listname,
    goBackToKeySelection,
  }: {
    closeEditor: () => void,
    listname: string,
    goBackToKeySelection: () => void,
  }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [listUsage, setListUsage] = useState<'include'|'exclude'>('include');
  const list = useSelector((state: State) => state.savedListsByProduct[state.product][listname]);
  const display = useDisplaySettings();
  const filterKey = filterKeyToStr({ type: 'list', listname });

  function onChange(event: any) {
    setListUsage(event.target.value);
  }

  function updateFilters() {
    log(
      `Added or updated filter: ${listUsage} ${display.entities} from list: ${listname}.`);
    dispatch({
      type: Action.AddOrUpdateFilter,
      payload:  {
        key: filterKey,
        values: { listUsage },
      }
    });
    closeEditor();
  }
  const Entities = display.entities.slice(0, 1).toUpperCase() + display.entities.slice(1);

  return (
    <>
      <h2> { list.humanName } </h2>
      <div className={classes.description}>
        You can either include or exclude the {displayFormat(list.numRows)} {display.entities} {' '}
        on this list. {Entities} not present in the current data are ignored.
      </div>
      <div>
        <RadioGroup aria-label='include-exclude' value={listUsage} onChange={onChange} >
          <FormControlLabel value='include' control={<Radio />} label='Include' />
          <FormControlLabel value='exclude' control={<Radio />} label='Exclude' />
        </RadioGroup>
      </div>
      <div className={classes.buttons}>
        <Button onClick={goBackToKeySelection}>Cancel</Button>
        <Button
          onClick={updateFilters}
          color='secondary'
          variant='contained'
          style={{marginLeft: '1em'}}
          >Apply
        </Button>
      </div>
    </>);
}

