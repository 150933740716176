/**
 *  This component is meant be used when we need to select,
 *  rename, download or delete a saved list.
 *
 *  Examples:
 *    - in SavedListDialog where we can manage lists
 *    - in the filter editor to add a list-based filter
 *    - with the lookalike feature to pick a list to be used
 *      by the lookalike audience builder
 */
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../sharedUtils';
import { entitiesLabel, log, protectedJsonFetch } from '../utils';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Divider from '@material-ui/core/Divider';
import {
  Action, ListMetadata, setError, State,
  useDisplaySettings, useExportAllowed } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import download from 'downloadjs';
import ConfirmDialog from '../ConfirmDialog';
import { DisplaySettings } from '../sharedTypes';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  button: {
    marginLeft: theme.spacing(2),
  },
  main: {

  },
  explanation: {
    color: Colors.lightblack,
    padding: theme.spacing(2),
  },
  listItem: {
    height: theme.spacing(10),
  }
}),
);


interface Props {
  savedLists: { [name: string]: ListMetadata }
  explanation?: string
  mode: 'selector' | 'editor'
  onSelect?: (name: string) => void
  selected?: string,
  disabled?: boolean
}

export default function SavedListsBrowser( props: Props) {
  const { savedLists, explanation, mode, onSelect, disabled } = props;
  const selected = props.selected || '';
  const classes = useStyles();
  const dispatch = useDispatch();
  const display = useDisplaySettings();
  const exportAllowed = useExportAllowed();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedList, setSelectedList] = useState('');
  const [listToRename, setListToRename] = useState('');
  const [newName, setNewName] = useState('');
  const lists = Object.values(savedLists || {});
  lists.sort((a, b) => a.humanName < b.humanName ? -1 : 1);
  const noOp = (_: string) => {};
  const handleSelect = mode === 'selector' && !disabled ? onSelect || noOp : noOp;
  const product = useSelector((state: State) => state.product);

  function listDescription(listMetadata: ListMetadata, display: DisplaySettings): string {
    const entities = entitiesLabel(display, listMetadata.numRows);
    const action = listMetadata.source.type === 'snapshot' ? 'saved' : 'uploaded';
    const date = listMetadata.saveDate;
    return `${entities} ${action} on ${date}`;
  }

  function disabledIfAnotherIsBeingRenamed(list: ListMetadata) {
    const anotherIsBeingRenamed = listToRename && listToRename !== list.filename;
    // The 'disabled' prop on the IconButton accepts true or undefined, but not false.
    return { disabled: anotherIsBeingRenamed || undefined };
  }

  function getError() {
    if (listToRename === '') {
      return '';
    }
    const otherListNames = lists.filter(l => l.filename !== listToRename).map(l => l.humanName);
    if (newName === '') {
      return 'Name cannot be empty!';
    } else if (otherListNames.includes(newName)) {
      return 'List name already exists!';
    }
  }

  const error = getError();

  async function renameList() {
    const list = savedLists[listToRename];
    log(`Renaming list ${list.humanName} to ${newName}.`);
    try {
      const updatedSavedLists = await protectedJsonFetch('/api/renameList', {
        method: 'POST',
        body: JSON.stringify({
          filename: list.filename,
          newName,
          product
        })
      });
      dispatch({ type: Action.SetSavedLists, payload: updatedSavedLists[product] });
    } catch (error) {
      dispatch(setError(error));
    }
    closeRename();
  }

  async function downloadList(list: ListMetadata) {
    log(`Downloading list ${list.filename}`);
    try {
      const csv = await protectedJsonFetch('/api/exportList', {
        method: 'POST',
        body: JSON.stringify({ filename: list.filename, product }),
      });
      download(csv, list.humanName, 'text/csv');
    } catch (error) {
      dispatch(setError(error));
    }
  }

  async function deleteList(name: string) {
    log(`Deleting list ${name}`);
    try {
      const updatedSavedLists = await protectedJsonFetch('/api/deleteList', {
        method: 'POST',
        body: JSON.stringify({
          name,
          product
        })
      });
      dispatch({ type: Action.SetSavedLists, payload: updatedSavedLists[product] });
    } catch (error) {
      dispatch(setError(error));
    }
  }

  function startRename(list: ListMetadata) {
    setListToRename(list.filename);
    setNewName(list.humanName);
  }

  function closeRename() {
    setListToRename('');
    setNewName('');
  }

  function nameField(list: ListMetadata) {
    return (listToRename === list.filename
      ? <div style={{marginTop: '8px'}}><TextField
          value={newName}
          autoFocus={true}
          variant='outlined'
          error={!!error}
          helperText={error || ' '}
          onChange={event => setNewName(event.target.value)}
          data-testid='rename-list'
          margin='dense'
        /></div>
      : <ListItemText
          primary={list.humanName}
          secondary={listDescription(list, display)}
          data-testid={`saved-list_${list.filename}`}
        />
    );
  }

  function actionButtons(list: ListMetadata) {
    return (
      <ListItemSecondaryAction>
        {
          mode === 'editor' && listToRename === list.filename &&
            <>
              <Button
                onClick={closeRename}>Cancel
              </Button>
              <Button
                onClick={renameList}
                color='secondary'
                variant='contained'
                disabled={!!error || list.humanName === newName}
                style={{marginLeft: '1em'}}>Rename
              </Button>
            </>
        }
        {
          mode === 'editor' && listToRename !== list.filename &&
            <>
              <IconButton
                edge='end'
                aria-label='rename'
                data-testid={`rename_button_${list.filename}`}
                { ...disabledIfAnotherIsBeingRenamed(list)}
                onClick={() => { startRename(list); }}>
                <EditIcon />
              </IconButton>
              {exportAllowed &&
                <IconButton
                  edge='end'
                  aria-label='download'
                  { ...disabledIfAnotherIsBeingRenamed(list)}
                  onClick={() => { downloadList(list); }}>
                  <SaveAltIcon />
                </IconButton>
              }
              <IconButton
                edge='end'
                aria-label='delete'
                data-testid={`delete_button_${list.filename}`}
                { ...disabledIfAnotherIsBeingRenamed(list)}
                onClick={() => { setSelectedList(list.filename); setShowConfirmDialog(true); }}>
                <DeleteOutlineIcon />
              </IconButton>
            </>
        }
      </ListItemSecondaryAction>
    );
  }

  return (
    <div className={classes.main}>
      <div className={classes.explanation}>{explanation}</div>
      { lists.length === 0 &&
        <div className={classes.explanation}>
          No list was saved so far.
        </div>
      }
      <List>
          {lists.map(list => (
            <React.Fragment key={list.filename}>
              <ListItem
                className={classes.listItem}
                key={list.filename}
                button={(mode !== 'editor') as any}
                selected={list.filename === selected}
                onClick={() => { handleSelect(list.filename); }}>
                {nameField(list)}
                {actionButtons(list)}
              </ListItem>
              <Divider/>
            </React.Fragment>
          ))}
        </List>
        { showConfirmDialog && <ConfirmDialog
          open={true}
          message={`
            This will permanently delete the saved list "${savedLists[selectedList].humanName}".`}
          close={() => { setShowConfirmDialog(false); }}
          action={() => { deleteList(selectedList); }}
        /> }
    </div>
  );
}
