/**
 * A bar chart where each data point is represented by 2 bars: a solid "inner bar" and a
 * dashed "outer bar".
 * Used for representing actual and potential impacts.
 */
import React from 'react';
import { mergeWhileConcatenatingArrays, LayoutConstants, concat } from '../utils';
import { Colors } from '../sharedUtils';
import BarChart, { getBargap } from './BarChart';


interface Props {
  labels: string[],
  innerValues: number[],
  outerValues: number[],
  layout?: any,
  // `text` elements are displayed on top of the outer bar. If not set then nothing is displayed.
  text?: string[],
  textColor?: string | string[],
  innerBarColor?: string | string[],
  outerBarLineColor?: string | string[],
  hovertext?: string | string[],
  // You can pass extra data for each bar using `barData`. The `onSelectBar` will be called with
  // barData element corresponding to the selected bar.
  barData?: any[],
  onSelectBar?: (selectedBarData: any) => void,
  wrapAt?: number,  // If set then the bar names should be wrapped at this number of characters.
  bargap?: number,
}

// The projection of the i-th outer bar on the x axis is
//     [i - getOuterBarHalfWidth(bargap), i + getOuterBarHalfWidth(bargap)].
export function getOuterBarHalfWidth(bargap: number) {
  // The width of the outer bar is 10% of the inner bar's width.
  // The center of the i-th inner bar is always on the x=i line.
  // So the i-th inner bar's projection on the x axis is
  //     [i - 0.5 * (1 - bargap), i + 0.5 * (1 - bargap)]
  // and so the same for the outer bar is
  //     [i - 0.55 * (1 - bargap), i + 0.55 * (1 - bargap)].
  return 0.55 * (1 - bargap);
}

export default function BarChartWithOuterDashedRectangles(props: Props) {
  const { labels, innerValues, outerValues, onSelectBar, wrapAt, barData } = props;
  const textColor = colorByBar(props.textColor || Colors.black);
  const text = props.text || [];
  const innerBarColor = colorByBar(props.innerBarColor || Colors.darkblue);
  const outerBarLineColor = colorByBar(props.outerBarLineColor || Colors.black);
  const hovertext = props.hovertext || '';
  const layout = props.layout || {};
  const bargap = layout.bargap || getBargap(labels.length);

  // Converts colors (textColor, innerBarColor etc.) that are defined as string to array.
  // Colors can be defined either as a string (all data points uses the same color) or as an array.
  // It's more convenient if we can assume that all colors are defined as array so we use this
  // function to achieve that.
  function colorByBar(color: string | string[]) {
    if (typeof(color) === 'string') {
      return labels.map(_ => color);
    } else {
      return color;
    }
  }

  const halfWidth = getOuterBarHalfWidth(bargap);

  // The outer bars are represented by shapes on the Plotly chart.
  // To avoid also having a dashed line at the bottom, we use 3 lines to draw the outer bar
  // instead of using a 'rectangle' shape.
  const outerBarShapeLines = outerValues.map((value, id) => {
    const base = {
      type: 'line',
      line: { color: outerBarLineColor[id], dash: 'dash', width: '0.4' },
    };
    return [
      {
        ...base,
        x0: id - halfWidth,
        x1: id - halfWidth,
        y0: 0,
        y1: value,
      },
      {
        ...base,
        x0: id - halfWidth,
        x1: id + halfWidth,
        y0: value,
        y1: value,
      },
      {
        ...base,
        x0: id + halfWidth,
        x1: id + halfWidth,
        y0: 0,
        y1: value,
      },
    ];
  });

  const potentialImpactShapes = concat(outerBarShapeLines);

  // We use annotations for displaying the text on top of the outer bar.
  // We need to use annotations for this because simply using the text property would put the text
  // on top of the inner bar.
  const textAnnotations = text.map((t, id) => (
    {
      text: t,
      x: id,
      y: outerValues[id],
      yanchor: 'bottom',
      showarrow: false,
      font: { color: textColor[id], family: LayoutConstants.roboto },
    })
  );


  const defaultLayout = {
    bargap: bargap,
    shapes: potentialImpactShapes,
    annotations: textAnnotations,
  };

  const layoutWithDefaults = mergeWhileConcatenatingArrays([defaultLayout, layout]);


  return (
    <div style={{height: '100%'}}>
      <BarChart
        labels={labels}
        values={innerValues}
        layout={layoutWithDefaults}
        barColor={innerBarColor}
        hovertext={hovertext}
        barData={barData}
        onSelectBar={onSelectBar}
        wrapAt={wrapAt}
        baseBottomMargin={8}
        />
    </div>
  );
}
