import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { StateWith, useProductConfig, useSelectedOutcome, ChangeEvent } from '../store';
import { log } from '../utils';
import { addCommonDataToSqIng, computeWhatIfsFromSim } from '../addData';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import { Colors } from '../sharedUtils';
import { simulateOutcomeForIngredient } from './simulation';
import { ImpactVisualization } from './ImpactVisualization';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ImpactBar, SqIngWithCommonData } from '../clientTypes';
import { SimulatorData, PerCategory, IngredientGroup } from '../sharedTypes';
import GroupNavigator from '../insights/GroupNavigator';
import { collectIngGroupsWithData, getCurrentGroup, getImpactBars } from '../groups';
import { GroupImpactVisualization } from './GroupImpactVisualization';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: (props: any) => ({
      width: props.shrink ? '528px' : '1160px',
      marginRight: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: theme.spacing(1),
    }),
    ingSelector: {
      overflowX: 'auto',
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      flexGrow: 1,
    },
    helper: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
    },
    smallText: {
      fontSize: '10px',
    },
    legendItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '10px',
      marginRight: '42px',
      marginBottom: '16px'
    },
    legendItemBox: (props: any) => ({
      border: props.impactType === 'Potential' ? 'dashed 1px' : 'solid 1px invisible',
      backgroundColor: props.impactType === 'Potential'
        ? 'white'
        : props.impactType === 'Actual' ? Colors.mediumblue : Colors.darkcyclamen,
      width: '25px',
      height: '25px',
      marginRight: '8px'
    }),
    sortByText: {
      display: 'inline',
      fontSize: '10px',
      marginRight: theme.spacing(1),
    },
    alignedSelect: {
      marginTop: '-8px',
    },
  }),
);

type BarToSort = SqIngWithCommonData | ImpactBar;
interface SortingOption {
  id: string,
  title: string,
  sortFn: (a: BarToSort, b: BarToSort) => number,
}

const sortingOptions: SortingOption[] = [
  {
    id: 'actualOutcomeRatio',
    title: 'Actual Impact',
    sortFn: (a: BarToSort, b: BarToSort) => b.actualBar - a.actualBar,
  },
  {
    id: 'potentialOutcomeRatio',
    title: 'Potential Impact',
    sortFn: (a: BarToSort, b: BarToSort) => b.potentialBar - a.potentialBar,
  },
  {
    id: 'unrealized',
    title: 'Unrealized Potential',
    sortFn: (a: BarToSort, b: BarToSort) =>
      (b.potentialBar - b.actualBar) - (a.potentialBar - a.actualBar),
  },
];


export function LegendItem({impactType}: {impactType: 'Potential' | 'Actual' | 'Simulated'}) {
  const classes = useStyles({impactType});

  return <div className={classes.legendItemContainer}>
    <div className={classes.legendItemBox}/>
    <div>
      <div>{impactType}</div>
      <div>Impact</div>
    </div>
  </div>;
}


interface Props {
  shrink: boolean,
  selectedIng: string,
  onSelectIng: (ingCol: string) => void,
  onDragIng: (ingCol: string, newDistr: { [cat: string]: number }) => void,
  showGroups: boolean
  setShowGroups: (newValue: boolean) => void
  currentPath: Array<string>
  setCurrentPath: (newPath: string[]) => void
  weAreInAGroup: boolean
  ingredientGroups: IngredientGroup[]
}

type LocalState = StateWith<SimulatorData>;
interface IngWithLocalData extends SqIngWithCommonData {
  whatIfValues: PerCategory<number>,
  whatIfMagnitudes: PerCategory<number>,
}

export default function SimulatorIngredientSelector(props: Props) {
  const {
    shrink, selectedIng, onSelectIng, onDragIng,
    showGroups, setShowGroups, currentPath, setCurrentPath,
    weAreInAGroup, ingredientGroups
  } = props;
  const classes = useStyles({ shrink });
  const selectedOutcome = useSelectedOutcome();
  const {product, simulatorData, simulatorState} = useSelector((state: LocalState) => {
    const product = state.product;
    const simulatorData = state.data.simulation[selectedOutcome.id];
    const simulatorState = state.simulatorStateByProduct[product];
    return { product, simulatorData, simulatorState };
  });
  const cfg = useProductConfig();
  const sqIngredients = cfg.ingredients.filter( i => !i.context );
  const sqIngredientsWithData: IngWithLocalData[] = useSelector((state: LocalState) =>
    sqIngredients.map(i => {
      const withCommonData = addCommonDataToSqIng(i, state);
      const whatIfs = computeWhatIfsFromSim(i, state);
      return { ...withCommonData, ...whatIfs };
    })
  );
  const ingredientGroupsWithData = collectIngGroupsWithData(
    ingredientGroups, sqIngredientsWithData, selectedOutcome
  );
  const currentGroup = getCurrentGroup(
    weAreInAGroup, currentPath, ingredientGroups, showGroups, sqIngredients
  );
  const barsAreIngredients = currentGroup.subgroups === undefined;
  const impactBars: ImpactBar[] = getImpactBars(
    barsAreIngredients, currentGroup,
    sqIngredientsWithData, ingredientGroupsWithData);


  function diveIntoGroup(groupId: string) {
    const newPath = currentPath.concat(groupId);
    setCurrentPath(newPath);
  }

  function backToGroupLevel(groupId: string) {
    const idx = currentPath.indexOf(groupId);
    const newPath = currentPath.slice(0, idx + 1);
    setCurrentPath(newPath);
  }

  function backToTopLevel() {
    setCurrentPath([]);
  }

  function updateShowGroups() {
    if (showGroups) {
      backToTopLevel();
    }
    setShowGroups(!showGroups);
  }


  const defaultSortBy = sortingOptions[0].id;
  const [sortBy, changeSortBy] = useState(defaultSortBy);
  const sortOpt = sortingOptions.find(i => i.id === sortBy);
  const sortFn = sortOpt!.sortFn;
  sqIngredientsWithData.sort(sortFn);
  impactBars.sort(sortFn);


  const valuesForImpacts: { [ingColumn: string]: { potential: number, actual: number, simulated: number}} = {};
  for (const ing of sqIngredientsWithData) {
    valuesForImpacts[ing.column] = {
      potential: ing.potentialBar,
      actual: ing.actualBar,
      simulated: ing.magnitude(
        simulateOutcomeForIngredient(
          simulatorData[ing.column],
          simulatorState.originalDistributions[ing.column],
          simulatorState.simulatedDistributions[ing.column]
        )
      ),
    };
  }
  const maxPotential = barsAreIngredients
    ? Math.max(...sqIngredients.map((sqIng) => valuesForImpacts[sqIng.column].potential))
    : Math.max(...impactBars.map((bar) => bar.potentialBar));

  function loggedOnSelect(ingId: string) {
    onSelectIng(ingId);
    log(`Changed ingredient for simulation: ${product} -> ${ingId}`);
  }

  return (
    <div className={classes.main} >
      <div style={{minWidth: '528px', display: 'flex', flexDirection: 'row'}}>
        <LegendItem impactType='Potential'/>
        <LegendItem impactType='Actual'/>
        <LegendItem impactType='Simulated'/>
        <div style={{flex: 1}}></div>
        <div>
          <Typography className={classes.sortByText}>
            Sort By:
          </Typography>
          <TextField
            select
            value={sortBy}
            onChange={(event: ChangeEvent) => changeSortBy((event.target.value as string))}
            variant='outlined'
            margin='dense'
            className={classes.alignedSelect}
            SelectProps={{
              IconComponent: (props) => <KeyboardArrowDownIcon className={props.className}/>,
            }}
            InputProps={{
              classes: {
                input: classes.smallText
              }
            }}
            inputProps={{'data-testid': 'simulator-ing-sort-dropdown'}}
          >
          {sortingOptions.map((o: any) => <MenuItem key={o.id} value={o.id}>{o.title} &nbsp;</MenuItem>)}
          </TextField>
        </div>
      </div>
      { ingredientGroups.length > 0 &&
        <GroupNavigator
          currentPath={currentPath}
          showGroups={showGroups}
          updateShowGroups={updateShowGroups}
          backToGroupLevel={backToGroupLevel}
          backToTopLevel={backToTopLevel}
          groups={cfg!.ingredient_groups!}
        />
      }
      <div className={classes.ingSelector} data-testid='simulator-ingredient-selector'>
        <div style={{display: 'flex', flexDirection: 'row', maxWidth: '100%'}}>
          { barsAreIngredients &&
            sqIngredientsWithData.filter(
              sqIng => currentGroup.ingredients!.includes(sqIng.column)
            ).map(sqIng => <ImpactVisualization
              width={64}
              height={300}
              role='selector'
              actualMagnitude={valuesForImpacts[sqIng.column].actual}
              potentialMagnitude={valuesForImpacts[sqIng.column].potential}
              simulatedMagnitude={valuesForImpacts[sqIng.column].simulated}
              maxPotentialMagnitude={maxPotential}
              whatIfMagnitudes={sqIng.whatIfMagnitudes}
              magnitudeToOutcome={sqIng.magnitudeToOutcome}
              sqIng={sqIng}
              selected={sqIng.column === selectedIng}
              onSelect={() => loggedOnSelect(sqIng.column)}
              onDrag={(newDistr: { [cat: string]: number }) => onDragIng(sqIng.column, newDistr)}
              key={sqIng.column + selectedOutcome.id}
            />)
          }
          { !barsAreIngredients &&
            impactBars.map(impactBar => <GroupImpactVisualization
              width={128}
              height={300}
              impactBar={impactBar}
              maxPotential={maxPotential}
              onSelect={() => diveIntoGroup(impactBar.id)}
              key={impactBar.id + selectedOutcome.id}
            />)
          }
        </div>
      </div>
      <div className={classes.helper}>
        <Chip
          icon={<Icon>touch_app</Icon>}
          label={`Click to ${barsAreIngredients ? 'select ingredient for simulation' : 'expand group'}`}
          color='secondary'
        />
      </div>
    </div>
  );
}
