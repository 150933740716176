import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../sharedUtils';
import { IngredientCategory, PerOutcome, PerIngredient, PerCategory } from '../sharedTypes';
import { useSelectedOutcome, useFetchEffect } from '../store';
import { SqIngWithCommonData, ViewMode } from '../clientTypes';
import { WidgetPanelTitle } from '../WidgetPanel';
import OnlyIfDataUpToDate, { WaitForIt } from '../OnlyIfDataUpToDate';
import { getCategoryColors, displayFormat, displayImpact, LayoutConstants,
  sortCategoriesByWhatIf, segmentSizeStrings } from '../utils';
import { computeWhatIfMagnitudes } from '../addData';
import MarkdownIt from 'markdown-it';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '450px',
      backgroundColor: LayoutConstants.lightBlueBackgroundColor,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    whiteSection: {
      backgroundColor: Colors.white,
      padding: LayoutConstants.widgetPadding,
    },
    meaning: {
      maxHeight: theme.spacing(10),
      overflowY: 'auto',
      fontSize: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    impactsSection: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '16px',
      marginBottom: '16px',
    },
    lightBlueSection: {
      backgroundColor: LayoutConstants.lightBlueBackgroundColor,
      padding: LayoutConstants.widgetPadding,
      paddingBottom: 0,
    },
    impactScore: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    box: {
      width: '1.5em',
      height: '1.5em',
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
    impactTypeText: {
      fontSize: '0.75em',
      marginBottom: theme.spacing(1),
    },
    outcomeRange: {
      fontSize: '0.75em',
      marginBottom: theme.spacing(1),
    },
    potentialImpactBox: {
      border: '0.2px dashed'
    },
    actualImpactBox: {
      backgroundColor: Colors.mediumblue,
    },
    dot: {
      marginRight: theme.spacing(1.5),
      fontSize: theme.spacing(2),
    },
    bar: {
      backgroundColor: Colors.darkblue,
      verticalAlign: 'middle',
      display: 'inline-block',
      height: theme.spacing(2),
    },
    ingredientValues: {
      height: '100%',
      overflowY: 'auto'
    },
    valueTable: {
      borderSpacing: '4px',
    },
    segmentDigits: {
      marginLeft: theme.spacing(2),
      fontSize: theme.spacing(1.5),
    },
    segmentSuffix: {
      fontSize: theme.spacing(1.5),
    },
    segmentPercentage: {
      marginLeft: theme.spacing(0),
      fontSize: theme.spacing(1.5),
    },
    title: {
      marginTop: theme.spacing(1),
    },
    subTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    }
  }),
);

function Dot({ color }: { color: string }) {
  const classes = useStyles();
  return <span className={classes.dot} style={{ color: color }}>⬤</span>;
}

interface Props {
  selectedIngredientData: SqIngWithCommonData,
  viewMode: ViewMode,
}

export default function IngredientCategoriesWidget(props: Props) {
  const whatIfValues = useFetchEffect('/api/onDemand/whatIfValues');
  return (
    <OnlyIfDataUpToDate>
      <WaitForIt toBeTrue={whatIfValues !== undefined}>
      <IngredientCategoriesWidgetContent
        selectedIngredientData={props.selectedIngredientData}
        viewMode={props.viewMode}
        whatIfValues={whatIfValues!}
      />
      </WaitForIt>
    </OnlyIfDataUpToDate>
  );
}

interface ContentProps extends Props {
  whatIfValues: PerOutcome<PerIngredient<PerCategory<number>>>
}

function IngredientCategoriesWidgetContent(props: ContentProps) {
  const { selectedIngredientData, viewMode } = props;
  const outcome = useSelectedOutcome();
  const whatIfValues = props.whatIfValues[outcome.id][selectedIngredientData.column];
  const ingWithData = {
    ...selectedIngredientData,
    whatIfValues,
    whatIfMagnitudes: computeWhatIfMagnitudes(whatIfValues, selectedIngredientData.magnitude),
  };
  const classes = useStyles();
  const categories = sortCategoriesByWhatIf(ingWithData);
  const categoryColors = getCategoryColors(categories.map(c => c.value));
  const hasUnits = ingWithData.units !== undefined;
  const valueSectionTitle = hasUnits ? `Ingredient values (in ${ingWithData.units})` : 'Ingredient values';
  const relative = viewMode === 'relative';
  const displayActual = displayImpact(
    relative ? ingWithData.actualOutcomeRatio : ingWithData.actualOutcomeDelta,
    outcome, relative);
  const displayPotential = displayImpact(
    relative ? ingWithData.potentialOutcomeRatio : ingWithData.potentialOutcomeDelta,
    outcome, relative);
  const bestOutcome  = outcome.positive ? ingWithData.max : ingWithData.min;
  const worstOutcome  = outcome.positive ? ingWithData.min : ingWithData.max;

  const md = new MarkdownIt();

  function isFilteredOut(cat: IngredientCategory) {
    return !ingWithData.selectedCategories.includes(cat.value);
  }

  return (
    <div className={classes.root}>
      <div className={classes.lightBlueSection}>
        <div className={classes.title}>
          <WidgetPanelTitle title={ingWithData.human_name} />
        </div>
      </div>
      <div className={classes.whiteSection}>
        <div
           className={classes.meaning}
           dangerouslySetInnerHTML={{ __html: md.render(ingWithData.meaning || '') }}
        />

        <div className={classes.impactsSection} >

          <div style={{ marginRight: '40px' }}>
            <div className={classes.impactTypeText}>Potential Impact</div>
            <div className={classes.impactScore}>
              <div className={`${classes.box} ${classes.potentialImpactBox}`}></div>
              <div data-testid='potential-impact'>{displayPotential}</div>
            </div>
          </div>

          <div style={{ marginRight: '40px' }}>
            <div className={classes.impactTypeText}>Actual Impact</div>
            <div className={classes.impactScore}>
              <div className={`${classes.box} ${classes.actualImpactBox}`}></div>
              <div data-testid='actual-impact'>{displayActual}</div>
            </div>
          </div>

          <div>
            <div className={classes.impactTypeText}>If this ingredient changed</div>
              <div className={classes.outcomeRange} data-testid='best-value'>
                best-case {outcome.human_name}: <b>{displayFormat(bestOutcome, outcome)}&nbsp;
                {outcome.display && outcome.display.description}</b>
              </div>
              <div className={classes.outcomeRange}  data-testid='worst-value'>
                worst-case {outcome.human_name}: <b>{displayFormat(worstOutcome, outcome)}&nbsp;
                {outcome.display && outcome.display.description}</b>
              </div>
          </div>

        </div>

      </div>
      <div style={{flexGrow: 1, minHeight: '0px'}}>
      <div style={{padding: LayoutConstants.widgetPadding, display: 'flex', flexDirection: 'column', height: '95%'}}>
        <div className={classes.subTitle}>{valueSectionTitle}</div>
        <div className={classes.ingredientValues}>
          <table className={classes.valueTable}>
            <tbody>
              {
                categories.map((cat, idx) => {
                  const count = ingWithData.countPerCategory[cat.value];
                  const percentage = (ingWithData.percentagePerCategory[cat.value] as number);
                  const { digits, suffix } = segmentSizeStrings(count);
                  return (
                    <tr key={idx}>
                      <td valign='middle' style={{ paddingTop: '4px' }}>
                        <Dot color={categoryColors[cat.value]} />
                      </td>
                      <td  valign='bottom'>
                        <div style={{ color: isFilteredOut(cat) ? Colors.gray : Colors.black, paddingTop: '4px' }}>
                          {cat.display_name}
                        </div>
                        <div style={{ width: '100%' }}>
                          <>
                            <span
                              className={classes.bar}
                              style={{
                                // If not filtered out then show at least a thin line as bar. (
                                // even if there is no customer in the category.)
                                width: isFilteredOut(cat) ? 0 : Math.max(2 * percentage, 1)
                              }}>
                            </span>
                            { !isFilteredOut(cat) &&
                                <span>
                                    <span className={classes.segmentDigits}>{digits} </span>
                                    <span className={classes.segmentSuffix}>{suffix}</span>
                                    <span className={classes.segmentPercentage}>
                                      &nbsp;|&nbsp;{`${displayFormat(percentage)}%`}
                                    </span>
                                </span>
                            }
                          </>
                        </div>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
  );
}
