import React from 'react';
import { Outcome } from '../sharedTypes';
import { makeStyles, Theme, createStyles, Paper, CardActionArea } from '@material-ui/core';
import { Colors } from '../sharedUtils';
import { displayFormat } from '../utils';
import { WidgetPanelTitle } from '../WidgetPanel';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';
import { useSelector } from 'react-redux';
import { State } from '../store';
import DiffArrow from './DiffArrow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      width: '100%',
      height: '100%',
    },
    diffLabel: (options: any) => ({
      width: '72px',
      fontSize: options.width < 200 ? '14px' : '16px',
    }),
    outcomeDiffContainer: (options: any) => ({
      width: `${options.width}px`,
      padding: '8px 8px',
      borderRadius: '2px',
    }),
    diffRow: (options: any) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '12px',
      backgroundColor: options.selected ? Colors.darkblue : undefined,
      borderRadius: options.selected ? '4px' : undefined,
      color: options.selected ? 'white' : undefined
    }),
    diffRowSimulated: (options: any) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '12px',
      backgroundColor: options.active ? (options.selected ? Colors.darkblue : undefined) : Colors.lightsilver,
      borderRadius: options.selected ? '4px' : undefined,
      color: options.active ? (options.selected ? 'white' : undefined) : Colors.lightblack
    }),
    value: (options: any) => ({
      fontSize: options.width < 200 ? '20px' : '24px',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'right',
      flex: 1,
      color: options.selected ? 'white' : Colors.darkblue
    }),
    valueSimulated: (options: any) => ({
      fontSize: options.width < 200 ? '20px' : '24px',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'right',
      flex: 1,
      color: options.active ? (options.selected ? 'white' : Colors.darkblue) : Colors.lightblack
    }),
    diff: {
      width: '32px'
    },
    spacer: {
      width: theme.spacing(1),
      height: theme.spacing(1),
    },
    selectedOutcomeCard: {
      border: 'solid 1px', borderColor: Colors.darkblue, borderRadius: '4px'
    },
    outcomeCard: (options: any) => ({
      border: 'solid 1px',
      borderColor: options.selected ? Colors.darkblue : 'transparent',
      borderRadius: '4px'
    }),
  }),
);

interface Props {
  outcome: Outcome
  selected: boolean
  value: number
  simulatedValue?: number
  updateOutcome: (o: Outcome) => void
  width?: number
}

export default function OutcomeDiff( props: Props) {
  const { outcome, selected, value, simulatedValue, updateOutcome } = props;
  const simulatorState = useSelector((state: State) => (
    state.simulatorStateByProduct[state.product])
  );
  const width = props.width || 228;
  const classes = useStyles({ selected, active: simulatorState.active, width });

  return (
    <div
      className={ classes.outcomeCard }>
      <CardActionArea className={classes.actionArea}  onClick={() => updateOutcome(outcome)}>
        <div className={classes.outcomeDiffContainer}>
          <WidgetPanelTitle title={outcome.human_name}/>
          <OnlyIfDataUpToDate>
            <Paper elevation={2}>
              <div className={classes.diffRow}>
                <div className={classes.diffLabel}>Actual Value</div>
                <div className={classes.value}>{displayFormat(value, outcome)}</div>
                <div className={classes.diff}></div>
              </div>
            </Paper>
            <div className={classes.spacer}></div>
            <Paper elevation={simulatorState.active ? 2 : 1}>
              <div className={classes.diffRowSimulated}>
                <div className={classes.diffLabel}>Simulated Value</div>
                <div className={classes.valueSimulated}>{simulatedValue !== undefined ? displayFormat(simulatedValue, outcome) : '-'}</div>
                <div className={classes.diff}>
                  { simulatorState.active &&
                    <DiffArrow
                      outcome={outcome}
                      selected={selected}
                      value={value}
                      simulatedValue={simulatedValue!}
                    />
                  }
                </div>
              </div>
            </Paper>
          </OnlyIfDataUpToDate>
        </div>
      </CardActionArea>
    </div>
  );
}
