import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import WidgetPanel, { WidgetPanelTitle } from '../WidgetPanel';
import MarkdownIt from 'markdown-it';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      width: '1240px',
      marginLeft: theme.spacing(18),
    },
    pageTitle: {
      fontSize: '24px'
    },
    helpContent: {
      paddingRight: theme.spacing(10),
    },
  }),
);

export default function HelpPage({ help }: { help: string }) {
  const classes = useStyles();
  const md = new MarkdownIt();

  return (
    <div className={classes.root}>
        <WidgetPanel style={{width: '100%'}}>
          <WidgetPanelTitle title='Help' className={classes.pageTitle}/>
           <div
              className={classes.helpContent}
              dangerouslySetInnerHTML={{ __html: md.render(help) }}/>
        </WidgetPanel>
    </div>
  );
}
