/**
 * Component to compute and display group-level impacts,
 * simulated impact included.
 */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { StateWith, useProductConfig } from '../store';
import { IngredientGroup, SimulatorData } from '../sharedTypes';
import { isDiffSmall, LayoutConstants, scale } from '../utils';
import { Colors } from '../sharedUtils';
import { ImpactBar } from '../clientTypes';
import { collectAllIngredientsInGroup } from '../groups';
import { simulateOutcome } from './simulation';
import { SimulatedBar } from './SimulatedBar';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: (props: any) => ({
      cursor: props.role === 'simulator' ? 'default' : 'pointer',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: LayoutConstants.lightBlueBackgroundColor
      }
    }),
    barContainer: {
      borderRadius: '5px',
      marginRight: '4px',
      marginLeft: '4px',
      backgroundColor: 'white'
    },
    barWrapper: (props: any) => ({
      display: 'flex',
      justifyContent: 'center',
      height: `${props.height + 10}px`,
      paddingTop: '8px'
    }),
    groupNameBox: (props: any) => ({
      textAlign: 'center',
      paddingLeft: '2px',
      paddingRight: '2px',
      paddingTop: '16px',
      paddingBottom: '4px',
      width: `${props.width + 16}px`,
      flex: `0 0 ${props.width + 16}px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
      whiteSpace: 'nowrap'
    }),
  }),
);


interface GroupImpactVisualizationProps {
  width: number
  height: number
  impactBar: ImpactBar
  maxPotential: number
  onSelect: () => void
}

type LocalState = StateWith<SimulatorData>;
const dY = 10;


export function GroupImpactVisualization( props: GroupImpactVisualizationProps) {
  const { width, height, impactBar, maxPotential, onSelect } = props;
  const { simulationData, simulatorState, selectedOutcome, currentOutcomeValue } = useSelector(
    (state: LocalState) => {
      const selectedOutcome = state.selectedOutcomesByProduct[state.product];
      const currentOutcomeValue = state.data.currentOutcomeValues[selectedOutcome.id];
      const simulationData = state.data.simulation[selectedOutcome.id];
      const simulatorState = state.simulatorStateByProduct[state.product];
      return {
        simulationData, simulatorState, selectedOutcome, currentOutcomeValue
      };
    }
  );
  const cfg = useProductConfig();
  const ingredientGroups: IngredientGroup[] = cfg.ingredient_groups || [];
  const groupId = impactBar.id;
  const currentGroup = ingredientGroups.find(gr => gr.id === groupId)!;
  const ingsInGroup = collectAllIngredientsInGroup(currentGroup, ingredientGroups);

  const classes = useStyles({width, height});

  const actualMagnitude = impactBar.actualBar;
  const potentialMagnitude = impactBar.potentialBar;
  const simulatedValue = simulateOutcome(
    simulatorState,
    simulationData,
    currentOutcomeValue,
    selectedOutcome,
    ingsInGroup);
  const simulatedMagnitude = scale(simulatedValue, impactBar.worst!);

  function scaleMagnitude(val: number) {
    return val / maxPotential;
  }

  const diffIsVisible = !isDiffSmall(actualMagnitude, simulatedMagnitude);

  const scaledActual = scaleMagnitude(actualMagnitude);
  const scaledPotential = scaleMagnitude(potentialMagnitude);
  const scaledSimulated = scaleMagnitude(simulatedMagnitude);

  function toSVGCoord(val: number) {
    return height * (1 - val);
  }

  const svgYActual = toSVGCoord(scaledActual);
  const svgYPotential = toSVGCoord(scaledPotential);
  const svgYSimulated = toSVGCoord(scaledSimulated);

  return (
    <div className={classes.barContainer}>
      <div className={classes.actionArea} onClick={onSelect}>
        <div
          className={classes.barWrapper}
          data-testid={`sim-group-${impactBar.id}`}>
          <svg width={width} height={height + 2 * dY}>
            <SimulatedBar
              width={width}
              height={height}
              dY={dY}
              scaledPotential={scaledPotential}
              scaledSimulated={scaledSimulated}
              scaledActual={scaledActual}
              svgYPotential={svgYPotential}
              svgYSimulated={svgYSimulated}
              svgYActual={svgYActual}
              diffIsVisible={diffIsVisible}
              fillColor={Colors.darkblue}
            />
          </svg>
        </div>
        <div className={classes.groupNameBox}>
          {impactBar.human_name}
        </div>
      </div>
    </div>
  );
}
