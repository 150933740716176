import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, State, useProductConfig } from '../store';
import SimulatorIngredientSelector from './SimulatorIngredientSelector';
import SimulatorControl from './SimulatorControl';
import WidgetPanel from '../WidgetPanel';
import { IngredientGroup } from '../sharedTypes';

export default function SimulatorIngredientsPanel() {
  const dispatch = useDispatch();
  const selectedOutcome = useSelector((state: State) =>
    state.selectedOutcomesByProduct[state.product]
  );
  const [selectedIng, setSelectedIng] = useState<string>('');
  const [showController, setShowController] = useState<'no'|'withDetails'|'withoutDetails'>('no');
  const showIngredientSelector = showController !== 'withDetails';
  const cfg = useProductConfig();
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const weAreInAGroup = currentPath.length > 0;
  const ingredientGroups: IngredientGroup[] = cfg.ingredient_groups || [];
  const [showGroups, setShowGroups] = useState(ingredientGroups.length > 0);

  function onSelectIng(ingCol: string) {
    setSelectedIng(ingCol);
    // If the user has already closed the details panel (containing the Sankey-diagram) then he
    // already knows about it and is probably more interested in the shrinked Ingredient Selector.
    if (showController === 'no') {
      setShowController('withDetails');
    }
  }

  function onDragIng(ingCol: string, newDistr: { [cat: string]: number }) {
    setSelectedIng(ingCol);
    dispatch({
      type: Action.UpdateSimulatedDistribution,
      payload: { id: ingCol,  distribution: newDistr },
    });
  }

  function setShowControllerDetails(showDetails: boolean) {
      setShowController(showDetails ? 'withDetails' : 'withoutDetails');
  }

  return (
    <WidgetPanel style={{ height: '592px', width: '1176px' }} title={'Ingredient Impact On ' + selectedOutcome.human_name}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        { showIngredientSelector &&
            <SimulatorIngredientSelector
              shrink={showController === 'withoutDetails'}
              selectedIng={selectedIng}
              onSelectIng={onSelectIng}
              onDragIng={onDragIng}
              showGroups={showGroups}
              setShowGroups={setShowGroups}
              currentPath={currentPath}
              setCurrentPath={setCurrentPath}
              weAreInAGroup={weAreInAGroup}
              ingredientGroups={ingredientGroups}
            />
        }
        { showController !== 'no' &&
            <SimulatorControl
               ingredientForSimulation={selectedIng}
               showDetails={showController === 'withDetails'}
               setShowDetails={setShowControllerDetails}
               onDrag={(newDistr: { [cat: string]: number }) => onDragIng(selectedIng, newDistr)}
               close={() => { setShowController('no'); }}
            />
        }
      </div>
    </WidgetPanel>
  );
}
