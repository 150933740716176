import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme: Theme) =>
  ({
    goTop: {
      height: 70,
      width: 70,
      fontSize: 10,
      textTransform: 'none',
      padding: '5px',
      position: 'fixed',
      bottom: 20,
      left: 1350,
    }
  })
);


export default function GoToFilterPanelButton() {
  const classes = useStyles();
  const verticalPositionOfFilterPanel = 150;
  const trigger = useScrollTrigger({threshold: verticalPositionOfFilterPanel});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anchor = ((event.target as HTMLButtonElement).ownerDocument || document).querySelector(
      '#filter-panel',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <Fab
        color='secondary'
        size='large'
        className={classes.goTop}
        onClick={handleClick}> Go to <br/> filter panel
      </Fab>
    </Zoom>
  );
}
