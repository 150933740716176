/*
 * Displays the groups we have entered.
 * Allows switching between groups.
 */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import { IngredientGroup } from '../sharedTypes';


const useStyles = makeStyles(() =>
  createStyles({
    navLabels: {
      fontSize: '13px'
    }
  }),
);

interface Props {
  currentPath: string[],
  showGroups: boolean,
  updateShowGroups: () => void,
  backToGroupLevel: (groupId: string) => void,
  backToTopLevel: () => void,
  groups: IngredientGroup[]
}

export default function GroupNavigator(props: Props) {
  const classes = useStyles();

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    <FormControlLabel
      label={<Typography className={classes.navLabels}>Show groups</Typography>}
      labelPlacement='end'
      control={<Switch size='small' checked={props.showGroups} onChange={props.updateShowGroups}/>}
    />
    { props.showGroups &&
      <Breadcrumbs aria-label='breadcrumb' className={classes.navLabels}>
        { props.currentPath.length > 0 &&
          <Link
            color='inherit'
            onClick={props.backToTopLevel}
            style={{cursor: 'pointer'}}>
            <HomeIcon />
          </Link>
        }
        { props.currentPath.map(
          p => <Link
                key={p}
                color='inherit'
                onClick={() => props.backToGroupLevel(p)}
                style={{cursor: 'pointer'}}>
                { props.groups.find(gr => gr.id === p)!.human_name }
              </Link>
        )}
      </Breadcrumbs>
    }
    </div>
  );
}
