import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { StateWith, Action, useSelectedOutcome } from '../store';
import { Outcome, PerOutcome, SimulatorData } from '../sharedTypes';
import { log, LayoutConstants } from '../utils';
import OutcomeDiff from './OutcomeDiff';
import { simulateOutcome } from './simulation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    simulatorTitle: {
      fontWeight: 'bold',
      fontSize: '20px',
      paddingBottom: theme.spacing(2.5),
    },
    cardsContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2)
    },
  }),
);

type LocalState = StateWith<SimulatorData>;

export default function SimulatorOutcomeSelector() {
  const selectedOutcome = useSelectedOutcome();
  const { product, actualOutcomeValues, simulatorState, simulatorData, productOutcomes } =
  useSelector((state: LocalState) => {
    const product = state.product;
    const productOutcomes = state.cfg.products[product].outcomes;
    const simulatorData = state.data.simulation;
    const actualOutcomeValues: PerOutcome<number> = state.data.currentOutcomeValues;
    const simulatorState = state.simulatorStateByProduct[state.product];
    return { product, actualOutcomeValues, simulatorState, simulatorData, productOutcomes };
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const numOutcomes = productOutcomes.length;
  const outcomeDiffWidth = numOutcomes <= 4 ? 228 : 1100 / numOutcomes;

  function isSelected(outcome: Outcome) {
    return outcome.id === selectedOutcome.id;
  }

  function updateOutcome(outcome: Outcome) {
    log(
      `On simulator page changed outcome for product ${product}: ` +
      `${selectedOutcome.human_name} -> ${outcome.human_name}`
    );
    dispatch({ type: Action.SetOutcome, payload: outcome });
  }

  return (
    <div style={{...LayoutConstants.paperMargins}}>
      <div className={classes.simulatorTitle}>Select business outcome to simulate ingredient impact</div>
      <div className={classes.cardsContainer}>
        {productOutcomes.map((o: Outcome) => (
          <OutcomeDiff
            outcome={o}
            selected={isSelected(o)}
            width={outcomeDiffWidth}
            value={actualOutcomeValues[o.id]}
            simulatedValue={
              simulatorState.active
                ? simulateOutcome(simulatorState, simulatorData[o.id], actualOutcomeValues[o.id], o)
                : undefined
            }
            updateOutcome={updateOutcome}
            key={o.id}
          />)
        )}
      </div>
    </div>
  );
}
