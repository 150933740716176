// The root component of the whole trends page.
import React, { useState } from 'react';
import FilterPanel from '../filters/FilterPanel';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';
import { useStyles } from '../insights/InsightsPage';
import TrendsControls from './TrendsControls';
import TrendsChart from './TrendsChart';

export default function TrendsPage() {
  const classes = useStyles();
  const [showCounts, setShowCounts] = useState(false);

  return (
    <div className={classes.root}>
      <h1 className={classes.pageTitle}>Trends</h1>
      <div id='filter-panel' className={classes.anchor}></div>
      <FilterPanel/>
      <div className={classes.segmentationInfo}>
        <TrendsControls showCounts={showCounts} setShowCounts={setShowCounts}/>
        <OnlyIfDataUpToDate>
          <TrendsChart  showCounts={showCounts} setShowCounts={setShowCounts}/>
        </OnlyIfDataUpToDate>
      </div>
    </div>
  );
}
