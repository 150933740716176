import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FilterType, CategoryFilterKey, ImpactFilterKey, ListFilterKey,
         OutcomeFilterKey, Ingredient, ImpactType } from '../sharedTypes';
import { filterKeyToStr, parseFilterKey } from '../sharedUtils';
import SavedListsBrowser from '../lists/SavedListsBrowser';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useProductConfig, State, ChangeEvent, EditorState, isFilterKeyDefined } from '../store';


const useStyles = makeStyles(theme => ({
  outerContainer: {
    width: '520px',
    padding: '10px',
    alignSelf: 'flex-start'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  tab: { minWidth: '130px', maxWidth: '130px' },
  tabContent: { display: 'flex', flexWrap: 'wrap', margin: '10px 0px', alignItems: 'center' },
  ingredientSelector: { marginLeft: '10px', width: '200px' },
  outcomeSelector: { marginLeft: '10px', width: '100px' },
  impactTypeSelector: { marginLeft: '10px', width: '100px' },
  listSelector: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '100%' },
  tableCell: {
    verticalAlign: 'top'
  }
}));

function FlexBreak() {
  return <hr style={{ width: '100%', border: 0 }}/>;
}

// We keep track of the selected filter type for each filter type so that switching between them
// won't reset our settings.
type FilterKeysPerType = {
  category: CategoryFilterKey,
  impact: ImpactFilterKey,
  outcome: OutcomeFilterKey,
  list: ListFilterKey
};
interface FilterKeySelectorProps {
  closeEditor: () => void,
  setCurrentlyEditedFilterKey: (key: string) => void,
  editorState: EditorState,
}

export default function FilterKeySelector({ closeEditor, setCurrentlyEditedFilterKey,
                                            editorState }: FilterKeySelectorProps) {
  const classes = useStyles();
  const { outcome, filterBounds, savedLists } = useSelector((state: State) => {
    return {
      outcome: state.selectedOutcomesByProduct[state.product],
      filterBounds: state.filterBoundsByProduct[state.product],
      savedLists: state.savedListsByProduct[state.product] || {},
    };
  });
  function hasImpact(ingredient: Ingredient) {
    const bounds = filterBounds[outcome.id].impactBounds[ingredient.column];
    return (!ingredient.context
      && ingredient.categories.length > 0
      && bounds && bounds.relative.lower !== bounds.relative.upper);
  }
  const product = useProductConfig();
  // We don't want to show the kind of ingredients (location / multi-value) that have 0 categories.
  const ingredients = [...product.ingredients.filter(i => i.categories.length > 0)];
  ingredients.sort((f, s) => f.human_name < s.human_name ? -1 : 1);
  const selectableIngredients = ingredients.filter(hasImpact);
  const outcomes = [...product.outcomes];
  outcomes.sort((f, s) => f.human_name < s.human_name ? -1 : 1);
  const initFilterKeysPerType: FilterKeysPerType = {
    category: {
      type: 'category',
      ingredientId: ingredients[0].column,
    },
    impact: {
      type: 'impact',
      relative: true,
      ingredientId: selectableIngredients.length > 0 ? selectableIngredients[0].column : '',
      outcomeId: outcome.id,
      impactType: ImpactType.Actual,
    },
    outcome: {
      type: 'outcome',
      outcomeId: outcome.id,
    },
    list: {
      type: 'list',
      listname: Object.keys(savedLists)[0] || '',
    }
  };
  let initFilterType: FilterType;
  // Check if we are editing an already existing filter.
  if (isFilterKeyDefined(editorState)) {
    const currentlyEditedFilterKey = parseFilterKey(editorState.editedKey);
    initFilterType = currentlyEditedFilterKey.type;
    (initFilterKeysPerType as any)[currentlyEditedFilterKey.type] = currentlyEditedFilterKey;
  } else {
    initFilterType = 'category';
  }

  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>(initFilterType);
  const [filterKeysPerType, setFilterKeysPerType] = useState<FilterKeysPerType>(initFilterKeysPerType);
  const editedFilterKey = filterKeysPerType[selectedFilterType];

  function handleTypeChange(_: React.ChangeEvent<{}>, newType: FilterType) {
    setSelectedFilterType(newType);
  }

  function handleIngredientChange(newId: string) {
    const newFilterKeysPerType = { ...filterKeysPerType };
    (newFilterKeysPerType[selectedFilterType] as (CategoryFilterKey | ImpactFilterKey)).ingredientId = newId;
    setFilterKeysPerType(newFilterKeysPerType);
  }

  function handleOutcomeChange(newId: string) {
    const newFilterKeysPerType = { ...filterKeysPerType };
    (newFilterKeysPerType[selectedFilterType] as (ImpactFilterKey | OutcomeFilterKey)).outcomeId = newId;
    setFilterKeysPerType(newFilterKeysPerType);
  }

  function handleListChange(newList: string) {
    const newFilterKeysPerType = { ...filterKeysPerType };
    newFilterKeysPerType.list.listname = newList;
    setFilterKeysPerType(newFilterKeysPerType);
  }

  function handleImpactTypeChange(newType: ImpactType) {
    const newFilterKeysPerType = { ...filterKeysPerType };
    (newFilterKeysPerType[selectedFilterType] as ImpactFilterKey).impactType = newType;
    setFilterKeysPerType(newFilterKeysPerType);
  }

  const disabledListFilter = selectedFilterType === 'list' && Object.keys(savedLists).length === 0;
  const disabledIfKeyDetermined = { disabled:  isFilterKeyDefined(editorState) };
  const disabledImpactFilter = { disabled:  isFilterKeyDefined(editorState) || selectableIngredients.length === 0 };

  function IngredientSelector({ ingredientId, selectableIngredients }: { ingredientId: string,  selectableIngredients: Array<Ingredient>}) {
    return (
      <FormControl className={classes.ingredientSelector}>
        <Select
          value={ingredientId}
          inputProps={{'data-testid': 'filter-ingredient-selector'}}
          onChange={(e: ChangeEvent) => handleIngredientChange(e.target.value as string)}
          {...disabledIfKeyDetermined}
        >
          {selectableIngredients.map(i => (
            <MenuItem key={i.column} value={i.column}>{i.human_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function OutcomeSelector({ outcomeId }: { outcomeId: string }) {
    return (
      <FormControl className={classes.outcomeSelector}>
        <Select
          value={outcomeId}
          inputProps={{'data-testid': 'filter-outcome-selector'}}
          onChange={(e: ChangeEvent) => handleOutcomeChange(e.target.value as string)}
          {...disabledIfKeyDetermined}
        >
          {outcomes.map(o => (
            <MenuItem key={o.id} value={o.id}>{o.human_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function ListSelector() {
    return (
      <FormControl className={classes.listSelector}>
        <SavedListsBrowser
          savedLists={savedLists}
          mode='selector'
          onSelect={handleListChange}
          selected={(editedFilterKey as ListFilterKey).listname}
          {...disabledIfKeyDetermined}
        />
      </FormControl>
    );
  }

  function ImpactTypeSelector() {
    return (
      <FormControl className={classes.impactTypeSelector}>
        <Select
          value={(editedFilterKey as ImpactFilterKey).impactType}
          inputProps={{'data-testid': 'impact-type-selector'}}
          onChange={(e: ChangeEvent) => handleImpactTypeChange(e.target.value as ImpactType)}
          {...disabledIfKeyDetermined}
        >
          <MenuItem value={ImpactType.Actual}>actual</MenuItem>
          <MenuItem value={ImpactType.Potential}>potential</MenuItem>
          <MenuItem value={ImpactType.Unrealized}>unrealized</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <Paper className={classes.outerContainer}>
      <Tabs
        value={selectedFilterType}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        onChange={handleTypeChange}
      >
        <Tab classes={{ root: classes.tab }} label='Category filter' value='category' {...disabledIfKeyDetermined} />
        <Tab classes={{ root: classes.tab }} label='Impact filter' value='impact' {...disabledImpactFilter} />
        <Tab classes={{ root: classes.tab }} label='Outcome filter' value='outcome' {...disabledIfKeyDetermined} />
        <Tab classes={{ root: classes.tab }} label='List filter' value='list' {...disabledIfKeyDetermined} />
      </Tabs>
      {
        editedFilterKey.type === 'category' &&
        <div className={classes.tabContent}>
          <span>Select categories of</span>
          <IngredientSelector
            ingredientId={editedFilterKey.ingredientId}
            selectableIngredients={ingredients}
          />
        </div>
      }
      {
        editedFilterKey.type === 'impact' &&
        <div className={classes.tabContent}>
          Filter based on <ImpactTypeSelector/> impact<FlexBreak/>
          of
          <IngredientSelector
            ingredientId={editedFilterKey.ingredientId}
            selectableIngredients={selectableIngredients}
          />
          on
          <OutcomeSelector outcomeId={editedFilterKey.outcomeId} />
        </div>
      }
      {
        editedFilterKey.type === 'outcome' &&
        <div className={classes.tabContent}>
          <span>Filter based on</span>
          <OutcomeSelector outcomeId={editedFilterKey.outcomeId} />
        </div>
      }
      {
        editedFilterKey.type === 'list' &&
        <div className={classes.tabContent}>
          <ListSelector />
        </div>
      }
      <div className={classes.buttons}>
        <Button
          onClick={closeEditor}
          {...disabledIfKeyDetermined}>Cancel
        </Button>
        <Button
          onClick={() => setCurrentlyEditedFilterKey(filterKeyToStr(editedFilterKey))}
          color='secondary'
          variant='contained'
          style={{marginLeft: '1em'}}
          disabled={isFilterKeyDefined(editorState) || disabledListFilter}>Next
        </Button>
      </div>
    </Paper>
  );
}
