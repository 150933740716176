import React from 'react';
import { useSelector } from 'react-redux';
import { useDisplaySettings, State, useAudienceCount, useNumSqIngs } from '../store';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FilterPanel from '../filters/FilterPanel';
import IngredientsPanel from './IngredientsPanel';
import OutcomeSelector from './OutcomeSelector';
import SegmentSize from './SegmentSize';
import DistributionsPanel from './DistributionsPanel';
import GoToFilterPanelButton from '../GoToFilterPanelButton';
import WidgetPanel from '../WidgetPanel';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2.5),
      width: '1240px',
      marginLeft: theme.spacing(18),
    },
    pageTitle: {
      paddingLeft: theme.spacing(3),
      fontSize: '24px'
    },
    infoAndSelector: {
       display: 'flex',
       flexDirection: 'row',
       justifyContent: 'space-between',
       marginBottom: theme.spacing(2)
    },
    segmentationInfo: {
      padding: '1em',
      paddingTop: '0px'
    },
    anchor: {
      height: 0,
    }
  }),
);

export default function InsightsPage() {
  const classes = useStyles();
  const product = useSelector((state: State) => state.product);
  const audienceCount = useAudienceCount();
  const customers = useDisplaySettings().entities;
  const numSqIngs = useNumSqIngs();

  return (
    <div className={classes.root}>
      <h1 className={classes.pageTitle}>Insights & Segmentation</h1>
      <div id='filter-panel' className={classes.anchor}></div>
      <FilterPanel/>
      { audienceCount > 0 ?
      <div className={classes.segmentationInfo}>
        <div className={classes.infoAndSelector}>
          <SegmentSize/>
          <OutcomeSelector/>
        </div>
        <OnlyIfDataUpToDate>
          { numSqIngs > 0 &&
            <IngredientsPanel key={product}/>
          }
          <DistributionsPanel/>
        </OnlyIfDataUpToDate>
      </div> :
      <WidgetPanel style={{ marginLeft: '16px', marginBottom: '32px', width: '1184px', height: '200px'}} title='Empty segment!'>
        <OnlyIfDataUpToDate>
          <div>
            There are no {customers} in the segment you have created.
            Try to modify or remove some filters.
            You can also use the "Clear all filters" button to start again.
          </div>
        </OnlyIfDataUpToDate>
      </WidgetPanel>
      }
      <GoToFilterPanelButton/>
    </div>
  );
}
