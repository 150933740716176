import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import { State, Action,
         useProductConfig, useSelectedPage, useHistogram } from '../store';
import { Bounds } from '../sharedTypes';
import { filterKeyToStr } from '../sharedUtils';
import RangeSlider from './RangeSlider';
import Tooltip from '@material-ui/core/Tooltip';
import { orderedHistogramCounts } from '../utils';


const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
}));

export default function OutcomeRangeSelector(
  {
    closeEditor,
    outcomeId,
    goBackToKeySelection,
  }: {
    closeEditor: () => void,
    outcomeId: string,
    goBackToKeySelection: () => void,
  }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pageId = useSelectedPage();

  const outcome = useProductConfig().outcomes.filter(i => i.id === outcomeId)[0];
  const bounds: Bounds = useSelector((state: State) => {
    const { product, filterBoundsByProduct } = state;
    return filterBoundsByProduct[product][outcome.id].outcomeBounds;
  }, shallowEqual);
  const histogramCounts = useHistogram(outcomeId);
  const filterKey = filterKeyToStr({ type: 'outcome', outcomeId });
  const initialRange = useSelector((state: State): Bounds => {
    const { product, filtersByProduct } = state;
    return filtersByProduct[product][filterKey] as Bounds || bounds;
  }, shallowEqual);
  const [selectedRange, setSelectedRange] = useState(initialRange);
  const stepSize = outcome.stepSize;
  const histogramData = pageId !== 'trends' ? {
      bucketCounts: orderedHistogramCounts(histogramCounts, stepSize, bounds, outcome),
      bounds,
      currentRange: selectedRange,
      stepSize
    } : undefined;


  function updateFilters() {
    dispatch({
      type: Action.AddOrUpdateFilter,
      payload:  {
        key: filterKey,
        values: selectedRange,
      }
    });
    closeEditor();
  }

  function errorMessage() {
    if (selectedRange.lower === selectedRange.upper) {
      return 'Wrong range: lower bound should be strictly less than upper.';
    } else {
      return '';
    }
  }


  return (
    <>
    <h3>Outcome filter</h3>
        <RangeSlider
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            bounds={bounds}
            stepSize={stepSize}
            title={outcome.human_name}
            outcome={outcome}
            prefix={outcome.display && outcome.display.prefix}
            suffix={outcome.display && outcome.display.suffix}
            histogramData={histogramData}
        />
      <div className={classes.buttons}>
      <Button onClick={goBackToKeySelection}>Cancel</Button>
        <Tooltip  title={errorMessage()} placement='right'>
          <span>
            <Button
              onClick={updateFilters}
              color='secondary'
              variant='contained'
              style={{marginLeft: '1em'}}
              disabled={selectedRange.lower === selectedRange.upper}
              >Apply
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
}
