import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import { State } from '../store';

export default function AppMenu() {
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(undefined);
  const userName = useSelector((state: State) => state.userInfo.name);

  return (
    <div>
      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={openMenu} color='secondary'
              id='menu-opener'>
        { userName }
      </Button>
      <Menu id='menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
            onClose={closeMenu}>
        <MenuItem id='logout' onClick={closeMenu}>
          <Link href='/logout' underline='none'>Logout</Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
