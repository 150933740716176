/**
 * Component to use in SVG.
 * Displays impacts: actual, potential and simulated.
 * Can be used both for single ingredients and for groups.
 */

import React from 'react';
import { LayoutConstants } from '../utils';
import { Colors } from '../sharedUtils';


interface SimulatedBarProps {
  width: number
  height: number
  dY: number
  scaledPotential: number
  scaledSimulated: number
  scaledActual: number
  svgYPotential: number
  svgYSimulated: number
  svgYActual: number
  diffIsVisible: boolean
  fillColor: string
}


export function SimulatedBar( props: SimulatedBarProps) {
  const dashGap = 2;
  const innerX = 10 + dashGap;
  const innerWidth = props.width - 2 * innerX;

  return (
    <>
      <rect
        x={10} y={props.svgYPotential + props.dY}
        width={props.width - 20} height={props.height * props.scaledPotential}
        style={{fill: 'none', stroke: '#000000de', strokeDasharray: '9 9', strokeWidth: 0.4}}
      />
      {props.scaledSimulated >= props.scaledActual ?
        <>
          { props.diffIsVisible &&
            <rect
              x={innerX} y={props.svgYSimulated + props.dY}
              width={innerWidth} height={props.height * (props.scaledSimulated - props.scaledActual)}
              fill={Colors.darkcyclamen}
            />
          }
          <rect
            x={innerX} y={props.svgYActual + props.dY}
            width={innerWidth} height={props.height * props.scaledActual}
            fill={props.fillColor}
          />
        </>
      :
        <>
          <rect
            x={innerX} y={props.svgYActual + props.dY}
            width={innerWidth} height={props.height * props.scaledActual}
            fill={props.fillColor}
          />
          { props.diffIsVisible &&
            <rect
              x={innerX + 1} y={props.svgYActual + props.dY}
              width={innerWidth - 2} height={props.height * (props.scaledActual - props.scaledSimulated)}
              stroke={Colors.darkcyclamen}
              fill={LayoutConstants.lightBlueBackgroundColor}
            />
          }
        </>
      }
    </>
  );
}
