import React from 'react';
import { useSelector } from 'react-redux';
import { StateWith } from '../store';
import { lighter } from '../utils';
import { Colors } from '../sharedUtils';
import { InsightsData } from '../sharedTypes';
import ErrorIcon from '@material-ui/icons/Error';

const a = 50 * (1 + 1 / Math.sqrt(2));
const rSmall = 10;
const grayArcWidth = 4;
const startingAngleInDegree = 135;

function clockToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = angleInDegrees * Math.PI / 180.0;
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
  const start = clockToCartesian(x, y, radius, endAngle);
  const end = clockToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(' ');
  return d;
}

function gaugeArc(score: number, color: string, id: string) {
  return (
    <path
      id={id}
      fill='none'
      stroke={color}
      strokeWidth={grayArcWidth}
      d={describeArc(50, 50, 50 - grayArcWidth / 2, startingAngleInDegree, scoreToEndAngle(score))}
    />
  );
}

function gaugeTick(score: number, color: string, startRadius: number, endRadius: number, width: number) {
  const start = clockToCartesian(50, 50, startRadius, scoreToEndAngle(score));
  const end = clockToCartesian(50, 50, endRadius, scoreToEndAngle(score));
  return (
    <line
      fill='none'
      stroke={color}
      strokeWidth={width}
      x1={start.x}
      y1={start.y}
      x2={end.x}
      y2={end.y}
      key={score}
    />
  );
}

function gaugeLabel(score: number, radius: number, color: string) {
  const pos = clockToCartesian(50, 50, radius, scoreToEndAngle(score));
  return (
    <text
      x={pos.x} y={pos.y}
      textAnchor='middle'
      alignmentBaseline='middle'
      fontSize='6'
      key={`label-${score}`}
      fill={color}>
      {score}
    </text>
  );
}

function scoreToEndAngle(score: number) {
  return (startingAngleInDegree + 27 * score );
}

type LocalState = StateWith<InsightsData>;

export default function HiGauge( { selected }: { selected: boolean } ) {
  const colors = {
    fullArc: selected ? Colors.lightgray : lighter(Colors.gray),
    scoreArc: selected ? Colors.mediumblue : Colors.mediumblue,
    scoreValue: selected ? Colors.white : Colors.darkblue,
    scoreTick: selected ? Colors.white : Colors.darkblue,
    smallTick: selected ? Colors.gray : Colors.gray,
    circleStroke: selected ? Colors.white : Colors.gray,
    circleFill: selected ? Colors.white : 'none',
    labelColor: selected ? Colors.white : Colors.darkblue,
  };

  const hiScore = useSelector((state: LocalState) => state.data.currentOutcomeValues.chi);

  if ( 0 > hiScore || 10 < hiScore) {
    return (
      <>
        <p style={{textAlign: 'center'}}>
          <ErrorIcon color='error' fontSize='large'/>
        </p>
        <p>
          <small>
            <span>Invalid HI score: </span>
            <b>{hiScore} </b>.
            <span>Should be between 0 and 10.</span>
          </small>
        </p>
      </>
    );
  } else return (
    <svg width='100%' height='100%' viewBox='0 0 100 100'>
      <circle cx='50' cy='50' r={rSmall} stroke={`${colors.circleStroke}`} strokeWidth='1' fill={`${colors.circleFill}`} />
      {gaugeArc(10, `${colors.fullArc}`, 'full-arc')}
      {gaugeArc(hiScore, `${colors.scoreArc}`, 'score-arc')}
      {
        [0, 2, 4, 6, 8, 10].map(
          (score) => {
            return gaugeTick(
              score,
              `${colors.smallTick}`,
              50 - 3 * grayArcWidth, 50 - 1.5 * grayArcWidth, grayArcWidth / 6);
          }
        )
      }
      {
        [1, 3, 5, 7, 9].map(
          (score) => {
            return gaugeTick(
              score,
              `${colors.smallTick}`,
              50 - 2.2 * grayArcWidth, 50 - 1.5 * grayArcWidth, grayArcWidth / 6);
          }
        )
      }
      {
        [0, 2, 4, 6, 8, 10].map(
          (score) => {
            return gaugeLabel(score, 50 - 4.5 * grayArcWidth, colors.labelColor);
          }
        )
      }
      {gaugeTick(
        hiScore,
        `${colors.scoreTick}`,
        rSmall + 0.5 * grayArcWidth, 50 - 1.5 * grayArcWidth, grayArcWidth / 2)
      }
      <text
        x='50%' y={a - grayArcWidth}
        fill={`${colors.scoreValue}`} textAnchor='middle' fontSize='20' fontWeight='bold'>
        {hiScore.toFixed(1)}
      </text>
    </svg>

  );
}
