import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { useDisplaySettings, useProductConfig, Action, StateWith } from '../store';
import { segmentSizeStrings } from '../utils';
import { Colors } from '../sharedUtils';
import WidgetPanel from '../WidgetPanel';
import OnlyIfDataUpToDate from '../OnlyIfDataUpToDate';
import SvgIcon from '@material-ui/core/SvgIcon';
import { SVGIcons } from '../icons';
import { PointLevelValues, InsightsData } from '../sharedTypes';

const useStyles = makeStyles((theme: Theme) =>
  ({
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '5px',
    },
    segmentDigits: {
      fontSize: '40px',
      color: Colors.darkblue,
      fontWeight: 'bold'
    },
    segmentSuffix: {
      fontSize: '40px',
      color: Colors.darkblue,
      fontWeight: 'bold'
    },
    baseSize: {
      color: Colors.mediumblue,
    },
    entityIcon: {
      width: '40px',
      height: '40px',
      color: Colors.darkblue
    },
    iconContainer: {
      alignSelf: 'flex-start',
    },
    content: {
      alignSelf: 'flex-start',
      marginLeft: theme.spacing(2),
    },
    infoText: {
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 'bold',
    }
  }),
);

type LocalState = StateWith<InsightsData>;

export default function SegmentSize() {
  const display = useDisplaySettings();
  const cfg = useProductConfig();
  const data = useSelector((state: LocalState) => state.data);
  const base = segmentSizeStrings(data.baseCount);
  const segment = segmentSizeStrings(data.audienceCount);
  const product = useSelector((state: LocalState) => state.product);
  const activeFilters = useSelector((state: LocalState) => state.filtersByProduct[product]);
  const classes = useStyles();
  const dispatch = useDispatch();

  function switchToConnectedProduct() {
    // Pick out the selected IDs from a "by id" column, which currently means kind=location.
    const idColumn = cfg.ingredients.find(i => i.kind === 'location')!.column;
    const ids = Object.values(data.byPoint![idColumn]).map(e => (e as PointLevelValues)[cfg.datasource.primary_key]);
    dispatch({ type: Action.SetProduct, payload: cfg.product_connection!.product });
    dispatch({
      type: Action.SetFilters,
      payload: { ['category/' + cfg.product_connection!.foreign_key as any]: ids },
    });
  }

  return (
    <WidgetPanel style={{width: '310px', height: '168px', position: 'relative'}} title={display.entities}>
      <div className={classes.mainContainer}>
        <div className={classes.iconContainer}>
          { display.entity_icon && display.entity_icon in SVGIcons &&
          <SvgIcon viewBox='-1 -1 40 40' className={classes.entityIcon}>
            {SVGIcons[display.entity_icon](Colors.darkblue)}
          </SvgIcon>
          }
        </div>
        <div className={classes.content}>
          <div className={classes.infoText}>Selected {display.entities}</div>
          <OnlyIfDataUpToDate>
            <div>
              <span className={classes.segmentDigits}>{segment.digits} </span>
              <span className={classes.segmentSuffix}>{segment.suffix}</span>
            </div>
            {Object.keys(activeFilters).length > 0
              ?
                <div className={classes.infoText}>
                  out of a total of
                  <span className={classes.baseSize}> {base.digits} {base.suffix} </span>
                </div>
              : <div className={classes.infoText}>total number of {display.entities}</div>
            }
            { cfg.product_connection &&
              <CardActions><Button onClick={switchToConnectedProduct}>
                {cfg.product_connection.button_text}
              </Button></CardActions>
            }
          </OnlyIfDataUpToDate>
        </div>
      </div>
    </WidgetPanel>
  );
}
