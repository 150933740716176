/**
 *  This is the main dialog of the filter panel for managing saved lists.
 *  It belongs to the "Lists..." button.
 *
 *  Uses the SavedListBrowser component for list selecrtion and adds an
 *  upload button for uploading lists.
 */
import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import dialogStyle from '../dialogStyle';
import SavedListsBrowser from '../lists/SavedListsBrowser';
import ListUploadButton from '../lists/ListUploadButton';
import { ListMetadata } from '../store';


interface Props {
  open: boolean
  savedLists: { [name: string]: ListMetadata }
  onClose: () => void
}

export default function ListsDialog( props: Props) {
  const { open, savedLists, onClose } = props;
  const classes = dialogStyle();
  const listBrowserInfo = 'These lists can be used when you add a filter or use the "Find lookalikes" feature.';

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.dialogContent}}>
      <DialogTitle className={classes.dialogTitle}>Saved lists</DialogTitle>
      <div className={classes.decoration}/>
      <DialogContent>
        <SavedListsBrowser
          savedLists={savedLists}
          explanation={listBrowserInfo}
          mode='editor'
        />
      </DialogContent>
      <DialogActions>
        <ListUploadButton/>
        <div style={{flex: '1 0 0'}} />
        <Button onClick={onClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
