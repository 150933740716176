/**
 * Functions to help navigation between groups and
 * aggregation of impacts for ingredient groups.
 */

import { ImpactBar, IngredientGroupWithData, SqIngWithCommonData } from './clientTypes';
import { Ingredient, IngredientGroup, Outcome } from './sharedTypes';
import { clipOutcome } from './simulator/simulation';
import { distinct, scale } from './utils';


// Returns all the ingredient ids under the group, those under subgroups included.
export function collectAllIngredientsInGroup(group: IngredientGroup, ingredientGroups: IngredientGroup[]): string[] {
  if (group.ingredients) {
    return group.ingredients;
  } else {
    const ingredientsPerSubgroups = group.subgroups!
      .map(subgr => ingredientGroups.find(gr => gr.id === subgr)!)
      .flatMap(gr => collectAllIngredientsInGroup(gr, ingredientGroups));
    return distinct(ingredientsPerSubgroups);
  }
}

export function collectIngGroupsWithData(
  ingredientGroups: IngredientGroup[],
  serviceQualityIngredientsWithData: SqIngWithCommonData[],
  outcome: Outcome) {

  function addDataToGroup(group: IngredientGroup): IngredientGroupWithData {
    return { ...getAggrImpacts(group), ...group };
  }

  function getAggrImpacts(group: IngredientGroup) {
    const allIngredientsWithData = collectAllIngredientsWithDataInGroup(group);
    let actualOutcomeRatio = allIngredientsWithData
      .reduce((soFar, nextIng) => soFar * nextIng.actualOutcomeRatio, 1);
    let potentialOutcomeRatio = allIngredientsWithData
      .reduce((soFar, nextIng) => soFar * nextIng.potentialOutcomeRatio, 1);
    // Transform the aggregated ratios back into outcome units.
    const { curr } = allIngredientsWithData[0]; // `curr` is the same for all ings.
    let worst = curr / actualOutcomeRatio;
    let best = worst * potentialOutcomeRatio;
    // clip bounds
    worst = clipOutcome(worst, outcome);
    best = clipOutcome(best, outcome);
    // (re-)calculate everything with clipped values
    const actualOutcomeDelta = curr - worst;
    const potentialOutcomeDelta = best - worst;
    actualOutcomeRatio = curr / worst;
    potentialOutcomeRatio = best / worst;
    const actualBar = scale(curr, worst);
    const potentialBar = scale(best, worst);
    return {
      actualOutcomeRatio, potentialOutcomeRatio, actualOutcomeDelta, potentialOutcomeDelta,
      actualBar, potentialBar, worst};
  }

  // Returns all the ingredients under the group, those under subgroups included.
  function collectAllIngredientsWithDataInGroup(group: IngredientGroup): SqIngWithCommonData[] {
    if (group.ingredients) {
      return group.ingredients.map(i =>
        serviceQualityIngredientsWithData.find(soqIng => soqIng.column === i)!);
    } else {
      const ingredientsPerSubgroups = group.subgroups!
        .map(subgr => ingredientGroups.find(gr => gr.id === subgr)!)
        .flatMap(gr => collectAllIngredientsWithDataInGroup(gr));
      return distinct(ingredientsPerSubgroups);
    }
  }

  return ingredientGroups.map(gr => addDataToGroup(gr));

  }

// Returns the ids of the groups that are not subgroups of any other group.
export function getRootGroups(ingredientGroups: IngredientGroup[]) {
  const notRootGroupIds = new Set();
  for (const gr of ingredientGroups) {
    for (const subgr of gr.subgroups || []) {
      notRootGroupIds.add(subgr);
    }
  }
  return ingredientGroups.map(gr => gr.id).filter(i => !notRootGroupIds.has(i));
}

// Collects group metadata about current group for widgets
// which display ingredient groups.
export function getCurrentGroup(
    weAreInAGroup: boolean,
    currentPath: string[],
    ingredientGroups: IngredientGroup[],
    showGroups: boolean,
    serviceQualityIngs: Ingredient[]): IngredientGroup {
  if (weAreInAGroup) {
    const currentGroupId = currentPath[currentPath.length - 1];
    return ingredientGroups.find(gr => gr.id === currentGroupId)!;
  } else {
    // We create a pseudo top level group.
    const items = showGroups && ingredientGroups.length > 0 ?
      { subgroups: getRootGroups(ingredientGroups) } :
      { ingredients: serviceQualityIngs.map(i => i.column) };
    return { id: '', human_name: '', ...items };
  }
}


export function getImpactBars(
    barsAreIngredients: boolean,
    currentGroup: IngredientGroup,
    sqIngsWithData: SqIngWithCommonData[],
    ingGroupsWithData: IngredientGroupWithData[]): ImpactBar[] {
  if (barsAreIngredients) {
    return currentGroup.ingredients!
      .map(ingCol => sqIngsWithData.find(sqIng => sqIng.column === ingCol)!)
      .map(i => ({ ...i, id: i.column }));
  } else {
    return currentGroup.subgroups!
      .map(subgrId => ingGroupsWithData.find(gr => gr.id === subgrId)!);
  }
}
