import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { State, Action, ListMetadata, useSelectedPage } from '../store';
import { Colors } from '../sharedUtils';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TocIcon from '@material-ui/icons/Toc';
import SaveIcon from '@material-ui/icons/Save';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { log } from '../utils';
import SvgIcon from '@material-ui/core/SvgIcon';
import { SVGIcons } from '../icons';
import SaveDialog from './SaveDialog';
import ListsDialog from './ListsDialog';
import LookalikeDialog from './LookalikeDialog';
import LoadFilterSetDialog from './LoadFilterSetDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      minWidth: '164px',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    saveDate: {
      padding: '0px 16px 16px 16px',
      fontSize: '10px'
    },
    tooltip: {
      maxWidth: 200
    },
  }),
);


export default function FilterPanelActions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cfg, product, savedFilterSets, savedLists } = useSelector((state: State) => {
    const { cfg, product, selectedDatesByProduct } = state;
    return {
      cfg,
      product,
      savedFilterSets: state.savedFilterSetsByProduct[product],
      savedLists: state.savedListsByProduct[product],
      filters: state.filtersByProduct[product],
      selectedDate: selectedDatesByProduct[product][1],
    };
  }, shallowEqual);
  const [loadFilterSetOpen, setLoadFilterSet] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [listDialogOpen, setListDialogOpen] = useState(false);
  const [lookalikeDialogOpen, setLookalikeDialogOpen] = useState(false);
  const loadButtonDisabled = !savedFilterSets || Object.keys(savedFilterSets).length === 0;
  const lookalikeConfig = cfg.products[product].lookalike_audience;
  const lookalikeButtonEnabled = lookalikeConfig && lookalikeConfig.enabled;
  const existingFilterSetNames = savedFilterSets ? Object.keys(savedFilterSets) : [];
  const existingListNames = savedLists ? Object.values(savedLists).map((m: ListMetadata) => m.humanName) : [];
  const page = useSelectedPage();

  return (
    <div>
      <>
        <Button color='secondary' variant='contained' className={classes.button}
                disabled={loadButtonDisabled} onClick={() => setLoadFilterSet(true)}>
          <SvgIcon className={classes.leftIcon} fontSize='small'>
            {SVGIcons.funnel(loadButtonDisabled ? Colors.gray : Colors.white)}
          </SvgIcon>
          Filter sets
        </Button>
        <LoadFilterSetDialog
          open={loadFilterSetOpen}
          savedFilterSets={savedFilterSets}
          onSelect={ name => {
            if (name !== undefined) {
              log(`Loaded filter set ${name}`);
              const action = {
                type: Action.SetFilters,
                payload: savedFilterSets[name].filters,
              };
              dispatch(action);
            }
            setLoadFilterSet(false);
          }}
        />
      </>
      <>
        <Button color='secondary' variant='contained' className={classes.button} onClick={() => { setListDialogOpen(true); }}>
          <TocIcon className={classes.leftIcon} />
          Lists
        </Button>
        <ListsDialog
          savedLists={savedLists}
          open={listDialogOpen}
          onClose={() => { setListDialogOpen(false); }}/>
      </>
      <>
        <Button color='secondary' variant='contained' className={classes.button}
                onClick={() => { setSaveDialogOpen(true); }}>
          <SaveIcon className={classes.leftIcon} />
          Save ...
        </Button>
        {saveDialogOpen &&
          <SaveDialog
            open={saveDialogOpen}
            onClose={() => { setSaveDialogOpen(false); }}
            setSaveDialogOpen={setSaveDialogOpen}
            existingFilterSetNames={existingFilterSetNames}
            existingListNames={existingListNames}
          />
        }
      </>

      { page !== 'trends' && lookalikeButtonEnabled && <>
        <Tooltip title='Upload a list of customers to find the segment that best represents them.'
          placement='top' classes={{ tooltip: classes.tooltip }}>
          <Button
            color='secondary'
            variant='contained'
            component='label'
            onClick={() => setLookalikeDialogOpen(true)}
            className={classes.button}>
            <SupervisedUserCircleIcon className={classes.leftIcon} />
            Find lookalikes
          </Button>
        </Tooltip>
        { lookalikeDialogOpen && <LookalikeDialog onClose={() => setLookalikeDialogOpen(false)}/> }
      </> }
    </div>
  );
}
