/**
 * Component to change an ingredient distribution.
 *
 * Colors the elements of the distribution according to our
 * standard coloring scheme.
 *
 */
import React from 'react';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { StateWith, Action, useAudienceCount } from '../store';
import { addCommonDataToSqIng, computeWhatIfsFromSim } from '../addData';
import { Ingredient, SimulatorData } from '../sharedTypes';
import { Colors } from '../sharedUtils';
import { sortCategoriesByWhatIf, segmentSizeStrings } from '../utils';
import { valuesToNames } from './simulation';
import Slider from '@material-ui/core/Slider/Slider';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legendContainer: {
      marginTop: '24px',
      paddingLeft: '4px',
      overflowX: 'auto',
      fontSize: '12px'
    },
    legendLabel: {
      fontWeight: 'bold',
      minWidth: '80px',
      paddingRight: '16px',
      paddingLeft: '4px',
    },
    legendValue: {
      paddingLeft: '8px',
    },
    legendCount: {
      fontSize: '10px',
      paddingLeft: '8px',
    },
  }),
);

const DistributionSlider = withStyles(theme => ({
  track: {
    height: 0,
  },
  rail: {
    height: 0,
    color: Colors.darkblue,
    borderRadius: theme.spacing(0.5),
  },
  thumb: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: Colors.lightblack,
  },
}))(Slider);

type LocalState = StateWith<SimulatorData>;

export default function IngredientDistributionEditor({ ingredient, categoryColors }: { ingredient: Ingredient, categoryColors: any }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const simulatedDistribution = useSelector((state: LocalState) => {
    return state.simulatorStateByProduct[state.product].simulatedDistributions[ingredient.column];
  });
  const ingredientWithData = useSelector((state: LocalState) => {
    const ingWithCommonData = addCommonDataToSqIng(ingredient, state);
    const whatIfs = computeWhatIfsFromSim(ingredient, state);
    return { ...ingWithCommonData, ...whatIfs };
  });
  const audienceCount = useAudienceCount();
  const categories = sortCategoriesByWhatIf(ingredientWithData);
  const categoryIds = categories.map(c => c.value);
  const valueNames = valuesToNames(ingredient);


  const breakpoints: number[] = [];
  let total: number = 0;
  for (const category of categoryIds.slice(0, -1)) { // We don't need the last breakpoint at 100%
    total += simulatedDistribution[category];
    breakpoints.push(100 * total);
  }

  const rails = categoryIds.map((catId, i) => ({
    from: i === 0 ? 0 : breakpoints[i - 1],
    to: i === categoryIds.length - 1 ? 100 :  breakpoints[i],
    color: categoryColors[categoryIds[i]]
  }));

  function valuesToDistribution(breakpoints: number[]) {
    const newDistribution: { [category: string]: number } = {};
    const railEndpoints = [0].concat(breakpoints).concat([100]);
    for (let i = 0; i < categoryIds.length; i++) {
      newDistribution[categoryIds[i]] = (railEndpoints[i + 1] - railEndpoints[i]) / 100;
    }
    return newDistribution;
  }

  function updateDistribution(values: number[]) {
    const distribution = valuesToDistribution(values);
    dispatch({ type: Action.UpdateSimulatedDistribution, payload: { id: ingredient.column, distribution }});
  }

  return (
    <div>
      <div style={{width: '100%', position: 'relative'}}>
        <Box
          position='relative'
          zIndex='tooltip'
          left={0}
          top={0}
        >
          <DistributionSlider
            value={breakpoints}
            min={0}
            max={100}
            step={0.1}
            onChange={(event, value) => { updateDistribution(value as number[]); }}
          />
        </Box>
        <Box
          position='absolute'
          zIndex='modal'
          left={0}
          top={4}
        >
          {/* Colored strips under the slider to display the elements of the distribution. */}
          <svg viewBox='0 0 600 10' width='600' height='10'>
            {
              rails.map(
                (v, i) => <rect
                  x={6 * v.from}
                  y={0}
                  width={6 * (v.to - v.from)}
                  height={10}
                  rx={5}
                  ry={5}
                  key={i}
                  style={{fill: v.color, stroke: v.color}}
                />
              )
            }
          </svg>
        </Box>
      </div>
      <div className={classes.legendContainer}>
        <table>
          <tbody>
            <tr>
              {
                categoryIds.map( cat =>
                  <td key={cat} style={{borderLeft: `4px solid ${categoryColors[cat]}`}}>
                    <div className={classes.legendLabel}>{valueNames[cat]}</div>
                  </td>
                )
              }
            </tr>
            <tr>
              {
                categoryIds.map( cat =>
                  <td key={cat}>
                    <div className={classes.legendValue}>{(simulatedDistribution[cat] * 100).toFixed(1)}%</div>
                  </td>
                )
              }
            </tr>
            <tr>
              {
                categoryIds.map( cat =>
                  <td key={cat}>
                    <div className={classes.legendCount}>
                      { segmentSizeStrings(Math.round(simulatedDistribution[cat] * audienceCount)).digits }&nbsp;
                      { segmentSizeStrings(Math.round(simulatedDistribution[cat] * audienceCount)).suffix }
                    </div>
                  </td>
                )
              }
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
