import React from 'react';

// Material-ui seems to be only able to set the fill color of an SVG icon (with CSS, like font color),
// but not the stroke color. So we needed to introduce this color argument to be able to change the
// stroke color.
export const SVGIcons: { [key: string]: (color: string) => JSX.Element } = {
  funnel: (color: string) => <path d='M0-0h24.551l-9.895 10.509v6.662l-3.68 4.072v-10.735z' fill={color}/>,
  tower: (color: string) =>
  <>
    <rect height='36' width='4' y='0' x='16' stroke='none' fill={color}/>
    <rect height='16' width='3' y='0' x='10' stroke='none' fill={color}/>
    <rect height='16' width='3' y='0' x='4' stroke='none' fill={color}/>
    <rect height='16' width='3' y='0' x='23' stroke='none' fill={color}/>
    <rect height='16' width='3' y='0' x='29' stroke='none' fill={color}/>
  </>,
  customer: (color: string) =>
  <>
    <ellipse stroke={color} fill='none' cx='18' cy='8' rx='8' ry='8'/>
    <line stroke={color} x1='0' y1='36' x2='36' y2='36'/>
    <path stroke={color} fill='none' d='M 36 36 A 18 18 0 0 0 0 36'/>
  </>,
  insightsPageIcon: (color: string) =>
  <>
    <rect x='0.373047' y='11.175' width='6.11263' height='9.39203' stroke={color} fill='none'/>
    <rect x='8.94336' y='5.75511' width='6.11263' height='14.8116' stroke={color} fill='none'/>
    <rect x='17.5146' y='0.336243' width='6.11263' height='20.2306' stroke={color} fill='none'/>
    <rect y='22.4521' width='23.9993' height='1.54808' rx='0.745239' fill={color}/>
  </>,
  simulationPageIcon: (color: string) =>
  <>
    <line x1='1' y1='5' x2='23' y2='5' stroke={color} strokeLinecap='round' strokeWidth='2'/>
    <line x1='1' y1='12' x2='23' y2='12' stroke={color} strokeLinecap='round' strokeWidth='2'/>
    <line x1='1' y1='19' x2='23' y2='19' stroke={color} strokeLinecap='round' strokeWidth='2'/>
    <circle cx='21.5' cy='5' r='2.5' fill={color}/>
    <circle cx='12' cy='12' r='2.5' fill={color}/>
    <circle cx='2.5' cy='19' r='2.5' fill={color}/>
  </>,
  trendsPageIcon: (color: string) =>
  <>
    <line x1='2' y1='16' x2='8' y2='7' stroke={color}/>
    <line x1='8' y1='7' x2='15' y2='12' stroke={color}/>
    <line x1='15' y1='12' x2='22' y2='5' stroke={color}/>
    <circle cx='2' cy='16' r='2' fill={color}/>
    <circle cx='8' cy='7' r='2' fill={color}/>
    <circle cx='15' cy='12' r='2' fill={color}/>
    <circle cx='22' cy='5' r='2' fill={color}/>
  </>,
  calendar: (color: string) =>
  <>
    <rect x='1.25' y='3.062' width='18.646' height='16.938' stroke={color} fill='none'/>
    <rect x='4.23901' y='0.5' width='2.417' height='4.979' rx='1.208' stroke={color} fill={color}/>
    <rect x='14.489' y='0.5' width='2.417' height='4.979' rx='1.208' stroke={color} fill={color}/>
    <rect x='7.15601' y='10.25' width='2.563' height='2.563' fill={color}/>
    <rect x='7.15601' y='14.094' width='2.563' height='2.563' fill={color}/>
    <rect x='3.31201' y='14.094' width='2.563' height='2.563' fill={color}/>
    <rect x='11' y='10.25' width='2.563' height='2.563' fill={color}/>
    <rect x='11' y='14.094' width='2.563' height='2.563' fill={color}/>
    <rect x='14.844' y='10.25' width='2.563' height='2.563' fill={color}/>
    <rect x='14.844' y='14.094' width='2.563' height='2.563' fill={color}/>
  </>,
  info: (color: string) =>
  <>
    <circle cx='12' cy='12' r='11.5' stroke={color} fill='none'/>
    <rect x='10.5' y='9.5' width='3' height='8' rx='0.5' stroke={color} fill={color}/>
    <circle cx='12' cy='6' r='1.3' stroke={color} />
  </>

};
