import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { useProductConfig, State, Action } from '../store';
import { filterKeyToStr } from '../sharedUtils';
import { log } from '../utils';


const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
}));

export default function CategorySelector(
  {
    closeEditor,
    ingredientId,
    goBackToKeySelection,
  }: {
    closeEditor: () => void,
    ingredientId: string,
    goBackToKeySelection: () => void,
  }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filterKey = filterKeyToStr({ type: 'category', ingredientId });
  const initialActiveCategories = useSelector((state: State) => {
    const { product, filtersByProduct } = state;
    return (filtersByProduct[product][filterKey] as string[]) || [];
  });
  const ingredient = useProductConfig().ingredients.filter(i => i.column === ingredientId)[0];
  const categories = ingredient.categories;

  function initialState() {
    const checked: { [categoryValue: string]: boolean } = {};
    categories.forEach(cat => {
      checked[cat.value] = initialActiveCategories.indexOf(cat.value) >= 0;
    });
    return checked;
  }
  const [selectedCategories, setSelectedCategories] = useState(initialState());


  function nothingSelectedError() {
    return listOfSelectedCategoryValues.length === 0;
  }

  function allSelectedError() {
    return listOfSelectedCategoryValues.length === categories.length;
  }

  function errorInfo() {
    if (nothingSelectedError()) {
      return 'Nothing is selected, the result of the filter would be an empty set.';
    } else if (allSelectedError()) {
      return 'Everything is selected, the filter would have no effect.';
    } else {
      return ''; // Tooltip is not displayed when title is an empty string.
    }
  }

  const listOfSelectedCategoryValues = categories
    .map(c => c.value).filter(c => selectedCategories[c]);

  function updateFilters() {
    log(`Added or updated filter: ${filterKey}: ${listOfSelectedCategoryValues}`);
    dispatch({
      type: Action.AddOrUpdateFilter,
      payload:  {
        key: filterKey,
        values: listOfSelectedCategoryValues,
      }
    });
    closeEditor();
  }

  function toggleCat(cat: string, checked: boolean) {
    const updatedSelectedCategories = {
      ...selectedCategories,
      [cat]: checked
    };
    setSelectedCategories(updatedSelectedCategories);
  }

  return (
    <>
      <h2> { ingredient.human_name } </h2>
      <FormControl component='fieldset'>
        <FormGroup>
          {
            categories.map((cat, i) =>
            <FormControlLabel
            control={
              <Checkbox
                checked={selectedCategories[cat.value]}
                value={cat.value}
                onChange={(event, checked) => toggleCat(cat.value, checked)}/>
            }
            label={cat.display_name}
            key={i}/>)
          }
        </FormGroup>
      </FormControl>
      <div className={classes.buttons}>
      <Button onClick={goBackToKeySelection}>Cancel</Button>
      <Tooltip title={errorInfo()} placement='right'>
        <span>
          <Button
            onClick={updateFilters}
            color='secondary'
            variant='contained'
            style={{marginLeft: '1em'}}
            disabled={nothingSelectedError() || allSelectedError()}
            >Apply
          </Button>
        </span>
      </Tooltip>
      </div>
    </>
  );
}
