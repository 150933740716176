// A button for uploading a list. You can use it with <SavedListsBrowser>
// when you also want to allow uploads.
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Action, setError, State } from '../store';
import PublishIcon from '@material-ui/icons/Publish';
import { protectedJsonFetch, uniqueName } from '../utils';
import { useDispatch, useSelector } from 'react-redux';


export default function ListUploadButton() {
  const dispatch = useDispatch();
  const product = useSelector((state: State) => state.product);
  const existingListNames = useSelector((state: State) =>
    Object.keys(state.savedListsByProduct[product] || {}));
  const [uploadInProgress, setUploadInProgress] = useState(false);

  async function uploadList(event: any) {
    const body = new FormData();
    const fileToUpload = event.target.files[0];
    body.append('file', fileToUpload);
    body.append('product', product);
    body.append('name', uniqueName(fileToUpload.name, existingListNames));
    event.target.value = '';
    setUploadInProgress(true);
    try {
      // This is not a JSON request (the default for protectedJsonFetch) but a multipart/formdata
      // (the default for fetch). So we unset the extra headers from protectedJsonFetch.
      const updatedSavedLists = await protectedJsonFetch(
        '/api/uploadList', { method: 'POST', body, headers: {} });
      dispatch({ type: Action.SetSavedLists, payload: updatedSavedLists[product] });
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setUploadInProgress(false);
    }
  }

  return (
    <Button color='secondary' disabled={uploadInProgress} component='label'>
      <PublishIcon/>
      Upload
      <input onChange={uploadList} type='file' style={{ display: 'none' }} />
    </Button>
  );
}
